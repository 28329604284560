import {
  apiClient,
  handleResponse,
} from './axios';

async function getCode(code) {
  try {
    const response = await apiClient.get(`/code/name/${code}`);
    return handleResponse(response);
  } catch (error) {
    const res = await Promise.reject(error);
    return res;
  }
}

const signUpServices = {
  getCode,
};

export default signUpServices;
