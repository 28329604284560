/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Grid,
  IconButton,
  AppBar,
  Toolbar,
  useMediaQuery,
  Slide,
  Dialog,
  Snackbar,
} from '@material-ui/core';
import { ArrowBack, Close } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import moduleServices from '../../../../redux/services/moduleServices';
import userActions from '../../../../redux/actions/userActions';
import StorylineContent from './StorylineContent';
import Objectives from './Objectives';
import UnitMenu from './UnitMenu';
// import useTimeCounter from './useTimeCounter';
import moduleActions from '../../../../redux/actions/moduleActions';
import UnitLoader from '../../../Loader/UnitLoader';
import Dictionnary from '../../../Dictionnary';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles(() => ({
  imgCard: {
    height: '20vh',
  },
  sideMenu: {
    height: '90vh',
    maxHeight: '90vh',
  },
  moduleName: {
    margin: '1em 0',
  },
  mobileCloseBtn: {
    position: 'absolute',
    top: 25,
    right: 5,
  },
}));

const Module = ({
  _id,
  group,
  email,
  location,
  firstname,
  name,
  data,
  currentGroup,
  getAllModules,
  // logout,
  selectUnit,
}) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const [units, setUnits] = useState([]);
  const [moduleName, setModuleName] = useState('');
  const [errorSnackBar, setErrorSnackBar] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [currentModule, setCurrentModule] = useState(null);

  const moduleId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

  useEffect(() => {
    let mounted = true;
    setModuleName(location.state ? location.state.title : '');
    moduleServices.getUnits(moduleId, _id)
      .then((res) => {
        if (mounted) {
          if (res.length === 1) {
            setSelectedUnit(0);
          }
          setUnits(res);
        }
      })
      .catch(() => {
        setErrorSnackBar({ code: 2101, message: 'Missing units.', severity: 'warning' });
      });

    // eslint-disable-next-line no-return-assign
    return () => mounted = false;
  }, [_id]);

  useEffect(() => {
    data.length === 0 && getAllModules(group.data[currentGroup]._id);
  }, [currentGroup]);

  useEffect(() => {
    let selectedModule = null;
    if (data.length !== 0) {
      selectedModule = data[0].modules.find(
        (mod) => mod._id === moduleId,
      );
    }
    setCurrentModule(selectedModule);
  }, [currentModule]);

  useEffect(() => {
    setSelectedUnit(location.state
      ? (location.state.selectedUnit ? location.state.selectedUnit : null) : null);
  }, [location.state]);

  // eslint-disable-next-line max-len
  // const isIdle = useTimeCounter(_id, (units.length > 0 && selectedUnit !== null) ? units[selectedUnit].unit._id : null);
  // isIdle && logout();

  const handleSelectedUnit = (unitIndex) => {
    setSelectedUnit(unitIndex);
  };

  useEffect(() => {
    if ((selectedUnit !== null
        && units.length > 0)
      || (selectedUnit !== null
        && currentModule
        && currentModule !== null
        && currentModule.isRise
        && currentModule.units.length > 0)) {
      if (!currentModule.isRise) {
        selectUnit(units[selectedUnit].unit._id);
      } else {
        selectUnit(currentModule.units[selectedUnit]);
      }
    }
  }, [selectedUnit]);

  return (
    <>
      <Grid container direction="row">
        <Dictionnary />
        <Snackbar open={errorSnackBar !== null} onClose={() => { setErrorSnackBar(null); }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <Alert severity={errorSnackBar !== null ? errorSnackBar.severity : 'info'} variant="filled" onClose={() => { setErrorSnackBar(null); }}>
            {`Error ${errorSnackBar?.code}: ${errorSnackBar?.message}`}
          </Alert>
        </Snackbar>
        {
        units.length > 1 ? (
          <Grid item xs={12} md={3} lg={2}>
            <UnitMenu
              mobile={mobile}
              units={units}
              moduleName={moduleName}
              selectedUnit={selectedUnit}
              setSelectedUnit={(unitIndex) => setSelectedUnit(unitIndex)}
              _id={_id}
              // eslint-disable-next-line max-len
              revisionFile={currentModule && currentModule.revisionFile ? currentModule.revisionFile : null}
              handleSelectedUnit={(unitIndex) => handleSelectedUnit(unitIndex)}
            />
          </Grid>
        ) : (
          <Grid item md={12} xs={12}>
            <AppBar position="static" color="transparent">
              <Toolbar variant="dense">
                <IconButton
                  aria-label="back-button"
                  component={Link}
                  to="/elearning/course"
                >
                  <ArrowBack />
                </IconButton>
                Retour
              </Toolbar>
            </AppBar>
          </Grid>
        )
        }
        {
          !mobile ? (
            <Grid
              item
              xs={12}
              md={units.length === 1 ? 12 : 9}
              lg={units.length === 1 ? 12 : 10}
              className={classes.contentBody}
            >
              {(units.length > 0 && selectedUnit !== null) ? (
                <StorylineContent
                  rise={Boolean(units.length === 1)}
                  filename={units[selectedUnit].unit.filename}
                  unitId={units[selectedUnit].unit._id}
                  launchPoint={units[selectedUnit].unit.launchPoint}
                  userName={`${firstname} ${name}`}
                  email={email}
                  activityId={units[selectedUnit].unit.activityId}
                />
              ) : (
                currentModule ? (
                  <Objectives
                    content={currentModule.objectives.content}
                    title={currentModule.objectives.title}
                  />
                ) : (
                  <p>Loading...</p>
                )
              )}
            </Grid>
          ) : (
            <Dialog
              fullScreen
              open={Boolean(selectedUnit !== null)}
              onClose={() => setSelectedUnit(null)}
              TransitionComponent={Transition}
            >
              {(units.length > 0 && selectedUnit !== null) ? (
                <>
                  {units.length > 1 ? (
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={() => setSelectedUnit(null)}
                      aria-label="close"
                      className={classes.mobileCloseBtn}
                    >
                      <Close />
                    </IconButton>
                  ) : (
                    <IconButton
                      aria-label="back-button"
                      component={Link}
                      className={classes.mobileCloseBtn}
                      to="/elearning/course"
                    >
                      <Close />
                    </IconButton>
                  )}
                  <StorylineContent
                    rise={Boolean(units.length === 1)}
                    filename={units[selectedUnit].unit.filename}
                    launchPoint={units[selectedUnit].unit.launchPoint}
                    userName={`${firstname} ${name}`}
                    email={email}
                    activityId={units[selectedUnit].unit.activityId}
                  />
                </>
              ) : (
                currentModule ? (
                  <Objectives
                    content={currentModule.objectives.content}
                    title={currentModule.objectives.title}
                  />
                ) : (
                  <UnitLoader />
                )
              )}
            </Dialog>
          )
        }
      </Grid>
    </>
  );
};

Module.propTypes = {
  _id: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  email: PropTypes.string.isRequired,
  firstname: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  currentGroup: PropTypes.number.isRequired,
  group: PropTypes.object.isRequired,
  getAllModules: PropTypes.func.isRequired,
  // logout: PropTypes.func.isRequired,
  selectUnit: PropTypes.func.isRequired,
};

function mapState(state) {
  const { group } = state;
  const { authData } = state.auth;
  const { userData } = state.user;
  const { firstname, name } = userData;
  const { _id, email } = authData;
  const { data } = state.module;
  const { currentGroup } = state.ui;
  return {
    _id,
    email,
    firstname,
    name,
    data,
    currentGroup,
    group,
  };
}

const actionCreator = {
  getAllModules: moduleActions.getAll,
  selectUnit: moduleActions.selectUnit,
  logout: userActions.logout,
};

export default withRouter(connect(mapState, actionCreator)(Module));
