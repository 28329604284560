const examType1Constants = {
  INIT_REQUEST: 'EXAM_INIT_REQUEST',
  INIT_SUCCESS: 'EXAM_INIT_SUCCESS',
  INIT_FAILURE: 'EXAM_INIT_FAILURE',

  SET_EXAM_BEGIN_TIME: 'SET_EXAM_BEGIN_TIME',

  SET_CURRENT_QUESTION: 'SET_CURRENT_EXAM_QUESTION',
  SET_MULTI_QUESTION_ID: 'SET_EXAM_MULTI_QUESTION_ID',
  SET_CURRENT_ANSWER: 'SET_CURRENT_EXAM_ANSWER',
  PUSH_ANSWER: 'PUSH_EXAM_ANSWER',

  CLEAR_EXAM: 'CLEAR_EXAM',
  CLEAR_EXAM_ANSWERS: 'CLEAR_EXAM_ANSWERS',
};

export default examType1Constants;
