import React, { useState } from 'react';
import {
  Card, CardContent, Typography, Button, useMediaQuery,
} from '@material-ui/core';

const TextCard = () => {
  const isMobile = useMediaQuery('(max-width: 900px)');
  const [expand, setExpand] = useState(null);

  const readMore = () => {
    setExpand(true);
  };

  /**
   * Truncate the string length after 80 chars.
   * @param {String} text
   */
  const limitText = (text) => {
    if (text.length > 60) {
      const newText = `${text.substring(0, 60)} ...`;
      return newText;
    }
    return text;
  };

  return !isMobile || expand ? (
    <Card className="card-ui" style={{ margin: '2em 0 2em 0' }}>
      <CardContent>
        <Typography variant="body1" style={{ textAlign: 'center' }}>
          Learning Links - linking you to a world of English learning resources.
        </Typography>
        <Typography variant="body1" style={{ textAlign: 'center' }}>
          Links organized by theme will help you develop and practice your English
          in the form of quizzes, exercises, podcasts, videos, articles and apps.
        </Typography>
        <Typography variant="body1" style={{ color: 'red', textAlign: 'center', marginTop: '10px' }}>
          {`${'IMPORTANT: L\'onglet "Learning links" vous permet d\'accéder à des ressources supplémentaires.'}`}
        </Typography>
        <Typography variant="body1" style={{ color: 'red', textAlign: 'center' }}>
          {`${'Il ne prend pas en compte le temps de connexion et n\'a pas d\'impact sur vos résultats.'}`}
        </Typography>
      </CardContent>
    </Card>
  ) : (
    <Card className="card-ui" style={{ margin: '2em 0 2em 0' }}>
      <CardContent>
        <Typography variant="body1">
          {limitText('Learning Links is an additional resource that will give you access to global English learning. Different themes are available in order to perfect your understanding, practice and general knowledge in the form of quizzes, exercises, podcasts, videos, articles or even mobile applications.') }
        </Typography>
        <Button onClick={readMore}>
          <Typography variant="body1" style={{ textDecoration: 'underline', paddingTop: '1em' }}>
            Read more
          </Typography>
        </Button>
      </CardContent>
    </Card>
  );
};

export default TextCard;
