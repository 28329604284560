/* eslint-disable no-underscore-dangle */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import learningLinksActions from '../../../redux/actions/learningLinksActions';
import ThemesLoader from '../../Loader/ThemesLoader';
import TextCard from './TextCard';
import ThemeCard from './ThemeCard';

const DisplayThemes = ({ learningLinks, getAllThemes }) => {
  useEffect(() => {
    getAllThemes();
  }, []);

  return (
    <div>
      <TextCard />
      <h3 className="subtitle">Themes</h3>
      <Grid container style={{ marginLeft: '7px' }}>
        {
          learningLinks.requestingThemes ? (
            <ThemesLoader />
          ) : (
            learningLinks.themes.map((theme) => (
              <ThemeCard
                key={theme._id}
                id={theme._id}
                name={theme.name}
                img={theme.img}
              />
            ))
          )
        }
      </Grid>
    </div>
  );
};

DisplayThemes.propTypes = {
  learningLinks: PropTypes.object.isRequired,
  getAllThemes: PropTypes.func.isRequired,
};

function mapState(state) {
  const { learningLinks } = state;
  return { learningLinks };
}

const actionCreators = {
  getAllThemes: learningLinksActions.getAllThemes,
};

const connectedDisplayThemes = connect(mapState, actionCreators)(DisplayThemes);
export default connectedDisplayThemes;
