/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useEffectOnce } from 'react-use';
import Alert from '@material-ui/lab/Alert';
import {
  PlayCircleOutline as PlayCircleOutlineIcon,
} from '@material-ui/icons';
import {
  Button, Grid, Card, CardContent, Container, Typography, TextField, useMediaQuery, Snackbar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NotificationCard from '../Layout/components/NotificationCard';
import Player from '../shared/Player';
import uiActions from '../../redux/actions/uiActions';
import userActions from '../../redux/actions/userActions';
import guideServices from '../../redux/services/guideServices';
import newsServices from '../../redux/services/newsServices';
// import useTimeCounter from '../Elearning/components/content/useTimeCounter';
import VideoCard from '../Videos/components/VideoCard';

const useStyles = makeStyles((theme) => ({
  playerContainer: {
    justifyContent: 'center !important',
    width: '100%',
    paddingTop: '10px',
    display: 'flex',
  },
  primaryColor: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const startCloseHelp = new Date('2022-12-23');
const endCloseHelp = new Date('2023-01-02');

const Contact = ({
  changePageTitle,
  // logout,
  // userId,
  // groupId,
}) => {
  const size = useMediaQuery('(max-width: 700px)');
  const classes = useStyles();
  const [videoUrl, setVideoUrl] = useState('');
  const [guides, setGuides] = useState([]);
  const [news, setNews] = useState([]);
  const [errorSnackBar, setErrorSnackBar] = useState(null);
  const [search, setSearch] = useState('');
  const today = new Date();
  const isHelpClosed = today >= startCloseHelp && today <= endCloseHelp;

  useEffectOnce(() => {
    changePageTitle('Help');
    guideServices.getGuides()
      .then((data) => {
        setGuides(data);
      })
      .catch(() => {
        setErrorSnackBar({ code: 1001, message: 'Missing guides.', severity: 'warning' });
      });
    newsServices.getLastNewsByType(1)
      .then((data) => {
        const tmp = [];
        data.forEach((newData) => {
          const date = new Date(newData.created_at);
          if (date.getFullYear() === today.getFullYear() || (date.getFullYear() === today.getFullYear() - 1 && date.getMonth() > today.getMonth())) {
            tmp.push(newData);
          }
        });
        setNews(tmp);
      })
      .catch(() => {
        setErrorSnackBar({ code: 1002, message: 'Missing news.', severity: 'warning' });
      });
  });

  // eslint-disable-next-line no-unused-vars
  const handleClick = (_url, opt) => {
    setVideoUrl(_url);
  };

  // const isIdle = useTimeCounter(userId, groupId || null);

  // isIdle && logout();

  return (
    <>
      <Container style={{ textAlign: 'center' }}>
        <Snackbar open={errorSnackBar !== null} onClose={() => { setErrorSnackBar(null); }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <Alert severity={errorSnackBar !== null ? errorSnackBar.severity : 'info'} variant="filled" onClose={() => { setErrorSnackBar(null); }}>
            {`Error ${errorSnackBar?.code}: ${errorSnackBar?.message}`}
          </Alert>
        </Snackbar>
        <Grid
          container
          direction="row"
          style={{ marginTop: '1em' }}
          spacing={4}
        >
          <Grid
            item
            md={12}
          >
            <Card variant="outlined">
              <CardContent style={{ textAlign: 'center' }}>
                <Typography component="h2" style={{ width: '90%', marginLeft: '5%' }}>
                  Vous êtes un peu perdu(e) ? Pas de panique. Cette page contient toutes les ressources utiles pour vous aider et vous accompagner dans l’utilisation de la plateforme. Vous pouvez soit regarder l'une des vidéos tutoriels ci-dessous pour vous aider à mieux comprendre votre contenu, vos résultats ou les fonctionnalités de la plateforme, soit simplement taper un mot clé de votre problème dans la barre FAQ pour rechercher une réponse à votre question. La section "Actualités" vous fournira des informations sur les mises à jour ou les problèmes actuels de la plateforme. Si aucun de ces éléments ne répond à votre question, il suffit de nous envoyer un message via le service d'assistance. Le bouton pour y accéder se trouve au bas de cette page.
                </Typography>
                <div
                  className={classes.primaryColor}
                  style={{
                    width: '100%', borderRadius: 5, display: 'flex', justifyContent: 'flex-start', marginTop: 25, marginBottom: 25,
                  }}
                >
                  <Typography component="h2" variant="h5" style={{ padding: '0.5em', color: 'white' }}>
                    Actualités
                  </Typography>
                </div>
                <Typography component="h2" style={{ color: '#FF0000' }}>
                  Notre plateforme rencontre des problèmes avec les versions de MacOs antérieures a 10.12.6, si vous êtes concerné, pensez à utiliser un autre appareil.
                </Typography>
                <Typography component="h2">
                  Pensez à changer de navigateur internet ou videz vos données en cache, voici quelques liens utiles en fonction de votre navigateur:
                </Typography>
                <Grid
                  container
                  direction="row"
                  spacing={4}
                  style={{
                    marginBottom: '1em', marginTop: '2em', width: '100%', justifyContent: 'space-around', marginLeft: '1%',
                  }}
                >
                  <Button
                    href="https://support.mozilla.org/fr/kb/comment-vider-le-cache-de-firefox"
                    className="btn btn-primary"
                    style={{ width: '15%' }}
                  >
                    <img style={{ maxWidth: '3em' }} src="img/firefox.png" title="Firefox" alt="firefox" />
                  </Button>
                  <Button
                    href="https://support.google.com/accounts/answer/32050?hl=fr&co=GENIE.Platform%3DDesktop"
                    className="btn btn-primary"
                    style={{ width: '15%' }}
                  >
                    <img style={{ maxWidth: '3em' }} src="img/chrome.png" title="Chrome" alt="chrome" />
                  </Button>
                  <Button
                    href="https://support.apple.com/fr-fr/HT201265"
                    className="btn btn-primary"
                    style={{ width: '15%' }}
                  >
                    <img style={{ maxWidth: '3em' }} src="img/safari.png" title="Safari" alt="safari" />
                  </Button>
                  <Button
                    href="https://support.microsoft.com/fr-fr/microsoft-edge/afficher-et-supprimer-l-historique-du-navigateur-dans-microsoft-edge-00cf7943-a9e1-975a-a33d-ac10ce454ca4"
                    className="btn btn-primary"
                    style={{ width: '15%' }}
                  >
                    <img style={{ maxWidth: '3em' }} src="img/edge.png" title="Edge" alt="edge" />
                  </Button>
                </Grid>
                {
                  news.length > 0
                    ? (
                      news.map((_news) => (
                        <NotificationCard
                          key={_news._id}
                          message={_news.message}
                          createdAt={_news.created_at}
                        />
                      ))
                    )
                    : null
                }
                <div
                  className={classes.primaryColor}
                  style={{
                    width: '100%', borderRadius: 5, display: 'flex', justifyContent: 'flex-start', marginTop: 25, marginBottom: 25,
                  }}
                >
                  <Typography component="h2" variant="h5" style={{ padding: '0.5em', color: 'white' }}>
                    Les vidéos explicatives
                  </Typography>
                </div>
                {
                  videoUrl
                    ? (
                      <div className={classes.playerContainer}>
                        <Player url={videoUrl} />
                      </div>
                    )
                    : null
                }
                <Grid
                  container
                  direction="row"
                  spacing={4}
                  style={{
                    marginTop: '1em', justifyContent: 'space-around',
                  }}
                >
                  {guides.map((guide) => (
                    <VideoCard
                      key={guide._id}
                      pdf={false}
                      tags={[]}
                      title={guide.name}
                      setUrl={handleClick}
                      filename={guide.filename}
                      thumbnail={guide.thumbnail}
                      icon={<PlayCircleOutlineIcon color="secondary" />}
                      type="video"
                      createdAt={guide.created_at}
                    />
                  ))}
                </Grid>
                <div
                  className={classes.primaryColor}
                  style={{
                    width: '100%', borderRadius: 5, display: 'flex', justifyContent: 'flex-start', marginTop: 25, marginBottom: 25,
                  }}
                >
                  <Typography component="h2" variant="h5" style={{ padding: '0.5em', color: 'white' }}>
                    F.A.Q
                  </Typography>
                </div>
                {
                  isHelpClosed
                    ? (
                      <Typography style={{ fontSize: 14, color: 'red', textAlign: 'center' }}>
                        Notre service d'assistance est indisponible du 23/12/2022 au 02/01/2023 inclus.
                        Nous vous invitons à consulter les vidéos tutorielles et la FAQ durant cette période.
                        Toute l'équipe Medical World vous souhaite de joyeuses fêtes de fin d'année et une bonne année 2023.
                      </Typography>
                    )
                    : (
                      <Typography component="h2" style={{ marginBottom: '1em' }}>
                        Pensez à rechercher si un problème similaire a déjà été résolu dans la F.A.Q.
                      </Typography>
                    )
                }
                <div style={{ minWidth: '60%' }}>
                  <Grid container columns={1}>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: 'flex', flexDirection: size ? 'column' : 'row', right: 0, justifyContent: 'center', alignItems: 'center',
                      }}
                    >
                      <TextField
                        label="F.A.Q"
                        id="faq"
                        placeholder="Posez une question !"
                        variant="outlined"
                        disabled={isHelpClosed}
                        onChange={(e) => { setSearch(e.target.value); }}
                        style={{
                          height: '100%', width: size ? '100%' : '50%', marginBottom: size ? '15px' : 0,
                        }}
                      />
                      <Button
                        color="primary"
                        variant="contained"
                        disabled={isHelpClosed}
                        href={`https://englishworld.atlassian.net/servicedesk/customer/portal/2?q=${search}`}
                        style={{
                          height: '100%', width: size ? '100%' : '15%', marginLeft: size ? 0 : 20,
                        }}
                      >
                        Rechercher
                      </Button>
                    </Grid>
                  </Grid>
                </div>
                <div
                  className={classes.primaryColor}
                  style={{
                    width: '100%', borderRadius: 5, display: 'flex', justifyContent: 'flex-start', marginTop: 25, marginBottom: 25,
                  }}
                >
                  <Typography component="h2" variant="h5" style={{ padding: '0.5em', color: 'white' }}>
                    L'assistance
                  </Typography>
                </div>
                {
                  isHelpClosed
                    ? (
                      <Typography style={{ fontSize: 14, color: 'red', textAlign: 'center' }}>
                        Notre service d'assistance est indisponible du 23/12/2022 au 02/01/2023 inclus.
                        Nous vous invitons à consulter les vidéos tutorielles et la FAQ durant cette période.
                        Toute l'équipe Medical World vous souhaite de joyeuses fêtes de fin d'année et une bonne année 2023.
                      </Typography>
                    )
                    : (
                      <Typography component="h2" style={{ width: '90%', marginLeft: '5%' }}>
                        Veuillez expliquer votre problème en détail lorsque vous envoyez un ticket. Vous pouvez également
                        inclure des captures d'écran. Cela nous aidera à répondre et à résoudre votre problème plus
                        rapidement. Vous trouverez un tutoriel dans la section ci-dessus pour vous aider à comprendre
                        comment remplir et suivre vos tickets.
                      </Typography>
                    )
                }
                <Button
                  color="primary"
                  variant="contained"
                  target="_blank"
                  disabled={isHelpClosed}
                  style={{
                    marginTop: 20, padding: '15px', paddingRight: '40px', paddingLeft: '40px', fontWeight: 'bold',
                  }}
                  href="https://englishworld.atlassian.net/servicedesk/customer/portal/2"
                >
                  Accéder à l&apos;assistance
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

Contact.propTypes = {
  changePageTitle: PropTypes.func.isRequired,
  // logout: PropTypes.func.isRequired,
  // userId: PropTypes.string.isRequired,
  // groupId: PropTypes.string.isRequired,
};

function mapState(state) {
  const { userData } = state.user;
  const { data } = state.group;
  const { currentGroup } = state.ui;
  const userId = userData._id;
  return {
    userId, groupId: data[currentGroup]._id,
  };
}

const actionCreators = {
  changePageTitle: uiActions.changePageTitle,
  logout: userActions.logout,
};

export default connect(mapState, actionCreators)(Contact);
