/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Divider,
} from '@material-ui/core';

import config from '../../../../config';

const UnitListItem = ({
  unit, index, selectedUnit, selected,
}) => {
  const {
    name,
    thumbnail,
    isFinalQuiz,
    _id,
  } = unit;

  return (
    <>
      {isFinalQuiz && <Divider />}
      <ListItem
        button
        alignItems="flex-start"
        key={_id}
        onClick={() => selectedUnit(index)}
        selected={selected}
      >
        <ListItemAvatar>
          <Avatar alt={name} src={`${config.OBJECT_URL}/thumbnail/${thumbnail}`} />
        </ListItemAvatar>
        <ListItemText
          primary={name}
          secondary={(
            <Typography variant="body1">
              {isFinalQuiz ? 'Chapter Quiz' : `Unit ${index + 1}`}
            </Typography>
          )}
        />
      </ListItem>
    </>
  );
};

UnitListItem.propTypes = {
  unit: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  selectedUnit: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default UnitListItem;
