/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Button, Grid, Typography, Box, Snackbar,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { useMsal } from '@azure/msal-react';
import { connect } from 'react-redux';
import userActions from '../../redux/actions/userActions';
import { loginRequest, graphConfig } from '../../azure/authConfig';
import LoginForm from './components/LoginForm';
import Copyright from './components/Copyright';
import NewPasswordRequest from './components/NewPasswordRequest';
import NewPassword from './components/NewPassword';
import SignUp from '../SignUp';
import userServices from '../../redux/services/userServices';
import config from '../../config';
import { Loading } from '../../helpers/utils';

const CustomButton = withStyles({
  root: {
    background: `linear-gradient(60deg, ${config.primaryColor} 30%, #189ecb 90%)`,
    borderRadius: 3,
    border: 0,
    color: 'white',
    width: '80%',
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '10%',
    padding: '5px 15px',
    boxShadow: '0 3px 5px 2px rgba(27, 177, 228, .3)',
    '&:hover': {
      background: 'linear-gradient(60deg, #1798c4 30%, #147fa4 90%)',
      boxShadow: '0 3px 5px 2px rgba(20, 147, 178, .4)',
    },
  },
  label: {
    textTransform: 'capitalize',
  },
// eslint-disable-next-line react/jsx-props-no-spreading
})((props) => <Button {...props} />);

const qs = require('qs');

function Alert(props) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

async function callMsGraph(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);
  headers.append('Access-Control-Allow-Origin', '*');

  const options = {
    method: 'GET',
    headers,
  };
  return fetch(graphConfig.graphMeEndpoint, options)
    .then((response) => response.json());
}

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
  },
  warningText: {
    textAlign: 'center',
    color: 'red',
  },
  mainCard: {
    [theme.breakpoints.down('sm')]: {
      minHeight: '100vh',
      minWidth: '100vw',
      margin: '0',
      paddin: '0',
      position: 'relative',
      overflow: 'visible',
    },
    [theme.breakpoints.down('md')]: {
      padding: '0',
      margin: '0',
      minHeight: '100vh',
    },
    [`${theme.breakpoints.up('md')} and (orientation: portrait)`]: {
      padding: '20vh 2vw',
      margin: '0',
    },
    minHeight: '70vh',
    minWidth: '45vw',
    boxShadow: '0px 0px 10px 1px rgb(0, 0, 0, 0.3)',
    borderRadius: '20px',
    margin: '6vh 20vh',
    position: 'relative',
    overflow: 'visible',
  },
  image: {
    backgroundImage: `url(${config.OBJECT_URL}/thumbnail/MW_LANDING_${Math.floor((Math.random() * 6) + 1)}.svg)`,
    backgroundRepeat: 'no-repeat',
    height: '100%',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '20px 0px 0px 20px',
  },
  paper: {
    margin: theme.spacing(2, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const startCloseHelp = new Date('2022-12-23');
const endCloseHelp = new Date('2023-01-02');

const Login = ({
  location, setAzureToken, login, clearLogin,
}) => {
  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null);
  const [azureSignup, setAzureSignup] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [errorSnackBar, setErrorSnackBar] = useState(null);
  const [values, setValues] = useState({
    forgotPassword: false,
    createAccount: false,
    params: {
      token: null,
    },
  });
  const [loading, setLoading] = useState(false);
  const { innerWidth: width } = window;
  const isSmallScreen = width < 450;
  const today = new Date();

  const closePlatform = new Date(config.START_CLOSING_PLATFORM);
  const endPlatform = new Date(config.END_CLOSING_PLATFORM);
  today.setHours(23);
  const platformClosed = closePlatform <= today && endPlatform >= today;
  const isHelpClosed = today >= startCloseHelp && today <= endCloseHelp;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleBack = () => {
    clearLogin();
    setAzureSignup(false);
    setValues({ ...values, createAccount: false });
  };

  const connectAzure = (tokenAzure, code, sem) => {
    setAzureToken(tokenAzure);
    callMsGraph(tokenAzure).then((_response) => {
      if (_response.userPrincipalName.includes('@prefmschutoulouse.onmicrosoft.com')) {
        setAzureSignup(false);
        setGraphData(null);
        setOpen(true);
        return;
      }
      const tmpResponse = { ..._response, code: code != null ? code : '', sem: sem != null ? sem : '' };
      setGraphData(tmpResponse);
      userServices.verifyEmail(_response.userPrincipalName)
        .then(() => {
          setAzureSignup(true);
        })
        .catch(() => {
          login(_response.userPrincipalName, _response.id, true)
            .then((response) => {
              if (response === undefined) {
                setOpen(true);
              } else {
                setAzureSignup(false);
                setGraphData(null);
              }
            })
            .catch(() => {
              setErrorSnackBar({ code: 2501, message: 'Login error.', severity: 'warning' });
            });
        });
    });
  };

  const RequestAccessToken = (code, sem) => {
    const accountsTest = instance.getAllAccounts();
    const request = {
      ...loginRequest,
      account: accountsTest[0],
    };
    instance.acquireTokenSilent(request)
      .then((response) => {
        connectAzure(response.accessToken, code, sem);
      }).catch(() => {
        instance.acquireTokenRedirect(request)
          .then((response) => {
            connectAzure(response.accessToken, code, sem);
          }).catch(() => {
            setAzureSignup(false);
            setGraphData(null);
            setOpen(true);
          });
      });
  };

  const handleLogin = (code, sem) => {
    instance.loginRedirect(loginRequest).then(() => {
      RequestAccessToken(code, sem);
    }).catch(() => {
      setAzureSignup(false);
      setGraphData(null);
      setOpen(true);
    });
  };

  useEffect(() => {
    setOpen(false);
    setLoading(false);
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });
    setValues({ ...values, params });
    if (params.code && params.sem) {
      // setLoading(true);
      sessionStorage.setItem('code', params.code);
      sessionStorage.setItem('sem', params.sem);
    }
    const code = sessionStorage.getItem('code') !== null ? atob(sessionStorage.getItem('code')) : '';
    const sem = sessionStorage.getItem('sem') !== null ? parseInt(sessionStorage.getItem('sem'), 10) : null;
    let accountsTest = instance.getAllAccounts();
    // eslint-disable-next-line no-plusplus
    for (let i = 0; (i < 10) && (accountsTest.length === 0); i++) {
      accountsTest = instance.getAllAccounts();
    }
    if (accountsTest.length > 0) {
      RequestAccessToken(code, sem);
      // setLoading(true);
    } else if (sessionStorage.getItem('code') !== null && sessionStorage.getItem('sem') !== null) {
      setLoading(true);
      handleLogin(code, sem);
    }
  }, [location, accounts]);

  const classes = useStyles();
  return (
    <>
      {loading === false
        ? (
          <>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="warning">
                Portail Azure: Connexion impossible.
              </Alert>
            </Snackbar>
            <Snackbar open={errorSnackBar !== null} onClose={() => { setErrorSnackBar(null); }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
              <Alert severity={errorSnackBar !== null ? errorSnackBar.severity : 'info'} variant="filled" onClose={() => { setErrorSnackBar(null); }}>
                {`Error ${errorSnackBar?.code}: ${errorSnackBar?.message}`}
              </Alert>
            </Snackbar>
            <NewPassword
              open={Boolean(values.params.token)}
              tokenToCheck={values.params.token ? values.params.token : ''}
            />
            <div className={classes.root}>
              <Grid container direction="row" className={classes.mainCard}>
                <Grid item xs={false} sm={4} md={7} className={classes.image} />
                <Grid item xs={12} sm={8} md={5} elevation={6} square>
                  <div className={classes.paper}>
                    {!values.createAccount && (<img src="/img/logo.png" width="200" height="120" alt="logo MW" />)}
                    <div className={classes.paper}>
                      {values.forgotPassword && (
                      <Typography component="h2" variant="h5">
                        Mot de passe oublié
                      </Typography>
                      )}
                      {values.forgotPassword
                        ? <NewPasswordRequest />
                        : (values.createAccount || azureSignup
                          ? (
                            <SignUp
                              graphData={graphData}
                              setCreateAccount={() => setValues({ ...values, createAccount: true })}
                              loading={loading}
                            />
                          )
                          : (
                            <LoginForm
                              setCreateAccount={() => setValues({ ...values, createAccount: true })}
                            />
                          )
                        )}
                    </div>
                    {values.createAccount && (
                    <Grid container justifyContent="center">
                      <Grid item>
                        <Button
                          style={{ textTransform: 'none' }}
                          onClick={() => handleBack()}
                          variant="text"
                        >
                          Déjà un compte? Se connecter
                        </Button>
                      </Grid>
                    </Grid>
                    )}
                    <Grid container style={{ marginTop: '1em' }}>
                      <Grid item xs>
                        {!values.forgotPassword ? (
                          <>
                            <Grid container style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                              {!values.createAccount ? (
                                <Button
                                  onClick={() => setValues({ ...values, forgotPassword: true })}
                                  color="primary"
                                >
                                  Mot de passe oublié?
                                </Button>
                              )
                                : null}
                              {!azureSignup || !values.createAccount ? (
                                <Button
                                  onClick={() => handleLogin(null, null)}
                                  style={{ marginTop: '20px' }}
                                >
                                  <img src="img/microsoft_long.svg" title="Connexion au portail Microsoft Azure" alt="azure" />
                                </Button>
                              )
                                : null}
                              {platformClosed ? (
                                <Typography style={{ fontSize: 14, color: 'red', textAlign: 'center' }}>
                                  La plateforme est en maintenance du 3 au 7 février inclus, merci de votre compréhension.
                                  L'équipe MedicalWorld.
                                </Typography>
                              )
                                : null}
                              {isHelpClosed ? (
                                <Typography style={{ fontSize: 14, color: 'red', textAlign: 'center' }}>
                                  Notre service d'assistance est indisponible du 23/12/2022 au 02/01/2023 inclus.
                                  Nous vous invitons à consulter les vidéos tutorielles et la FAQ durant cette période.
                                  Toute l'équipe Medical World vous souhaite de joyeuses fêtes de fin d'année et une bonne année 2023.
                                </Typography>
                              )
                                : null}
                              {!values.createAccount ? (
                                <>
                                  <CustomButton
                                    target="_blank"
                                    href="https://englishworld.atlassian.net/servicedesk/customer/portal/2"
                                    color="#00ff00"
                                    disabled={isHelpClosed}
                                    style={{ marginTop: '15px' }}
                                  >
                                    <Typography>
                                      {'Besoin d\'aide ? Assistance'}
                                    </Typography>
                                  </CustomButton>
                                </>
                              )
                                : null}
                            </Grid>
                          </>
                        ) : (
                          <Button
                            onClick={() => setValues({ ...values, forgotPassword: false })}
                            color="primary"
                            style={{ marginLeft: !isSmallScreen ? '40%' : '45%' }}
                          >
                            Retour
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                    <Box mt={3}>
                      <Copyright />
                    </Box>
                  </div>
                </Grid>
              </Grid>
            </div>
          </>
        )
        : <Loading />}
    </>
  );
};

Login.propTypes = {
  location: PropTypes.object.isRequired,
  setAzureToken: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  clearLogin: PropTypes.func.isRequired,
};

function mapState(state) {
  const { azureToken } = state.auth;
  return { azureToken };
}

const actionCreators = {
  setAzureToken: userActions.setAzureToken,
  login: userActions.login,
  clearLogin: userActions.clearLogin,
};

export default connect(mapState, actionCreators)(Login);
