const glossaryConstants = {
  GLOSSARY_REQUEST: 'GLOSSARY_REQUEST',
  GLOSSARY_SUCCESS: 'GLOSSARY_SUCCESS',
  GLOSSARY_FAILURE: 'GLOSSARY_FAILURE',

  ADD_GLOSSARY_REQUEST: 'ADD_GLOSSARY_REQUEST',
  ADD_GLOSSARY_SUCCESS: 'ADD_GLOSSARY_SUCCESS',
  ADD_GLOSSARY_FAILURE: 'ADD_GLOSSARY_FAILURE',

  DELETE_GLOSSARY_REQUEST: 'DELETE_GLOSSARY_REQUEST',
  DELETE_GLOSSARY_SUCCESS: 'DELETE_GLOSSARY_SUCCESS',
  DELETE_GLOSSARY_FAILURE: 'DELETE_GLOSSARY_FAILURE',
};

export default glossaryConstants;
