import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import ReactPlayer from 'react-player';
import uiActions from '../../redux/actions/uiActions';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: '2em',
    width: '100%',
    display: 'flex',
    justifyContent: 'left',
  },
  playerWrapper: {
    position: 'relative',
    paddingTop: '56.25%',
    width: '100%',
  },
  player: {
    justifyContent: 'center !important',
    position: 'absolute',
    top: 0,
    left: '-0.55%',
    width: '100%',
    height: '100%',
  },
}));

const Player = ({ url }) => {
  const classes = useStyles();

  return (
    <Container style={{ zIndex: 10 }} className={classes.root}>
      <div className={classes.playerWrapper}>
        <ReactPlayer
          url={url}
          controls
          loop
          playing
          width="100%"
          height="100%"
          className={classes.player}
        />
      </div>
    </Container>
  );
};

Player.propTypes = {
  url: PropTypes.string.isRequired,
};

const actionCreators = {
  changePageTitle: uiActions.changePageTitle,
};

export default connect(null, actionCreators)(Player);
