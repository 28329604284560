/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid } from '@material-ui/core';
import Loader from 'react-loader-spinner';
// import useTimeCounter from '../Elearning/components/content/useTimeCounter';
import VideoCard from './components/VideoCard';
import Player from '../shared/Player';
import videoServices from '../../redux/services/videoServices';
import uiActions from '../../redux/actions/uiActions';
import userActions from '../../redux/actions/userActions';
import Dictionnary from '../Dictionnary';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '2em',
    width: '100%',
    justifyContent: 'center !important',
  },
}));

const Videos = ({
  changePageTitle,
  // logout,
  // userId,
  // groupId,
}) => {
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles();
  const [videos, setVideos] = useState(null);
  const [videoUrl, setVideoUrl] = useState('');

  useEffect(() => {
    let mounted = true;
    changePageTitle('MedFlix');
    videoServices.getVideos()
      .then((res) => {
        if (mounted) {
          setVideos(res);
        }
      }, () => setVideos([]));
    // eslint-disable-next-line no-return-assign
    return () => mounted = false;
  }, []);

  const handleClick = (_url) => {
    setVideoUrl(_url);
    window.scrollTo(0, 0);
  };

  // const isIdle = useTimeCounter(userId, groupId || null);

  // eslint-disable-next-line no-unused-expressions
  // isIdle && logout();

  return (
    videos === null
      ? (
        <div style={{
          width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center',
        }}
        >
          <Loader
            type="Oval"
            color="#00BFFF"
            height={150}
            width={150}
          />
        </div>
      )
      : (
        <Container className={classes.root}>
          <Dictionnary />
          {
        videoUrl
          ? (
            <div className="">
              <Player url={videoUrl} />
            </div>
          )
          : null
      }
          <Grid container direction="row" spacing={2} xs={12}>
            {
          videos.map((video) => (
            <VideoCard
              title={video.title}
              filename={video.filename}
              thumbnail={video.thumbnail}
              pdf={video.pdf}
              setUrl={handleClick}
              credits={video.credits}
              tags={video.tags}
            />
          ))
        }
          </Grid>
        </Container>
      )
  );
};

Videos.propTypes = {
  changePageTitle: PropTypes.func.isRequired,
  // logout: PropTypes.func.isRequired,
  // userId: PropTypes.string.isRequired,
  // groupId: PropTypes.string.isRequired,
};

function mapState(state) {
  const { userData } = state.user;
  const { data } = state.group;
  const { currentGroup } = state.ui;
  const userId = userData._id;
  return {
    userId, groupId: data[currentGroup]._id,
  };
}

const actionCreators = {
  changePageTitle: uiActions.changePageTitle,
  logout: userActions.logout,
};

export default connect(mapState, actionCreators)(Videos);
