/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import Cookies from 'js-cookie';
import axios from 'axios';
import {
  apiClient,
  handleResponse,
} from './axios';

async function login(email, password) {
  try {
    const response = await axios.post('/student/login', { email, password });
    const user = await handleResponse(response);
    return user;
  } catch (error) {
    const res = await Promise.reject(error.response.data.message);
    return res;
  }
}

async function getStudentWithCompany(id) {
  try {
    const response = await axios.get(`/student/company/${id}`);
    const user = await handleResponse(response);
    return user;
  } catch (error) {
    const res = await Promise.reject(error.response.data.message);
    return res;
  }
}

async function getUserById(user) {
  try {
    const response = await apiClient.get(`/student/${user}`);
    const res = await handleResponse(response);
    return res;
  } catch (error) {
    const res = await Promise.reject(error.response.data.message);
    return res;
  }
}

async function updateSatisfaction(userId) {
  try {
    const response = await apiClient.put(`/student/satisfaction/${userId}`, {});
    const res = await handleResponse(response);
    return res;
  } catch (error) {
    const res = await Promise.reject(error.response.data.message);
    return res;
  }
}

async function createUser(user) {
  try {
    const response = await axios.post('/student', { ...user, typeSite: '1' });
    const data = await handleResponse(response);
    const expiring = new Date();
    expiring.setMinutes(60 * 5 + 5);
    Cookies.set('auth', data.token, { expires: expiring }, { secure: true }, { sameSite: 'strict' });
    Cookies.set('refresh', data.refreshToken, { expires: expiring }, { secure: true }, { sameSite: 'strict' });
    return data;
  } catch {
    const res = await Promise.reject(new Error('Erreur pendant la création du compte'));
    return res;
  }
}

async function updateUserNames(id, body) {
  const response = await apiClient.put(`/student/names/${id}`, body);
  const data = await handleResponse(response);
  return data;
}

async function sendNewMail(user, typeSite) {
  user.isMedicalWorld = typeSite;
  try {
    const response = await axios.post('/student/sendnewmail', user);
    const data = await handleResponse(response);
    return data;
  } catch (error) {
    const res = await Promise.reject(error.response.data.message);
    return res;
  }
}

async function requestNewPassword(email) {
  try {
    const response = await axios.post('/auth/forgot_password', { email });
    const data = await handleResponse(response);
    return data;
  } catch (error) {
    const res = await Promise.reject(error.response.data.message);
    return res;
  }
}

async function verifyAccount(id, code) {
  try {
    const response = await axios.post(`/confirmation/${id}/${code}`);
    const data = await handleResponse(response);
    return data;
  } catch (error) {
    const res = await Promise.reject(error.response.data.message);
    return res;
  }
}

async function verifyEmail(email) {
  try {
    const response = await axios.post('/student/email', { email });
    const data = await handleResponse(response);
    return data;
  } catch (error) {
    const res = await Promise.reject(error);
    return res;
  }
}

async function sendNewEmail(email) {
  try {
    const response = await axios.post('/student', { email });
    const data = await handleResponse(response);
    return data;
  } catch (error) {
    const res = await Promise.reject(error);
    return res;
  }
}

async function getNotificationByUser(userId, companyId, semester) {
  try {
    const response = await apiClient.get(`/notifications/custom/${userId}/${companyId}/${semester}`);
    const data = await handleResponse(response);
    return data;
  } catch (error) {
    const res = await Promise.reject(error.response.data.message);
    return res;
  }
}

async function setUnitTracker(userId, unitId, time) {
  const body = {
    unit: unitId,
    time,
  };

  try {
    const response = await apiClient.put(`/unittrackers/timer/${userId}`, body);
    const data = await handleResponse(response);
    return data;
  } catch (error) {
    const res = await Promise.reject(error.response.data.message);
    return res;
  }
}

async function forgotPassword(email) {
  const body = ({ email, type: 'student' });

  try {
    const response = await axios.post('/forgotPassword', body);
    const data = await handleResponse(response);
    return data;
  } catch (error) {
    const res = await Promise.reject(error.response.data.message);
    return res;
  }
}

async function getFixTime() {
  try {
    const response = await apiClient.get('/unittrackers/time/');
    const data = await handleResponse(response);
    return data;
  } catch (error) {
    const res = await Promise.reject(error.response.data.message);
    return res;
  }
}

const sendNewPassword = async (values) => {
  const body = {
    token: values.token,
    password: values.password,
  };

  try {
    const response = await axios.put('/resetPassword', body);
    const data = await handleResponse(response);
    return data;
  } catch (error) {
    if (error.status === 400) {
      Promise.reject(new Error('Invalid body'));
    } else if (error.status === 404) {
      Promise.reject(new Error('Token expired, please ask for a new password'));
    } else if (error.status === 500) {
      Promise.reject(new Error('Server was unable to reset your password'));
    }
    return error.response.data.message;
  }
};

async function refreshAuth(email, refreshToken) {
  const body = JSON.stringify({
    email,
    refreshToken,
  });

  const response = await axios.post('/refresh', body);
  const auth = await handleResponse(response);
  const expiring = new Date();
  expiring.setMinutes(60 * 5 + 5);
  Cookies.set('auth', auth.token, { expires: expiring }, { secure: true }, { sameSite: 'strict' });
  return auth;
}

function logout() {
  localStorage.removeItem('auth');
  localStorage.removeItem('group');
  localStorage.removeItem('modules');
  localStorage.removeItem('user');
  localStorage.removeItem('test');
  localStorage.removeItem('currentGroup');
  localStorage.clear();
  sessionStorage.removeItem('code');
  sessionStorage.removeItem('sem');
  sessionStorage.clear();
  Cookies.remove('auth');
  Cookies.remove('refresh');
}

const userService = {
  login,
  createUser,
  sendNewMail,
  requestNewPassword,
  updateUserNames,
  verifyAccount,
  verifyEmail,
  sendNewEmail,
  updateSatisfaction,
  getUserById,
  getNotificationByUser,
  setUnitTracker,
  forgotPassword,
  sendNewPassword,
  getStudentWithCompany,
  refreshAuth,
  getFixTime,
  logout,
};

export default userService;
