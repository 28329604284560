/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Timer from '@material-ui/icons/Timer';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    maxWidth: '250px',
    width: '10%',
    padding: '10px',
    display: 'flex',
    backgroundColor: theme.palette.primary.main,
    borderRadius: 50,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    top: 50,
    color: 'white',
    right: '10%',
    zIndex: 100,
  },
}));

const ExamTimer = ({
  timeLeft,
}) => {
  const classes = useStyles();
  const getFormatedDate = (time) => `${time >= 60 ? `${Math.floor(time / 60)}h ` : ''} ${time % 60 > 0 ? `${Math.round(time % 60)}min` : ''}`;

  return (
    <div
      color="primary"
      className={classes.container}
    >
      <Timer color="white" />
      {getFormatedDate(timeLeft)}
    </div>
  );
};

ExamTimer.propTypes = {
  timeLeft: PropTypes.number.isRequired,
};

function mapState() {
  return { };
}
export default connect(mapState, null)(ExamTimer);
