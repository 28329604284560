/* eslint-disable no-unused-expressions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import Badge from '@material-ui/core/Badge';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { connect } from 'react-redux';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Links from './components/Menu';
import ProfileMenu from './components/ProfileMenu';
import SemesterSwitcher from './components/SemesterSwitcher';
import TimeCounter from './components/TimeCounter';
import userServices from '../../redux/services/userServices';
import Notifications from './components/Notifications';

const drawerWidth = 270;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  logo: {
    width: 50,
    marginRight: '1em',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const Layout = ({
  currentPage,
  children,
  location,
  currentGroup,
  data,
  _id,
  company,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorSemesterEl, setAnchorSemesterEl] = useState(null);
  const [anchorNotificationsEl, setAnchorNotificationsEl] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [errorSnackBar, setErrorSnackBar] = useState(null);
  const [isPlacementTest, setisPlacementTest] = useState(false);
  const [badgeNumber, setBadgeNumber] = useState(1);
  const { innerWidth: width } = window;
  const isSmallScreen = width < 450;

  useEffect(() => {
    if (location.pathname === '/test' || currentPage === 'Placement test') {
      setisPlacementTest(true);
    } else {
      setisPlacementTest(false);
    }
    let mounted = true;
    // const checked = localStorage.getItem('notificationsChecked');
    // setNotificationsChecked(Boolean(checked));
    userServices.getNotificationByUser(_id, company._id)
      .then((res) => {
        if (mounted) {
          const tmp = [];
          res.forEach((notif) => {
            if (notif.range === 'Student') {
              tmp.push(notif);
            }
          });
          setNotifications(tmp);
        }
      });
    // eslint-disable-next-line no-return-assign
    return () => mounted = false;
  }, [currentPage, location]);

  useEffect(() => {
    setBadgeNumber(notifications.length);
  }, [notifications]);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSemesterSwitchOpen = (event) => {
    setAnchorSemesterEl(event.currentTarget);
  };

  const handleSemesterSwitchClose = () => {
    setAnchorSemesterEl(null);
  };

  const handleNotificationsOpen = (event) => {
    localStorage.setItem('notificationsChecked', true);
    setAnchorNotificationsEl(event.currentTarget);
  };

  const handleNotificationsClose = () => {
    setAnchorNotificationsEl(null);
  };
  const [open, setOpen] = useState(false);

  const mobileProfilMenuId = 'account-menu-mobile';
  const semesterSwitcherId = 'semester-switcher';

  return (
    <div className={classes.root}>
      <Snackbar open={errorSnackBar !== null} onClose={() => { setErrorSnackBar(null); }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert severity={errorSnackBar !== null ? errorSnackBar.severity : 'info'} variant="filled" onClose={() => { setErrorSnackBar(null); }}>
          {`Error ${errorSnackBar?.code}: ${errorSnackBar?.message}`}
        </Alert>
      </Snackbar>
      <AppBar
        position="fixed"
        className={classes.appBar}
      >
        <Toolbar>
          {!isPlacementTest && <TimeCounter />}
          {
            !isPlacementTest && (data.length > 0) && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                className={classes.menuButton}
                onClick={() => setOpen(true)}
              >
                <MenuIcon />
              </IconButton>
            )
          }
          <img src="/img/logo-whitey_cropped.png" className={classes.logo} alt="mw logo" />
          <Typography variant="h6">
            {currentPage}
          </Typography>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            {
              (data.length > 0 && (location.pathname !== '/test' && currentPage !== 'Placement test')) && (
                <Button
                  variant="outlined"
                  color="default"
                  startIcon={anchorSemesterEl === null ? <ExpandMore /> : <ExpandLess />}
                  onClick={handleSemesterSwitchOpen}
                  disabled={data.length === 1}
                  style={{ color: 'white', fontWeight: 'bold', borderColor: '#ddd' }}
                >
                  {data[currentGroup].name}
                </Button>
              )
            }
          </div>
          {
            (location.pathname !== '/test' && currentPage !== 'Placement test') && (
              <IconButton
                onClick={handleNotificationsOpen}
                aria-label="show notifications"
                color="inherit"
              >
                <Badge badgeContent={badgeNumber} color="secondary">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            )
          }
          {
            !isSmallScreen && (
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={mobileProfilMenuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            )
            }
        </Toolbar>
      </AppBar>
      <ProfileMenu handleMenuClose={handleProfileMenuClose} anchorEl={anchorEl} />
      <Notifications
        handleMenuClose={handleNotificationsClose}
        anchorEl={anchorNotificationsEl}
        notifications={notifications}
      />
      <SemesterSwitcher
        handleMenuClose={handleSemesterSwitchClose}
        anchorEl={anchorSemesterEl}
        id={semesterSwitcherId}
      />
      <Drawer
        className={classes.drawer}
        classes={{
          paper: classes.drawerOpen,
        }}
        variant="temporary"
        anchor="left"
        open={open}
      >
        <Links
          selectedRoute={location.pathname}
          handleDrawerClose={() => setOpen(false)}
        />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
};

Layout.propTypes = {
  currentPage: PropTypes.string.isRequired,
  _id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  currentGroup: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
};

function mapState(state) {
  const { currentPage, currentGroup } = state.ui;
  const { userData } = state.user;
  const { _id, company } = userData;
  const { data } = state.group;

  return {
    currentPage, data, currentGroup, _id, company,
  };
}

export default withRouter(connect(mapState, null)(Layout));
