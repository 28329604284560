/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import examType1Services from '../../../../../redux/services/examType1Services';

const useStyles = makeStyles(() => ({
  dateContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '20vh',
    justifyContent: 'space-around',
  },
  alertContainer: {
    width: '100%',
    height: '60vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const NoExamDisplay = ({
  examText, getFormatedDate, examBegin, examEnd, examType, userId, activityId,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.alertContainer}>
      <Alert severity="info">
        {examText}
      </Alert>
      <div className={classes.dateContainer}>
        <Alert icon={false} variant="outlined" severity="info">
          {'Date de début : '}
          {getFormatedDate(examBegin)}
          <br />
          <br />
          {'Date de fin : '}
          {getFormatedDate(examEnd)}
        </Alert>
        {/* {examType === 1 && examId
          && (
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            component={Link}
            to={{
              pathname: '/elearning/exam/results',
              state: { examId },
            }}
          >
            Voir le détail des résultats
          </Button>
          )} */}
      </div>
    </div>
  );
};

NoExamDisplay.propTypes = {
  examText: PropTypes.string.isRequired,
  examBegin: PropTypes.instanceOf(Date).isRequired,
  examEnd: PropTypes.instanceOf(Date).isRequired,
  getFormatedDate: PropTypes.func.isRequired,
  examType: PropTypes.number.isRequired,
  userId: PropTypes.string.isRequired,
  activityId: PropTypes.string.isRequired,
};

export default NoExamDisplay;
