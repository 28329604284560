import learningLinksConstants from '../constants/learningLinksConstants';

const initialState = {
  requestingThemes: false,
  requestingTopics: false,
  requestingTopicLinks: false,
  themes: [],
  topics: [],
  topicLinks: [],
  links: [],
};

export default function learningLinks(state = initialState, action) {
  switch (action.type) {
    case learningLinksConstants.GET_THEMES_REQUEST:
      return {
        ...state,
        requestingThemes: true,
      };
    case learningLinksConstants.GET_THEMES_SUCCESS:
      return {
        ...state,
        requestingThemes: false,
        themes: action.themes,
      };
    case learningLinksConstants.GET_THEMES_FAILURE:
      return {
        ...state,
        requestingThemes: false,
      };
    case learningLinksConstants.GET_TOPICS_REQUEST:
      return {
        ...state,
        requestingTopics: true,
      };
    case learningLinksConstants.GET_TOPICS_SUCCESS:
      return {
        ...state,
        requestingTopics: false,
        topics: action.topics,
      };
    case learningLinksConstants.GET_TOPICS_FAILURE:
      return {
        ...state,
        requestingTopics: false,
      };
    case learningLinksConstants.GET_TOPICS_LINKS_REQUEST:
      return {
        ...state,
        requestingTopicLinks: true,
      };
    case learningLinksConstants.GET_TOPICS_LINKS_SUCCESS:
      return {
        ...state,
        requestingTopicLinks: false,
        topicLinks: action.topicLinks,
      };
    case learningLinksConstants.GET_TOPICS_LINKS_FAILURE:
      return {
        ...state,
        requestingTopicLinks: false,
      };
    case learningLinksConstants.GET_LINKS_REQUEST:
      return {
        ...state,
        requestingLinks: true,
      };
    case learningLinksConstants.GET_LINKS_SUCCESS:
      return {
        ...state,
        requestingLinks: false,
        links: action.links,
      };
    case learningLinksConstants.GET_LINKS_FAILURE:
      return {
        ...state,
        requestingLinks: false,
      };
    default:
      return state;
  }
}
