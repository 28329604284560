/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  CardActionArea,
  Grid,
} from '@material-ui/core';
import config from '../../../../config';

const useStyles = makeStyles({
  cardContainer: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderRadius: 0,
    textAlign: 'center',
    marginBottom: '1em',
    marginTop: '1em',
    padding: '1em',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  iconContainer: {
    fontSize: '4rem',
  },
  text: {
    lineHeight: 1.2,
    maxWidth: '70%',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    textAlign: 'center',
  },
});

const ResourceCard = ({
  title, filename, type, icon,
}) => {
  const classes = useStyles();
  const resourceUrl = `${config.OBJECT_URL}/resources/${type}/${filename}`;

  return (
    <Grid item lg={4} md={4} sm={6} xs={12}>
      <CardActionArea
        component="a"
        href={resourceUrl}
        download
        target="_blank"
        className={classes.cardContainer}
      >
        {/* Icône centrée */}
        <div className={classes.iconContainer}>
          {icon}
        </div>

        {/* Nom du fichier sous l'icône */}
        <Typography variant="h6" className={classes.text}>
          {title.replaceAll('_', ' ')}
        </Typography>
      </CardActionArea>
    </Grid>
  );
};

ResourceCard.defaultProps = {
  title: 'Default Title',
  filename: 'default.pdf',
  type: 'pdf',
};

ResourceCard.propTypes = {
  title: PropTypes.string,
  filename: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.node.isRequired,
};

export default ResourceCard;
