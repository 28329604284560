/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Grid,
  List,
} from '@material-ui/core';
import LinkLoader from '../../Loader/FlatCardLoader';
import LinkItem from './LinkItem';
import learningLinksActions from '../../../redux/actions/learningLinksActions';

const DisplayLinks = ({ theme, learningLinks, getLinks }) => {
  useEffect(() => {
    getLinks(theme);
  }, []);

  const displayContent = () => {
    if (learningLinks.requestingLinks) {
      return (
        <LinkLoader />
      );
    }
    if (learningLinks.links) {
      return learningLinks.links.map((link) => (
        <LinkItem
          key={link.url}
          name={link.name}
          url={link.url}
        />
      ));
    }
    return null;
  };

  return (
    <Grid container>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <List>
          <Grid container>
            {displayContent()}
          </Grid>
        </List>
      </Grid>
    </Grid>
  );
};

DisplayLinks.defaultProps = {
  theme: 'Default',
};

DisplayLinks.propTypes = {
  learningLinks: PropTypes.object.isRequired,
  theme: PropTypes.string,
  getLinks: PropTypes.func.isRequired,
};

function mapState(state) {
  const { learningLinks } = state;
  return { learningLinks };
}

const actionCreators = {
  getLinks: learningLinksActions.getLinksByTheme,
};

const connectedDisplayLinks = connect(mapState, actionCreators)(DisplayLinks);
export default connectedDisplayLinks;
