/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import {
  Dialog, DialogActions, DialogContent, Button, useMediaQuery,
} from '@material-ui/core';
import config from '../../../../../../../config';

const useStyles = makeStyles(() => ({
  imgContainer: {
    margin: '4em 5em',
    // maxHeight: '70vh',
    overflow: 'auto',
    overflowX: 'hidden',
  },
  img: {
    objectFit: 'contain',
    textAlign: 'center',
  },
}));

const DisplayImage = ({ filename }) => {
  const theme = useTheme();
  const classes = useStyles();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const [imgDialog, setImgDialog] = useState(false);

  const openImg = () => {
    setImgDialog(true);
  };

  const closeImg = () => {
    setImgDialog(false);
  };
  return (
    <div>
      {!xs ? (
        <div className={classes.imgContainer}>
          <img
            className={classes.img}
            alt="Question Document"
            src={`${config.OBJECT_URL}/placementTest/img/${filename}`}
          />
        </div>
      ) : (
        <div>
          <Button variant="outlined" color="primary" onClick={openImg}>
            <img
              className={classes.img}
              alt="Question Document"
              src={`${config.OBJECT_URL}/placementTest/img/${filename}`}
            />
          </Button>
          <Dialog
            open={imgDialog}
            aria-labelledby="image PT"
            scroll="body"
            onBackdropClick={closeImg}
          >
            <DialogContent>
              <img
                src={`${config.OBJECT_URL}/placementTest/img/${filename}`}
                alt="question media"
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={closeImg}
                color="primary"
                autoFocus
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </div>
  );
};

DisplayImage.defaultProps = {
  filename: '',
};

DisplayImage.propTypes = {
  filename: PropTypes.string,
};

export default DisplayImage;
