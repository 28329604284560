/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

const GoBackButton = ({ location }) => {
  const url = location.pathname.slice(0, location.pathname.lastIndexOf('/'));

  return (
    <IconButton
      aria-label="back-button"
      component={Link}
      to={url}
    >
      <ArrowBackIcon />
    </IconButton>
  );
};

GoBackButton.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(GoBackButton);
