import {
  apiClient,
  handleResponse,
} from './axios';

async function addProgressTrackers(userId, body) {
  const response = await apiClient.post(`/progresstrackers/${userId}`, body);
  return handleResponse(response);
}

async function getGroup(userId) {
  const response = await apiClient.get(`/group/user/${userId}`);
  return handleResponse(response);
}

async function getGroupWithExam(groupId) {
  const response = await apiClient
    .get(`/group/exam/${groupId}`);
  const group = await handleResponse(response);
  return group;
}

async function getGroupsDataByCompagny(companyId) {
  const res = await apiClient
    .get(`/group/company/${companyId}`);
  const groups = res;
  return groups;
}

const groupService = {
  getGroup,
  getGroupWithExam,
  getGroupsDataByCompagny,
  addProgressTrackers,
};

export default groupService;
