const placementTestConstants = {
  INIT_REQUEST: 'PLACEMENT_TEST_INIT_REQUEST',
  INIT_SUCCESS: 'PLACEMENT_TEST_INIT_SUCCESS',
  INIT_FAILURE: 'PLACEMENT_TEST_INIT_FAILURE',

  SET_CURRENT_QUESTION: 'SET_CURRENT_QUESTION',
  SET_MULTI_QUESTION_ID: 'SET_MULTI_QUESTION_ID',
  SET_CURRENT_ANSWER: 'SET_CURRENT_ANSWER',
  PUSH_ANSWER: 'PUSH_ANSWER',

  CLEAR_TEST: 'CLEAR_TEST',
};

export default placementTestConstants;
