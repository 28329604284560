/* eslint-disable no-console */
import {
  apiClient,
  handleResponse,
} from './axios';

async function getCompany(id) {
  try {
    const response = await apiClient.get(`/company/${id}`);
    const company = await handleResponse(response);
    return company;
  } catch (error) {
    const res = await Promise.reject(error.response.data);
    return res;
  }
}

async function getCompanyByStudent(userId) {
  try {
    const response = await apiClient.get(`/company/student/${userId}`);
    const company = await handleResponse(response);
    return company;
  } catch (error) {
    const res = await Promise.reject(error.response.data);
    return res;
  }
}

async function getCompanyDataById(companyId) {
  try {
    const response = await apiClient.get(`/companyData/${companyId}`);
    const user = await handleResponse(response);
    return user;
  } catch (error) {
    const res = await Promise.reject(error.response.data);
    return res;
  }
}

const companyServices = {
  getCompany,
  getCompanyByStudent,
  getCompanyDataById,
};

export default companyServices;
