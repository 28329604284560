/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography, Card, CardActionArea, Grid, Container,
} from '@material-ui/core';
import parse from 'html-react-parser';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(2),
  },
}));

const NotificationsWidget = ({ notification }) => {
  const classes = useStyles();
  const getDate = (date) => {
    const localeDate = new Date(date).toLocaleString('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });
    return localeDate;
  };

  return (
    <Card>
      <CardActionArea
        className={classes.root}
      >
        <Container maxWidth="lg">
          <Grid
            item
            xs={12}
            md={12}
            style={{
              width: '100%', justifyContent: 'space-between', display: 'flex', flexDirection: 'row', alignItems: 'center',
            }}
          >
            <Typography variant="h5" color="textPrimary">
              Notification:
            </Typography>
            <Typography variant="body1" color="textPrimary">
              { notification ? getDate(notification.created_at) : '' }
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="body1" color="textPrimary">
              { notification ? parse(notification.message) : '' }
            </Typography>
          </Grid>
        </Container>
      </CardActionArea>
    </Card>
  );
};

NotificationsWidget.propTypes = {
  notification: PropTypes.object.isRequired,
};

export default connect(null, {})(NotificationsWidget);
