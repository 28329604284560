const alertConstants = {
  SUCCESS: 'ALERT_SUCCESS',
  ERROR: 'ALERT_ERROR',
  CLEAR: 'ALERT_CLEAR',

  ENQUEUE_SNACKBAR: 'ENQUEUE_SNACKBAR',
  CLOSE_SNACKBAR: 'CLOSE_SNACKBAR',
  REMOVE_SNACKBAR: 'REMOVE_SNACKBAR',
};

export default alertConstants;
