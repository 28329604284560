import glossaryConstants from '../constants/glossaryConstants';

const initialState = {};

export default function glossary(state = initialState, action) {
  switch (action.type) {
    case glossaryConstants.GLOSSARY_REQUEST:
      return {
        ...state,
        requesting: true,
      };
    case glossaryConstants.GLOSSARY_SUCCESS:
      return {
        requesting: false,
        glossary: action.glossary,
      };
    case glossaryConstants.GLOSSARY_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.error,
      };
    case glossaryConstants.ADD_GLOSSARY_REQUEST:
      return {
        ...state,
        requesting: true,
      };
    case glossaryConstants.ADD_GLOSSARY_SUCCESS:
      return {
        requesting: false,
        glossary: action.glossary,
      };
    case glossaryConstants.ADD_GLOSSARY_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.error,
      };
    default:
      return state;
  }
}
