/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Typography,
  List,
  ListItem,
  IconButton,
  Tooltip,
  Paper,
} from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert/Alert';
import googleStorageServices from '../../../redux/services/googleStorageServices';

const ZoomLinks = ({ group, company }) => {
  const [filesList, setFilesList] = useState([]);

  const getFilesFromStorage = async () => {
    const files = await googleStorageServices.getFiles('zoom_link', company.name, group.name);
    if (files && files.data && files.data.length > 0) {
      setFilesList(files.data[0]);
    }
  };

  const handleDownload = async (filename) => {
    const downloadedFile = await googleStorageServices.downloadFile(filename);
    const buffer = Buffer.from(downloadedFile.data[0]);
    const blob = new Blob([buffer]);

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    a.href = url;
    a.download = filename.replace(`documents_clients/zoom_link/${company.name}/${group.name}/`, '');
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  };

  useEffect(() => {
    getFilesFromStorage();
  }, [group]);

  if (!filesList || filesList.length <= 0) {
    return null;
  }
  return (
    <Paper style={{
      width: '100%', flexDirection: 'row', padding: '30px',
    }}
    >
      <Typography variant="h6" style={{ marginBottom: '15px' }}>Group distribution: </Typography>
      <Alert severity="info" icon={false} style={{ marginBottom: '10px' }}>
        You can find here the group distribution, the trainer assigned to your group,
        as well as your Zoom link if your classes are held remotely.
      </Alert>
      {filesList.length > 0
        ? (
          <List>
            {filesList.map((file) => (
              <ListItem>
                <Tooltip title="Download file">
                  <IconButton
                    style={{
                      marginRight: '15px',
                    }}
                    color="primary"
                  >
                    <CloudUpload
                      onClick={() => handleDownload(file.name)}
                    />
                  </IconButton>
                </Tooltip>
                <Typography variant="body2">
                  {file.name.replace(`documents_clients/zoom_link/${company.name}/${group.name}/`, '')}
                </Typography>
              </ListItem>
            ))}
          </List>
        )
        : <Typography>Pas de fichier(s) trouvé(s)</Typography>}
    </Paper>
  );
};

export default ZoomLinks;
