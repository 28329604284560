/* eslint-disable max-len */
/* eslint-disable import/no-duplicates */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import Cookies from 'js-cookie';
import config from '../../config';
import userConstants from '../constants/userConstants';
import userService from '../services/userServices';
import alertActions from './alertActions';
import groupActions from './groupActions';

function getUser(userId) {
  function request(user) { return { type: userConstants.GET_USER_REQUEST, user }; }
  function success(user) { return { type: userConstants.GET_USER_SUCCESS, user }; }
  function failure(error) { return { type: userConstants.GET_USER_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(userId));
    return userService.getUserById(userId)
      .then(
        (user) => {
          const tmp = user;
          if (!user.additionalTime) {
            tmp.additionalTime = 0;
          }
          if (user.additionalTimeOnce) {
            tmp.additionalTime += user.additionalTimeOnce;
          }
          localStorage.setItem('user', JSON.stringify(tmp));
          dispatch(success(tmp));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        },
      );
  };
}

function createUser(user) {
  function request(userRequest) {
    return {
      type: userConstants.CREATE_USER_REQUEST,
      userRequest,
    };
  }

  function success(userSuccess) {
    return {
      type: userConstants.CREATE_USER_SUCCESS,
      user: userSuccess,
    };
  }

  function failure(error) {
    return {
      type: userConstants.CREATE_USER_FAILURE,
      error,
    };
  }

  return (dispatch) => {
    const newUser = {
      email: user.email,
      password: user.password,
      firstname: user.firstname,
      name: user.name,
      type: user.type,
      jobTitle: user.jobTitle ? user.jobTitle : null,
      phone: user.phone ? user.phone : null,
      needs: user.needs ? user.needs : null,
      semester: user.semester,
      company: user.company,
      code: user.code,
      placementtest: user.placementtest,
    };
    dispatch(request(newUser));
    return userService.createUser(newUser)
      .then(
        async (auth) => {
          localStorage.setItem('auth', JSON.stringify(auth));
          // userServices.sendNewMail(auth, true);
          await dispatch(getUser(auth._id));
          await dispatch(success(auth));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        },
      );
  };
}

function confirmAccount(id, code) {
  function confirmRequest() { return { type: userConstants.CONFIRM_REQUEST }; }
  function confirmSuccess(user) { return { type: userConstants.CONFIRM_SUCCESS, user }; }
  function confirmFailure(error) { return { type: userConstants.CONFIRM_FAILURE, error }; }

  return (dispatch) => {
    dispatch(confirmRequest());
    return userService.verifyAccount(id, code)
      .then(
        async (auth) => {
          const isAuth = !!localStorage.getItem('auth');
          if (isAuth) {
            localStorage.setItem('auth', JSON.stringify(auth));
            const expiring = new Date();
            expiring.setMinutes(60 * 5 + 5);
            Cookies.set('auth', auth.token, { expires: null }, { secure: true }, { sameSite: 'strict' });
            Cookies.set('refresh', auth.refreshToken, { expires: 365 }, { secure: true }, { sameSite: 'strict' });
            if (!auth.testFinish) await dispatch({ type: userConstants.SET_TEST });
            await dispatch(getUser(auth._id));
            await dispatch(groupActions.getGroup(auth._id));
            dispatch(confirmSuccess(auth));
          }
        },
        (error) => {
          dispatch(confirmFailure(error.toString()));
          dispatch(alertActions.error(new Error('Impossible de vous connecter, verifiez vos identifiants.')));
        },
      )
      .catch(() => {
      });
  };
}

function login(email, password, remember) {
  function loginRequest() { return { type: userConstants.LOGIN_REQUEST }; }
  function loginSuccess(auth) { return { type: userConstants.LOGIN_SUCCESS, auth }; }
  function loginFailure(error) { return { type: userConstants.LOGIN_FAILURE, error }; }

  return (dispatch) => {
    dispatch(loginRequest({ email }));
    return userService.login(email, password)
      .then(
        async (auth) => {
          Cookies.remove('auth');
          Cookies.remove('currentGroup');
          Cookies.remove('refresh');
          localStorage.clear();
          const today = new Date();
          if (today >= new Date(config.START_CLOSING_PLATFORM) && today <= new Date(config.END_CLOSING_PLATFORM)) {
            dispatch(loginFailure('error.toString()'));
            return 'Maintenance';
          }
          localStorage.setItem('auth', JSON.stringify(auth));
          const expiring = new Date();
          expiring.setMinutes(60 * 5 + 5);
          Cookies.set('auth', auth.token, { expires: remember ? null : expiring }, { secure: true }, { sameSite: 'strict' });
          Cookies.set('refresh', auth.refreshToken, { expires: 365 }, { secure: true }, { sameSite: 'strict' });
          if (!auth.testFinish) await dispatch({ type: userConstants.SET_TEST });
          await dispatch(getUser(auth._id));
          await dispatch(groupActions.getGroup(auth._id));
          dispatch(loginSuccess(auth));
          return null;
        },
        // eslint-disable-next-line consistent-return
        (error) => {
          dispatch(loginFailure(error.toString()));
          if (error === 'Company blocked') {
            return error;
          }
          dispatch(alertActions.error(new Error('Impossible de vous connecter, verifiez vos identifiants.')));
        },
      )
      .catch((error) => {
        console.error(error);
      });
  };
}

function requestNewPassword(email) {
  function requestNewPasswordRequest() {
    return { type: userConstants.REQUEST_NEW_PASSWORD_REQUEST };
  }
  function requestNewPasswordSuccess(token) {
    return { type: userConstants.REQUEST_NEW_PASSWORD_SUCCESS, token };
  }
  function requestNewPasswordFailure(error) {
    return { type: userConstants.REQUEST_NEW_PASSWORD_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(requestNewPasswordRequest({ email }));
    return userService.requestNewPassword(email)
      .then(
        (token) => {
          dispatch(requestNewPasswordSuccess(token));
        },
        (error) => {
          dispatch(requestNewPasswordFailure(error.toString()));
        },
      )
      .catch((error) => {
        console.error(error);
      });
  };
}

function forgotPassword(email) {
  function passwordRequest() { return { type: userConstants.REQUEST_NEW_PASSWORD, email }; }
  function passwordSuccess() { return { type: userConstants.SUCCESS_NEW_PASSWORD }; }
  function passwordFailure(error) { return { type: userConstants.FAILURE_NEW_PASSWORD, error }; }

  return (dispatch) => {
    dispatch(passwordRequest({ email }));
    return userService.forgotPassword(email)
      .then(
        () => {
          dispatch(passwordSuccess());
        },
        (error) => {
          dispatch(passwordFailure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        },
      )
      .catch((error) => {
        console.error(error);
        dispatch(passwordFailure(error.toString()));
      });
  };
}

function setAzureToken(token) {
  return (dispatch) => {
    dispatch({
      type: userConstants.SET_AZURE_TOKEN,
      azureToken: token,
    });
  };
}

function clearLogin() {
  return (dispatch) => {
    dispatch({
      type: userConstants.CLEAR_LOGIN,
    });
  };
}

function logout() {
  return (dispatch) => {
    userService.logout();
    Cookies.remove('currentGroup');
    Cookies.remove('refresh');
    Cookies.remove('auth');
    localStorage.clear();
    dispatch({ type: 'USER_LOGGED_OUT' });
    document.location.reload();
  };
}

function updateUserNames(userId, body) {
  function refreshRequest(data) { return { type: userConstants.UPDATE_USER_REQUEST, data }; }
  function refreshSuccess() { return { type: userConstants.UPDATE_USER_SUCCESS }; }
  function refreshFailure(error) { return { type: userConstants.UPDATE_USER_FAILURE, error }; }
  return (dispatch) => {
    dispatch(refreshRequest(body));
    userService.updateUserNames(userId, body)
      .then(
        async () => {
          dispatch(refreshSuccess());
          await dispatch(getUser(userId));
          dispatch(alertActions.successWithToast('Names succesfully updated'));
        },
        (error) => {
          dispatch(refreshFailure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        },
      );
  };
}

const userActions = {
  login,
  createUser,
  confirmAccount,
  updateUserNames,
  getUser,
  requestNewPassword,
  logout,
  forgotPassword,
  setAzureToken,
  clearLogin,
};

export default userActions;
