import {
  apiClient,
  handleResponse,
} from './axios';

async function initTest(codeId, body) {
  try {
    const response = await apiClient.post(`/test/init/${codeId}`, body);
    const test = await handleResponse(response);
    return test;
  } catch (error) {
    const res = await Promise.reject(error.response.data.message);
    return res;
  }
}

async function sendTest(testId, body) {
  try {
    const response = await apiClient.put(`/test/${testId}`, body);
    const res = await handleResponse(response);
    return res;
  } catch (error) {
    const res = await Promise.reject(error.response.data.message);
    return res;
  }
}

async function getTest(userId) {
  try {
    const response = await apiClient.get(`/test/user/${userId}`);
    const res = await handleResponse(response);
    return res;
  } catch (error) {
    const res = await Promise.reject(error.response.data.message);
    return res;
  }
}

async function postStudentToGroup(companyId, level, userId, semester) {
  const body = {
    level: `${level}`,
    user: userId,
    semester: `${semester}`,
  };
  try {
    const response = await apiClient.post(`/group/associate/${companyId}`, body);
    const res = await handleResponse(response);
    return res;
  } catch (error) {
    const res = await Promise.reject(error.response.data.message);
    return res;
  }
}

const placementTestServices = {
  initTest,
  sendTest,
  getTest,
  postStudentToGroup,
};

export default placementTestServices;
