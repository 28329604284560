/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Card,
  Typography,
} from '@material-ui/core';
import {
  ArrowBack, AssignmentTurnedIn, PictureAsPdf,
} from '@material-ui/icons';
import config from '../../../../config';

import UnitListItem from './UnitListItem';

const useStyles = makeStyles(() => ({
  imgCard: {
    height: '20vh',
  },
  sideMenu: {
    overflowY: 'auto',
    height: '90vh',
    maxHeight: '90vh',
    paddingBottom: '4em',
  },
  moduleName: {
    margin: '1em 0',
  },
}));

const UnitMenu = ({
  units, moduleName, selectedUnit, setSelectedUnit, handleSelectedUnit, mobile, revisionFile,
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.sideMenu}>
      <Typography variant="body1" className={classes.moduleName}>
        <IconButton
          aria-label="back-button"
          component={Link}
          to="/elearning/course"
        >
          <ArrowBack />
        </IconButton>
        {moduleName}
      </Typography>
      <Divider />
      <List>
        <ListItem
          button
          selected={selectedUnit === null}
          onClick={() => setSelectedUnit(null)}
        >
          <ListItemIcon>
            <AssignmentTurnedIn />
          </ListItemIcon>
          <ListItemText
            className={classes.unitTitle}
            primary="Objectives"
          />
        </ListItem>
        <Divider />
        {revisionFile && revisionFile.map(({ fileName, title }) => (
          <ListItem
            button
            component="a"
            href={`${config.OBJECT_URL}/resources/pdf/${fileName}`}
            target="_blank"
          >
            <ListItemIcon>
              <PictureAsPdf />
            </ListItemIcon>
            <ListItemText
              className={classes.unitTitle}
              primary={title}
            />
          </ListItem>
        ))}
        {revisionFile && <Divider />}
      </List>

      <List>
        {units.map((unit, index) => (
          <UnitListItem
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            unit={unit.unit}
            index={index}
            selected={index === selectedUnit}
            selectedUnit={(unitIndex) => handleSelectedUnit(unitIndex)}
          />
        ))}
      </List>
    </Card>
  );
};

UnitMenu.defaultProps = {
  revisionFile: [],
};

UnitMenu.propTypes = {
  units: PropTypes.array.isRequired,
  mobile: PropTypes.bool.isRequired,
  moduleName: PropTypes.string.isRequired,
  selectedUnit: PropTypes.number.isRequired,
  setSelectedUnit: PropTypes.func.isRequired,
  handleSelectedUnit: PropTypes.func.isRequired,
  revisionFile: PropTypes.array,
};

export default UnitMenu;
