/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper, Grid, Box,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  body: {
    width: '100%',
    height: '100%',
    paddingBottom: '1em',
  },
  buttons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    top: '4.5%',
  },
  buttonbg: {
    border: 'none',
    borderRadius: 10,
    backgroundColor: theme.palette.primary.main,
    borderColor: '#A7A6A6',
    color: '#eeeeee',
    padding: 0, /* Removed padding from the container */
    width: '100%',
    height: '100%', /* Makes it fill the parent's height as well */
    display: 'flex', /* Centers the button inside if there's extra space */
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      color: '#ffffff',
      backgroundColor: '#ffffff !important',
    },
  },

  button: {
    border: 'none',
    borderRadius: 10,
    backgroundColor: 'inherit', /* Inherits from buttonbg */
    color: '#eeeeee',
    borderColor: '#A7A6A6',
    fontSize: '120%',
    padding: '0.25em 0', /* Removed padding to align fully with parent */
    width: '100%', /* Ensures full width inside container */
    height: '100%', /* Ensures full height as well */
    '&:hover': {
      color: '#06b1e4',
      backgroundColor: '#ffffff !important',
      transform: 'scale(1.2)',
    },
  },

  selected: {
    color: '#ffffff',
    backgroundColor: '#06b1e4 !important',
  },
}));

const AlphabetMenu = ({ letter, setLetter }) => {
  const classes = useStyles();
  const CreateAlphabetButton = () => {
    const selectors = [];

    for (let i = 65; i <= 90; i += 1) {
      selectors.push(
        <Paper
          className={`${classes.buttonbg} ${String.fromCharCode(i) === letter.toUpperCase() && classes.selected}`}
          elevation={1}
          key={i}
        >
          <button
            className={`${classes.button} ${String.fromCharCode(i) === letter.toUpperCase() && classes.selected}`}
            value={String.fromCharCode(i)}
            onClick={() => setLetter(String.fromCharCode(i))}
          >
            {String.fromCharCode(i)}
          </button>
        </Paper>,
      );
    }
    return selectors;
  };

  return (
    <Grid
      container
      className={classes.body}
    >
      <Box
        display="flex"
        className={classes.buttons}
      >
        <CreateAlphabetButton />
      </Box>
    </Grid>
  );
};

AlphabetMenu.propTypes = {
  letter: PropTypes.string.isRequired,
  setLetter: PropTypes.func.isRequired,
};

export default AlphabetMenu;
