/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const WelcomeTitle = ({ name, lastname }) => {
  const [sentence, setSentence] = useState('');

  useEffect(() => {
    setSentence("Here's your profile,");
  }, []);

  return (
    <Typography variant="h4">{`${sentence} ${name} ${lastname}  `}</Typography>
  );
};

WelcomeTitle.propTypes = {
  name: PropTypes.string.isRequired,
  lastname: PropTypes.string.isRequired,
};

export default WelcomeTitle;
