import examType1Constants from '../constants/examType1Constants';

const exam = JSON.parse(localStorage.getItem('exam'));
const currentQuestion = localStorage.getItem('currentQuestion');
const currentAnswer = localStorage.getItem('currentAnswer');
const answers = JSON.parse(localStorage.getItem('answers'));

const initialState = {
  currentQuestion: Number(currentQuestion) || 0,
  currentAnswer: currentAnswer || [],
  requestingExam: false,
  answers: answers || [],
  exam: exam || null,
  multiQuestionId: 0,
  beginTime: null,
};

export default function placementTestReducer(state = initialState, action) {
  switch (action.type) {
    case examType1Constants.INIT_REQUEST:
      return {
        ...state,
        requestingExam: true,
      };
    case examType1Constants.INIT_SUCCESS:
      return {
        ...state,
        requestingExam: false,
        exam: action.exam,
      };
    case examType1Constants.SET_EXAM_BEGIN_TIME:
      return {
        ...state,
        beginTime: action.time,
      };
    case examType1Constants.INIT_FAILURE:
      return {
        ...state,
        requestingExam: false,
      };
    case examType1Constants.SET_CURRENT_QUESTION:
      return {
        ...state,
        currentQuestion: action.currentQuestion,
      };
    case examType1Constants.SET_MULTI_QUESTION_ID:
      return {
        ...state,
        multiQuestionId: action.multiQuestionId,
      };
    case examType1Constants.SET_CURRENT_ANSWER:
      return {
        ...state,
        currentAnswer: action.currentAnswer,
        created_at: action.created_at,
      };
    case examType1Constants.PUSH_ANSWER:
      return {
        ...state,
        answers: action.answers,
        currentAnswer: [],
      };
    case examType1Constants.CLEAR_EXAM:
      return {
        exam: null,
        answers: [],
        currentQuestion: 0,
        currentAnswer: [],
        multiQuestionId: 0,
        beginTime: null,
      };
    case examType1Constants.CLEAR_EXAM_ANSWERS:
      return {
        ...state,
        answers: [],
        currentQuestion: 0,
      };
    default:
      return state;
  }
}
