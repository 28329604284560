/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Typography,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Button,
  useMediaQuery,
} from '@material-ui/core';
import {
  RadioButtonChecked,
  RadioButtonUnchecked,
  AccessTime,
  PlayArrow,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import config from '../../../../config';
import moduleActions from '../../../../redux/actions/moduleActions';
import { sec2time } from '../../../../helpers/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  spacing: {
    flexBasis: '20%',
    flexShrink: 0,
  },
  itemContainer: {
    alignItems: 'center',
    borderRadius: '10px',
  },
  colored: {
    backgroundColor: '#1bb1e417',
  },
  listText: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  listIcon: {
    marginRight: '0.5em',
  },
  completed: {
    color: '#32CD32',
  },
  progress: {
    color: '#FFA500',
  },
  listItems: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const UnitResultItemList = ({
  index,
  unit,
  module,
  selectUnit,
}) => {
  const classes = useStyles();
  const size = useMediaQuery('(max-width: 700px)');
  const getUnitProgress = () => {
    if (unit.unit.time === undefined || unit.unit.time === null || unit.unit.time === 0) {
      return (
        <div className={classes.listText}>
          <Typography
            variant="body1"
            component="span"
          >
            Not started yet
          </Typography>
        </div>
      );
    } if (unit.unit.time > 0 && unit.verb === 'completed') {
      return (
        <div className={classes.listText}>
          <RadioButtonChecked className={`${classes.listIcon} ${classes.completed}`} />
          <Typography
            variant="body1"
            component="span"
          >
            Finished
          </Typography>
        </div>
      );
    }
    if (unit.unit.time > 0 && (unit.verb === 'passed' || module.isRise)) {
      return (
        <Typography variant="body1">
          {' '}
          Score:
          {' '}
          {unit.result.score ? ((unit.result.score.raw / unit.result.score.max) * 100).toFixed(1) : (unit.result.completion && unit.result.success ? 100 : 0)}
          %
        </Typography>
      );
    }
    return (
      <div className={classes.listText}>
        <RadioButtonUnchecked className={`${classes.listIcon} ${classes.progress}`} />
        <Typography
          variant="body1"
          component="span"
        >
          In progress
        </Typography>
      </div>
    );
  };

  const getUnitTime = () => (
    <div className={classes.listText}>
      <AccessTime className={classes.listIcon} />
      <Typography
        variant="body1"
        component="span"
      >
        {sec2time((unit.unit.time === null || unit.unit.time === undefined) ? 0 : unit.unit.time)}
      </Typography>
    </div>
  );

  const goToUnitBtn = () => (
    <Button
      variant="contained"
      color="primary"
      onClick={() => {
        selectUnit(module.units[Number(index)].unit._id);
      }}
      startIcon={(
        <PlayArrow
          fontSize="large"
        />
      )}
      className="primary-color"
      component={Link}
      to={{
        pathname: `/elearning/course/${module._id}`,
        state: {
          title: module.name,
          selectedUnit: Number(index),
        },
      }}
    >
      {`Go to ${unit.unit.isFinalQuiz ? 'Quiz' : 'Unit'}`}
    </Button>
  );

  return (
    <>
      <ListItem
        alignItems="flex-start"
        style={{
          display: 'flex',
          flexDirection: size ? 'column' : 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: size ? '15px' : null,
          textAlign: size ? 'center' : 'start',
        }}
        className={index % 2 === 0 ? [classes.colored, classes.itemContainer] : classes.itemContainer}
      >
        <ListItemAvatar style={{ display: 'flex', justifyContent: size ? 'center' : 'start' }}>
          <Avatar alt={unit.unit.name} src={`${config.OBJECT_URL}/thumbnail/${unit.unit.thumbnail}`} />
        </ListItemAvatar>
        <ListItemText
          className={classes.spacing}
          primary={unit.unit.name}
          secondary={(
            <Typography
              component="span"
              variant="body1"
              className={classes.inline}
              color="textPrimary"
            >
              {unit.unit.isFinalQuiz ? 'Quiz' : `Chapitre ${index + 1}`}
            </Typography>
          )}
        />
        <ListItemText className={classes.spacing} primary={getUnitProgress()} />
        <ListItemText className={classes.spacing} primary={getUnitTime()} />
        <ListItemText className={classes.spacing} primary={goToUnitBtn()} />
      </ListItem>
    </>
  );
};

UnitResultItemList.propTypes = {
  index: PropTypes.number.isRequired,
  module: PropTypes.object.isRequired,
  unit: PropTypes.object.isRequired,
  selectUnit: PropTypes.func.isRequired,
};

function mapState() {
  return {

  };
}

const actionCreator = {
  selectUnit: moduleActions.selectUnit,
};

export default connect(mapState, actionCreator)(UnitResultItemList);
