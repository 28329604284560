/* eslint-disable no-underscore-dangle */
import groupConstants from '../constants/groupConstants';
import groupServices from '../services/groupServices';

function getGroup(userId) {
  function request(groupId) { return { type: groupConstants.GET_GROUP_REQUEST, groupId }; }
  function success(group) { return { type: groupConstants.GET_GROUP_SUCCESS, group }; }
  function failure(error) { return { type: groupConstants.GET_GROUP_FAILURE, error }; }

  return (dispatch) => {
    dispatch(request(userId));
    return groupServices.getGroup(userId)
      .then(
        (group) => {
          // reversing array to display the last group first
          const reversed = group.reverse();
          localStorage.setItem('group', JSON.stringify(reversed));
          dispatch(success(reversed));
        },
        (error) => {
          dispatch(failure(error.toString()));
        },
      );
  };
}

const groupActions = {
  getGroup,
};

export default groupActions;
