/* eslint-disable no-console */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, LinearProgress, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  colorPrimary: {
    backgroundColor: '#DDD',
  },
  barColorPrimary: {
    backgroundColor: '#FF6C53',
  },
  barColorCompleted: {
    backgroundColor: '#66DD66',
  },
});

const LinearProgressWithLabel = (props) => {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" style={{ width: '100%' }}>
      <Box width="100%" color="#00FF00" mr={1}>
        <LinearProgress
          variant="determinate"
          classes={{
            colorPrimary: classes.colorPrimary,
            barColorPrimary: props.objective === 100 || props.objective === '100.0' ? classes.barColorCompleted : classes.barColorPrimary,
          }}
          {...props}
        />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body1" color="textSecondary" style={{ textAlign: 'end' }}>
          {`${Math.floor(
            props.value,
          )}%`}
        </Typography>
      </Box>
    </Box>
  );
};

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
  objective: PropTypes.number.isRequired,
};

export default LinearProgressWithLabel;
