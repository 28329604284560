/* eslint-disable max-len */
/* eslint-disable padded-blocks */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Cookies from 'js-cookie';
import { numGradeToEUgrade, convertCEFRToElearningLevel, toulouseSchools } from '../../../helpers/utils';
import uiActions from '../../../redux/actions/uiActions';
import placementTestServices from '../../../redux/services/placementTestServices';
import groupActions from '../../../redux/actions/groupActions';

const SemesterSwitcher = ({
  data, setCurrentGroup, anchorEl, handleMenuClose, id, semester, company, _id, getGroup,
}) => {
  const [refreshCurrentGroup, setRefreshSCurrentGroup] = useState(false);

  useEffect(() => {
    for (let i = 0; i < data.length; i += 1) {
      if (semester === data[i].semester) {
        setCurrentGroup(i);
        Cookies.set('currentGroup', i, { expires: null }, { secure: true }, { sameSite: 'strict' });
      }

    }
  }, [refreshCurrentGroup, data]);

  useEffect(async () => {
    let found = false;
    data.forEach((group) => {
      if (group.semester === semester) {
        found = true;
      }
    });
    async function getTest() {
      try {
        const test = await placementTestServices.getTest(_id);
        if (test && test.scores) {
          return test.scores.total;
        }
        return 1;
      } catch (e) {
        // console.log(e);
        return 1;
      }
    }
    if (!found) {
      let level;
      let score;
      if (toulouseSchools.includes(company._id)) {
        level = 1;
      } else {
        score = await getTest();
        level = convertCEFRToElearningLevel(numGradeToEUgrade(score));
      }
      try {
        await placementTestServices.postStudentToGroup(company._id, level, _id, semester);
        await getGroup(_id);
        setCurrentGroup(0);
        Cookies.set('currentGroup', 0, { expires: null }, { secure: true }, { sameSite: 'strict' });
        window.location.reload(true);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
    setRefreshSCurrentGroup(!refreshCurrentGroup);
  }, []);

  return (
    <Menu
      anchorEl={anchorEl}
      id={id}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
    >
      { data.map((item, i) => (
        <MenuItem
          onClick={(e) => {
            setCurrentGroup(e.target.value);
            Cookies.set('currentGroup', e.target.value, { expires: null }, { secure: true }, { sameSite: 'strict' });
            handleMenuClose();
          }}
          value={i}
          key={item._id}
        >
          {item.name}
        </MenuItem>
      ))}
    </Menu>
  );
};

SemesterSwitcher.defaultProps = {
  anchorEl: null,
};

SemesterSwitcher.propTypes = {
  anchorEl: PropTypes.any,
  data: PropTypes.array.isRequired,
  setCurrentGroup: PropTypes.func.isRequired,
  handleMenuClose: PropTypes.func.isRequired,
  getGroup: PropTypes.func.isRequired,
  company: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  _id: PropTypes.string.isRequired,
  semester: PropTypes.any.isRequired,
};

function mapState(state) {
  const { data } = state.group;
  const { currentGroup } = state.ui;
  const { semester, company, _id } = state.user.userData;
  data.sort((a, b) => a.semester < b.semester);
  return {
    data, _currentGroup: currentGroup, semester, company, _id,
  };
}

const actionCreator = {
  setCurrentGroup: uiActions.setCurrentGroup,
  getGroup: groupActions.getGroup,
};

export default connect(mapState, actionCreator)(SemesterSwitcher);
