/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Typography, Dialog, Slide, CardActionArea, TextField,
} from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import config from '../../../../../config';
import examType1Actions from '../../../../../redux/actions/examType1Actions';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    maxWidth: '900px',
    alignSelf: 'center',
  },
  container: {
    marginTop: '100px',
    width: '100%',
    height: '100%',
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: '900px',
    gap: '30px',
  },
  getSubjectButton: {
    display: 'flex',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    width: '270px',
    padding: 8,
  },
  textareaCard: {
    width: '100%',
    display: 'flex',
    height: '55%',
    flexDirection: 'column',
  },
  answerInfos: {
    maxWidth: '200px',
    padding: '8px',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    paddingLeft: '20px',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  textFieldContainer: {
    width: '100%',
    minWidth: 250,
    padding: '6px',
    borderRadius: 5,
    borderTopLeftRadius: 0,
    borderStyle: 'solid',
    backgroundColor: '#fff',
    borderColor: theme.palette.primary.main,
    borderWidth: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  textField: {
    width: '100%',
    minWidth: 250,
    color: '#115',
    borderWidth: 1,
    backgroundColor: 'white',
  },
  sendButton: {
    display: 'flex',
    marginTop: '6px',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    width: '5%',
    minWidth: 100,
    paddingTop: 6,
    paddingBottom: 6,
  },
  modal: {
    padding: '20px',
    textAlign: 'center',
  },
  step: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
  },
  number: {
    fontSize: '12px',
    padding: '7px 13px',
    marginRight: '30px',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '100%',
    color: theme.palette.primary.main,
  },
}));

const ExamType2 = ({ data, setExamBeginTime }) => {
  const classes = useStyles();

  const handleChange = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (!localStorage.getItem('examBeginTime')) {
      setExamBeginTime();
    }
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.step}>
          <Typography className={classes.number}>1</Typography>
          <CardActionArea
            component="a"
            href={`${config.OBJECT_URL}/exams/${data.examFile}`}
            target="_blank"
            className={classes.getSubjectButton}
          >
            <Typography variant="button">
              TELECHARGER LE SUJET D’EXAMEN
            </Typography>
          </CardActionArea>
        </div>
        <div className={classes.step}>
          <Typography className={classes.number}>2</Typography>
          <div className={classes.textareaCard}>
            <Typography className={classes.answerInfos} variant="button">
              {`${data.hiddenResults ? 'REPONSE ENVOYEE :' : 'REDACTION :'}`}
            </Typography>
            <div className={classes.textFieldContainer}>
              <TextField
                required
                multiline
                className={classes.textField}
                disabled={data.hiddenResults}
                variant="outlined"
                fullWidth
                size="small"
                type="number"
                rowsMax={(window.innerHeight - 400) / 30}
                rows={8}
                value={data.answer}
                onChange={data.handleAnswer}
                placeholder="Ecrivez votre reponse ici.."
                helperText={data.hiddenResults ? `Sauvegardé à ${data.answerDate}` : null}
                onCut={handleChange}
                onCopy={handleChange}
                onPaste={handleChange}
              />
              {
            data.hiddenResults
              ? null
              : (
                <CardActionArea
                  onClick={() => data.handleApprovalAnswer()}
                  disabled={data.hiddenResults}
                  className={classes.sendButton}
                >
                  <Typography variant="button">
                    ENVOYER
                  </Typography>
                </CardActionArea>
              )
          }
              <Dialog
                className={classes.modal}
                open={data.modalApproval}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => data.setModalApproval(false)}
              >
                <DialogContent>
                  <DialogContentText>
                    {data.modalMessage}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => {
                    data.setApproval(false);
                    data.setModalApproval(false);
                  }}
                  >
                    Non
                  </Button>
                  <Button onClick={() => {
                    data.setApproval(true);
                    data.setModalApproval(false);
                  }}
                  >
                    Oui
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                className={classes.modal}
                open={data.alertAnswer}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => data.setAlertAnswer(false)}
              >
                <DialogContent>
                  <DialogContentText>
                    {data.modalMessage}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => data.setAlertAnswer(false)}>Ok</Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ExamType2.propTypes = {
  data: PropTypes.object,
  examFile: PropTypes.string,
  hiddenResults: PropTypes.bool,
  answer: PropTypes.string,
  handleAnswer: PropTypes.func,
  answerDate: PropTypes.instanceOf(Date),
  handleApprovalAnswer: PropTypes.func,
  modalApproval: PropTypes.bool,
  setModalApproval: PropTypes.bool,
  modalMessage: PropTypes.string,
  setApproval: PropTypes.bool,
  alertAnswer: PropTypes.bool,
  setAlertAnswer: PropTypes.bool,
  setExamBeginTime: PropTypes.func.isRequired,
};

ExamType2.defaultProps = {
  data: '',
  examFile: '',
  hiddenResults: '',
  answer: '',
  handleAnswer: '',
  answerDate: '',
  handleApprovalAnswer: '',
  modalApproval: '',
  setModalApproval: '',
  modalMessage: '',
  setApproval: '',
  alertAnswer: '',
  setAlertAnswer: '',
};
const actionCreators = {
  setExamBeginTime: examType1Actions.setExamBeginTime,
};

function mapState(state) {
  return {};
}
export default connect(mapState, actionCreators)(ExamType2);
