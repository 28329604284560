/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button, Typography, Grid,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import './index.css';
import '../../../../../../../fonts/arial.ttf';
import examType1Actions from '../../../../../../../redux/actions/examType1Actions';
import config from '../../../../../../../config';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '90vh',
    marginTop: theme.spacing(1),
  },
  mainTitle: {
    marginTop: '1em',
    textAlign: 'center',
  },
  image: {
    width: '35%',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  textCard: {
    margin: '2em',
    padding: '2em',
  },
  titleword1: {
    color: theme.palette.primary.main,
  },
  titleword2: {
    color: theme.palette.secondary.main,
  },
  paragraph: {
    marginBottom: '20px',
    fontSize: 19,
    fontFamily: 'Arial',
    lineHeight: 1.5,
  },
  bold: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    padding: '0px 5px',
  },
}));

const getFormatedDate = (time) => `${time >= 60 ? `${Math.floor(time / 60)}h ` : ''} ${time % 60 > 0 ? `${Math.round(time % 60)}min` : ''}`;

const LandingExam = ({
  initTest,
  testId,
  _id,
  requestingExam,
  activityId,
  additionalTime,
  setExamBeginTime,
  exam,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const examTime = useState(getFormatedDate(90 + parseInt(additionalTime, 10)));

  const handleInitTest = () => {
    setExamBeginTime();
    initTest(activityId, {
      user: _id,
      type: 1,
      testId,
      timer: exam.duration,
    });
    // const res = checkExamBeginTime(additionalTime, parseInt(localStorage.getItem('timeLeft'), 10) || 90);
    // const minutes = Math.round(res / 1000 / 60);
    // localStorage.setItem('timeLeft', JSON.stringify(minutes));
  };

  return (
    <Grid container direction="row" justifyContent="center">
      <Grid item md={12}>
        <Typography className={classes.mainTitle} variant="h4">
          Bienvenue sur
          <span className={classes.titleword1}> Medical </span>
          <span className={classes.titleword2}>World </span>
        </Typography>
      </Grid>
      {!xs && (
      <Grid item md={12}>
        <img className={classes.image} alt="placement test illustration" src={`${config.OBJECT_URL}/thumbnail/pt-landing.svg`} />
      </Grid>
      )}
      <Grid item md={12} className={classes.textCard}>
        <Typography variant="body1" className={classes.paragraph}>
          Bonjour,
        </Typography>
        <Typography variant="body1" className={classes.paragraph}>
          Votre épreuve du semestre 3 est un quizz en ligne.
          Il comporte des questions de vocabulaire et la compréhension d’un article médical.
          Il durera
          {' '}
          {examTime}
          . La barre de progression au-dessus indique votre avancement dans le quizz.
        </Typography>
        <Typography variant="body1" className={classes.paragraph}>
          AVANT L’EPREUVE :
        </Typography>
        <ul>
          <Typography component="li" style={{ fontSize: 19, lineHeight: 1.5 }} variant="body1">Désactiver tout &quot;Plug in&quot; de traduction (ex : Google translate) afin de garantir le bon fonctionnement de l’épreuve.</Typography>
          <Typography component="li" style={{ fontSize: 19, lineHeight: 1.5 }} variant="body1">Vérifier que votre ordinateur est à l’heure.</Typography>
        </ul>
        <Typography variant="body1" className={classes.paragraph}>
          IMPORTANT :
        </Typography>
        <Typography variant="body1" className={classes.paragraph}>
          Dès que l’examen démarre, vous devez répondre à chaque question avant de cliquer sur SUBMIT.
          {' '}
          <b>Attention, vous ne pouvez pas revenir sur les pages précédentes.</b>
          {' '}
          A la fin de l’examen, ne pas oublier de
          {' '}
          <b className={classes.bold}>cliquer pour envoyer vos résultats</b>
          {' '}
          avant de vous déconnecter.
          En cas de difficultés, vous pouvez contacter l’assistance au 07 67 50 74 15.
        </Typography>
      </Grid>
      <Button
        color="primary"
        variant="contained"
        disabled={requestingExam}
        style={{ marginBottom: '50px', fontSize: 19 }}
        onClick={() => handleInitTest()}
      >
        {requestingExam ? 'Chargement en cours' : 'Commencer votre examen'}
      </Button>
    </Grid>
  );
};

LandingExam.propTypes = {
  initTest: PropTypes.func.isRequired,
  setExamBeginTime: PropTypes.func.isRequired,
  testId: PropTypes.string,
  requestingExam: PropTypes.bool.isRequired,
  activityId: PropTypes.string.isRequired,
  additionalTime: PropTypes.number.isRequired,
  exam: PropTypes.object.isRequired,
  _id: PropTypes.string.isRequired,
};

LandingExam.defaultProps = {
  testId: null,
};

const actionCreators = {
  initTest: examType1Actions.initTest,
  setExamBeginTime: examType1Actions.setExamBeginTime,
};

function mapState(state) {
  const { authData } = state.auth;
  const { additionalTime } = state.user.userData;
  const {
    requestingExam,
  } = state.exam;
  const { _id, testId } = authData;
  return {
    _id, requestingExam, testId, additionalTime,
  };
}
export default connect(mapState, actionCreators)(LandingExam);
