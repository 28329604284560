import glossaryConstants from '../constants/glossaryConstants';
import glossaryServices from '../services/glossaryServices';

function getGlossary() {
  function request() {
    return {
      type: glossaryConstants.GLOSSARY_REQUEST,
    };
  }

  function failure(error) {
    return {
      type: glossaryConstants.GLOSSARY_FAILURE,
      error,
    };
  }

  function success(glossary) {
    return {
      type: glossaryConstants.GLOSSARY_SUCCESS,
      glossary,
    };
  }

  return (dispatch) => {
    dispatch(request());
    glossaryServices.getGlossary()
      .then(
        (glossary) => {
          dispatch(success(glossary));
        },
        (error) => {
          dispatch(failure(error.toString()));
        },
      );
  };
}

function addNewWord() {
  function request() {
    return {
      type: glossaryConstants.ADD_GLOSSARY_REQUEST,
    };
  }

  function failure(error) {
    return {
      type: glossaryConstants.ADD_GLOSSARY_FAILURE,
      error,
    };
  }

  function success(glossary) {
    return {
      type: glossaryConstants.ADD_GLOSSARY_SUCCESS,
      glossary,
    };
  }

  return (dispatch) => {
    dispatch(request());
    glossaryServices.addGlossaryWord()
      .then(
        (glossary) => {
          dispatch(success(glossary));
        },
        (error) => {
          dispatch(failure(error.toString()));
        },
      );
  };
}

const glossaryActions = {
  getGlossary,
  addNewWord,
};

export default glossaryActions;
