/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const messageArray = ['Hi, ', 'Hello, ', 'Welcome, ', 'Nice to see you, ', 'Let\'s learn Medical English, '];

const WelcomeTitle = ({ name }) => {
  const [sentence, setSentence] = useState('');

  useEffect(() => {
    const today = new Date();
    const curHr = today.getHours();
    const randValue = Math.round(Math.random() * (messageArray.length));

    if (today.getMonth() === 0 && today.getDate() < 15) {
      setSentence('Happy new year, ');
    } else if (today.getMonth() === 11 && today.getDate() >= 24) {
      setSentence('Merry Christmas, ');
    } else if (randValue === messageArray.length) {
      if (curHr < 12) {
        setSentence('Good morning, ');
      } else if (curHr < 18) {
        setSentence('Good afternoon, ');
      } else {
        setSentence('Good evening, ');
      }
    } else {
      setSentence(messageArray[randValue]);
    }
  }, []);

  return (
    <Typography variant="h4">{`${sentence}${name} !`}</Typography>
  );
};

WelcomeTitle.propTypes = {
  name: PropTypes.string.isRequired,
};

export default WelcomeTitle;
