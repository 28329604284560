import {
  apiClient,
  handleResponse,
} from './axios';

async function initTest(id, body) {
  try {
    const response = await apiClient.post(`/examtype1/init/${id}`, body);
    const test = await handleResponse(response);
    return test;
  } catch (error) {
    const res = await Promise.reject(error.response.data.message);
    return res;
  }
}

async function sendTest(testId, body) {
  try {
    const response = await apiClient.put(`/examtype1/${testId}`, body);
    const res = await handleResponse(response);
    return res;
  } catch (error) {
    const res = await Promise.reject(error.response.data.message);
    return res;
  }
}

async function getTest(userId, poolId) {
  try {
    const response = await apiClient.get(`/examtype1/user/${userId}/${poolId}`);
    const res = await handleResponse(response);
    return res;
  } catch (error) {
    const res = await Promise.reject(error.response.data.message);
    return res;
  }
}

async function getTestById(testId) {
  try {
    const response = await apiClient.get(`/examtype1/${testId}`);
    const res = await handleResponse(response);
    return res;
  } catch (error) {
    const res = await Promise.reject(error.response.data.message);
    return res;
  }
}

async function getQuestionPool(id) {
  try {
    const response = await apiClient.get(`/qpool/questions/${id}`);
    const res = await handleResponse(response);
    return res;
  } catch (error) {
    const res = await Promise.reject(error.response.data.message);
    return res;
  }
}

async function postStudentToGroup(companyId, level, userId, semester) {
  const body = {
    level: `${level}`,
    user: userId,
    semester: `${semester}`,
  };
  try {
    const response = await apiClient.post(`/group/associate/${companyId}`, body);
    const res = await handleResponse(response);
    return res;
  } catch (error) {
    const res = await Promise.reject(error.response.data.message);
    return res;
  }
}

async function isExamFinished(body) {
  try {
    const response = await apiClient.post('/examtype1/exam', body);
    const res = await handleResponse(response);
    return res;
  } catch (error) {
    const res = await Promise.reject(error.response.data.message);
    return res;
  }
}

async function updateExamTimer(testId, body) {
  try {
    const response = await apiClient.put(`/examtype1/data/${testId}`, body);
    const res = await handleResponse(response);
    return res;
  } catch (error) {
    const res = await Promise.reject(error.response.data.message);
    return res;
  }
}

const examType1Services = {
  initTest,
  sendTest,
  getTest,
  getTestById,
  postStudentToGroup,
  isExamFinished,
  getQuestionPool,
  updateExamTimer,
};

export default examType1Services;
