/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button, Select, TextField, Grid, Container, FormControl,
  InputLabel, OutlinedInput, InputAdornment, IconButton, MenuItem,
  FormHelperText, FormControlLabel, Checkbox,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import userActions from '../../../redux/actions/userActions';
import userServices from '../../../redux/services/userServices';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    '&:hover': {
      backgroundColor: '#ddd',
    },
  },
}));

const MWForm = ({
  handleBack, createUser, creating, company, codeId, needsAnalysis, semesters, placementtest,
}) => {
  const classes = useStyles();
  const [errorText, setErrorText] = useState('');
  const [error, setError] = useState(false);
  const [needsSelect, setNeedsSelect] = useState([]);
  const { innerWidth: width } = window;
  const isSmallScreen = width < 450;
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    password: '',
    password2: '',
    phone: '',
    jobTitle: '',
    needs: [],
    email: '',
    email2: '',
    consent: false,
    semester: 0,
  });

  const handleChange = (prop) => (event) => {
    if (prop === 'consent') {
      setValues({ ...values, [prop]: event.target.checked });
    } else if (prop === 'email' || prop === 'email2') {
      setValues({ ...values, [prop]: event.target.value.trim() });
    } else if (prop === 'needs') {
      setNeedsSelect(event.target.value);
      let _needs = {};
      event.target.value.forEach((value) => {
        _needs = { ..._needs, [value]: true };
      });
      setValues({ ...values, [prop]: _needs });
    } else if (prop === 'firstName' || prop === 'lastName') {
      setValues({ ...values, [prop]: event.target.value.trim() });
    } else {
      setValues({ ...values, [prop]: event.target.value });
    }
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleClickShowPassword2 = () => {
    setValues({ ...values, showPassword2: !values.showPassword2 });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const isValidPassword = (password) => {
    // Minimum eight characters, at least one letter and one number
    const regex = new RegExp('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$');
    return regex.test(password);
  };

  const isValidMail = (email) => {
    // Minimum eight characters, at least one letter and one number
    const regex = new RegExp(
      '^[0-9a-zA-Z.\\-_]{3,}@[a-zA-Z0-9.-]{3,}\\.(fr|com|org|eu|net|bzh|education)$',
    );
    return regex.test(email);
  };

  const verifyEmail = (e) => {
    if (isValidMail(e.target.value) === false) {
      setError(true);
      setErrorText('Cette adresse email est invalide.');
      return;
    }
    e.preventDefault();
    userServices.verifyEmail(e.target.value)
      .then(() => {
        setError(false);
        setErrorText('');
      })
      .catch(() => {
        setError(true);
        setErrorText('Cette adresse email est déjà utilisée');
      });
  };

  const handleCreateUser = (e) => {
    e.preventDefault();
    createUser({
      email: values.email.trim(),
      password: values.password,
      firstname: values.firstName.trim(),
      name: values.lastName.trim(),
      jobTitle: needsAnalysis ? values.jobTitle.trim() : null,
      phone: needsAnalysis ? values.phone.trim() : null,
      needs: needsAnalysis ? values.needs : null,
      semester: values.semester,
      type: 4,
      company: company._id,
      code: codeId,
      placementtest,
    });
  };
  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                value={values.firstName}
                onChange={handleChange('firstName')}
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="Prénom"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                value={values.lastName}
                onChange={handleChange('lastName')}
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Nom"
                name="lastName"
                autoComplete="lname"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={values.email}
                onChange={handleChange('email')}
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Adresse email"
                name="email"
                autoComplete="email"
                error={error || values.email.includes('@prefmschutoulouse.onmicrosoft.com')}
                helperText={(error && errorText) || (values.email.includes('@prefmschutoulouse.onmicrosoft.com') && 'Adresse mail en @prefmschutoulouse.onmicrosoft.com bloquée par votre établissement')}
                onBlur={(e) => verifyEmail(e)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={values.email2 && (values.email !== values.email2)}
                value={values.email2}
                onChange={handleChange('email2')}
                variant="outlined"
                required
                fullWidth
                id="email2"
                label="Confirmer adresse email"
                name="email2"
                autoComplete="email"
                helperText={values.email2 && (values.email !== values.email2) && 'La confirmation de votre adresse email ne correspond pas.'}
              />
            </Grid>
            {
              needsAnalysis
                ? (
                  <>
                    <Grid item xs={12}>
                      <TextField
                        value={values.phone}
                        onChange={handleChange('phone')}
                        variant="outlined"
                        required
                        fullWidth
                        id="phone"
                        label="Téléphone"
                        name="phone"
                        autoComplete="phone"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        value={values.jobTitle}
                        onChange={handleChange('jobTitle')}
                        variant="outlined"
                        required
                        fullWidth
                        id="jobTitle"
                        label="Profession"
                        name="jobTitle"
                        autoComplete="jobTitle"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl variant="outlined" style={{ width: '100%' }}>
                        <InputLabel id="demo-simple-select-label">Besoins</InputLabel>
                        <Select
                          labelId="needs-select"
                          label="Besoins"
                          id="needs"
                          value={needsSelect}
                          onChange={handleChange('needs')}
                          multiple
                        >
                          <MenuItem value="art">Art</MenuItem>
                          <MenuItem value="economy">Économie</MenuItem>
                          <MenuItem value="education">Éducation</MenuItem>
                          <MenuItem value="emails">Emails</MenuItem>
                          <MenuItem value="environment">Environnement</MenuItem>
                          <MenuItem value="finance">Finance</MenuItem>
                          <MenuItem value="it">Informatique</MenuItem>
                          <MenuItem value="engineering">Ingénierie</MenuItem>
                          <MenuItem value="legal">Legal</MenuItem>
                          <MenuItem value="logistics">Logistique</MenuItem>
                          <MenuItem value="marketing">Marketing</MenuItem>
                          <MenuItem value="negotiations">Négociations</MenuItem>
                          <MenuItem value="news">News</MenuItem>
                          <MenuItem value="politics">Politique</MenuItem>
                          <MenuItem value="presentations">Présentations</MenuItem>
                          <MenuItem value="production">Production</MenuItem>
                          <MenuItem value="quality">Qualité</MenuItem>
                          <MenuItem value="meetings">Rendez-vous</MenuItem>
                          <MenuItem value="hr">Ressources humaines</MenuItem>
                          <MenuItem value="sport">Sport</MenuItem>
                          <MenuItem value="telephoning">Téléphonie</MenuItem>
                          <MenuItem value="sales">Vente</MenuItem>
                          <MenuItem value="travelling">Voyage</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                )
                : (
                  <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        labelId="semester-select"
                        id="semester"
                        value={values.semester}
                        onChange={handleChange('semester')}
                      >
                        {
                          semesters.map((semester) => {
                            if (semester === 0) {
                              return <MenuItem value={semester}>{`Sélectionnez un ${company.isCompany && company.isCompany === true ? 'parcours' : 'semestre'}`}</MenuItem>;
                            }
                            return <MenuItem value={semester}>{`${company.isCompany && company.isCompany === true ? 'Parcours' : 'Semestre'} ${semester}`}</MenuItem>;
                          })
                        }
                      </Select>
                    </FormControl>
                  </Grid>
                )
            }
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                fullWidth
              >
                <InputLabel htmlFor="password">Mot de passe</InputLabel>
                <OutlinedInput
                  id="password"
                  error={values.password && !isValidPassword(values.password)}
                  type={values.showPassword ? 'text' : 'password'}
                  value={values.password}
                  onChange={handleChange('password')}
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )}
                  labelWidth={100}
                />
                {values.password && !isValidPassword(values.password) && (
                  <FormHelperText error id="password-error">
                    Merci de créer un mot de passe avec au moins 8 caractères,
                    une majuscule et un chiffre.
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                fullWidth
              >
                {!isSmallScreen ? (
                  <InputLabel htmlFor="password">Confirmer mot de passe</InputLabel>
                ) : (
                  <InputLabel htmlFor="password">Confirmer</InputLabel>
                )}
                <OutlinedInput
                  id="password2"
                  error={values.password2 && !(values.password === values.password2)}
                  type={values.showPassword2 ? 'text' : 'password'}
                  value={values.password2}
                  onChange={handleChange('password2')}
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password confirmation visibility"
                        onClick={handleClickShowPassword2}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword2 ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )}
                  labelWidth={!isSmallScreen ? 170 : 75}
                />
                {(values.password2 && !(values.password === values.password2)) && (
                  <FormHelperText error id="email-match-error">
                    La confirmation de votre mot de passe ne correspond pas.
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox checked={values.consent} color="primary" onChange={handleChange('consent')} name="consent" />}
                label="En soumettant mes informations, j'accepte qu'elles soient utilisées dans le cadre de l'organisation de ma formation. Les données relatives à mon niveau et à mes tests d'évaluation peuvent être partagées avec mon employeur."
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <Button
                onClick={() => handleBack()}
                fullWidth
                className={classes.submit}
                variant="contained"
                style={{ backgroundColor: '#eee', hover: { backgroundColor: '#ddd' } }}
              >
                retour
              </Button>
            </Grid>
            <Grid item md={9} xs={12}>
              <Button
                onClick={(e) => handleCreateUser(e)}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={
                  error
                  || !values.firstName
                  || !values.lastName
                  || (!needsAnalysis && !values.semester)
                  || !isValidPassword(values.password)
                  || !(values.password === values.password2)
                  || values.email !== values.email2
                  || !values.consent
                  || values.email.includes('@prefmschutoulouse.onmicrosoft.com')
                  || (needsAnalysis && (!values.jobTitle || !values.phone))
                }
                className={classes.submit}
              >
                {
                  creating ? 'Chargement...' : 'Créer un compte'
                }
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

MWForm.propTypes = {
  handleBack: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  creating: PropTypes.bool.isRequired,
  needsAnalysis: PropTypes.bool.isRequired,
  company: PropTypes.object.isRequired,
  codeId: PropTypes.string.isRequired,
  semesters: PropTypes.array.isRequired,
  placementtest: PropTypes.bool.isRequired,
};

function mapState(state) {
  const { creating } = state.auth;
  return { creating };
}

const actionCreators = {
  createUser: userActions.createUser,
};

export default connect(mapState, actionCreators)(MWForm);
