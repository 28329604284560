/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-param-reassign */
import Loader from 'react-loader-spinner';

function addMinutes(date, minutes) {
  return new Date(date.getTime() + minutes * 60000);
}

const sec2time = (timeInSeconds) => {
  const pad = (num, size) => (`000${num}`).slice(size * -1);
  const time = parseFloat(timeInSeconds).toFixed(3);
  const hours = Math.floor(time / 60 / 60);
  const minutes = Math.floor(time / 60) % 60;

  return `${pad(hours, hours > 99 ? 3 : 2)}h${pad(minutes, 2)}`;
};

const sec2int = (timeInSeconds) => {
  const time = parseFloat(timeInSeconds).toFixed(3);
  const hours = Math.floor(time / 60 / 60);
  const minutes = Math.floor(time / 60) % 60;

  return (hours * 60) + minutes;
};

const addUnitTimers = (trackers) => {
  let totalTime = 0;

  trackers.forEach((tracker) => {
    totalTime += tracker.time;
  });
  return totalTime;
};

const Loading = () => (
  <div
    key="divProgress"
    style={{
      height: '80vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Loader type="TailSpin" color="#729bda" height="20%" />
  </div>
);

const shuffleArray = (array) => {
  // eslint-disable-next-line no-plusplus
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
};

const cutUrl = (str) => {
  const matched = str.match(/([^/]*\/){3}/);
  if (matched) {
    if (matched[0].endsWith('/')) {
      return matched[0].substring(0, matched[0].length - 1);
    }
    return matched[0];
  }
  return str;
};

function isFloat(n) {
  return Number(n) === n && n % 1 !== 0;
}

const numGradeToEUgrade = (intgrade) => {
  let eugrade = 'A0';

  if (intgrade && typeof (intgrade) === 'number') {
    if (intgrade >= 1 && intgrade < 3) eugrade = 'A1';
    else if (intgrade >= 3 && intgrade < 5) eugrade = 'A2';
    else if (intgrade >= 5 && intgrade < 7) eugrade = 'B1';
    else if (intgrade >= 7 && intgrade < 8) eugrade = 'B2';
    else if (intgrade >= 8 && intgrade < 9) eugrade = 'C1';
    else if (intgrade >= 9) eugrade = 'C2';

    if (isFloat(intgrade) && eugrade !== 'A0') {
      const decimal = Math.round((intgrade % 1) * 10);
      if (eugrade === 'B1') {
        const demiDecimal = Math.trunc((decimal / 2));
        eugrade += `.${intgrade < 6 ? demiDecimal.toString() : (5 + demiDecimal).toString()}`;
      } else {
        eugrade += `.${decimal}`;
      }
    }
    if (eugrade === 'B1' && intgrade === 6) {
      eugrade += '.5';
    }
  }

  return (eugrade);
};

const convertCEFRToElearningLevel = (cefr) => {
  const grade = cefr.substring(0, 1);
  const value = parseInt(cefr.substring(1, cefr.length), 10);
  let level;

  if (grade === 'A' || (grade === 'B' && value < 1.5)) {
    level = 1;
  } else {
    level = 2;
  }
  return level;
};

const toulouseSchools = ['6156ef9e97e6980028ad5f4c', '63243da69b171d0025f3a553', '6156ef8b97e6980028ad5e5d', '6156ef9b97e6980028ad5f38', '6156ef8197e6980028ad5e22', '615467a328ea1f0063ea0ac5', '6156ef9397e6980028ad5ea7'];

const formatDateWithYear = (date) => {
  if (date === null || date === undefined) {
    return '/';
  }
  const _date = new Date(date);
  return `${_date.getDate().toString().length > 1 ? _date.getDate() : `0${_date.getDate()}`}/${(_date.getMonth() + 1).toString().length > 1 ? (_date.getMonth() + 1) : `0${_date.getMonth() + 1}`}/${_date.getFullYear()}`;
};

const formatTime = (date) => {
  const _date = new Date(date);
  return `${_date.getHours()}h${_date.getMinutes().toString().length === 1 ? `0${_date.getMinutes()}` : _date.getMinutes()}`;
};

export {
  sec2time,
  addUnitTimers,
  cutUrl,
  Loading,
  shuffleArray,
  numGradeToEUgrade,
  sec2int,
  convertCEFRToElearningLevel,
  addMinutes,
  toulouseSchools,
  formatDateWithYear,
  formatTime,
};
