/* eslint-disable no-unused-expressions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import TotalTimeWidget from './components/TotalTimeWidget';
import TotalProgressWidget from './components/TotalQuizzesProgressWidget';
import uiActions from '../../redux/actions/uiActions';
import userActions from '../../redux/actions/userActions';
import ProfileEditWidget from './components/ProfileEditWidget';
import ProfileTitle from './components/ProfileTitle';
import NoGroupAssociatedCard from './components/NoGroupAssociatedCard';
// import useTimeCounter from '../Elearning/components/content/useTimeCounter';

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  secondHalfContainer: {
    marginTop: '15px',
    marginBottom: '0px',
    marginLeft: '0px',
    marginRight: '-15px',
  },
  thirdContainer: {
    marginTop: '15px',
    marginBottom: '0px',
    marginLeft: '0px',
    marginRight: 'auto',
  },
}));

const Profile = ({
  isTest,
  changePageTitle,
  testFinish,
  data,
  firstname,
  lastname,
  // userId,
  // groupId,
  // logout,
  placementtest,
}) => {
  const classes = useStyles();

  useEffect(() => {
    changePageTitle('Profile');
  }, [testFinish]);

  // const isIdle = useTimeCounter(userId, groupId || null);

  // isIdle && logout();

  return isTest && placementtest === true ? (<Redirect to="/test" />) : (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container style={{ justifyContent: 'space-between' }} spacing={3}>
        <Grid item xs={12} md={12}>
          <ProfileTitle name={firstname} lastname={lastname} />
        </Grid>
        {data.length > 0 ? (
          <>
            <Grid item xs={12} md={6}>
              <ProfileEditWidget />
            </Grid>
            <Grid item xs={12} md={6}>
              <TotalProgressWidget />
            </Grid>
            <Grid container direction="row" className={classes.thirdContainer} spacing={3}>
              <Grid item xs={12}>
                <TotalTimeWidget />
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid item xs={12} md={6}>
            <NoGroupAssociatedCard />
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

Profile.propTypes = {
  changePageTitle: PropTypes.func.isRequired,
  // logout: PropTypes.func.isRequired,
  isTest: PropTypes.bool.isRequired,
  testFinish: PropTypes.bool.isRequired,
  firstname: PropTypes.string.isRequired,
  lastname: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  // userId: PropTypes.string.isRequired,
  // groupId: PropTypes.string.isRequired,
  placementtest: PropTypes.bool.isRequired,
};

function mapState(state) {
  const { authData, isTest } = state.auth;
  const { testFinish } = state.auth.authData;
  const { firstname, name, placementtest } = state.user.userData;
  const { requestingGroup, data } = state.group;
  const { currentGroup } = state.ui;
  const { _id } = authData;
  const lastname = name;
  return {
    isTest, testFinish, placementtest, requestingGroup: (requestingGroup || false), firstname, lastname, data, userId: _id, groupId: data[currentGroup] ? data[currentGroup]._id : null,
  };
}

const actionCreators = {
  changePageTitle: uiActions.changePageTitle,
  logout: userActions.logout,
};

export default connect(mapState, actionCreators)(Profile);
