/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-console */
import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone';
import EqualizerOutlined from '@material-ui/icons/EqualizerOutlined';
import AssignmentTurnedInTwoToneIcon from '@material-ui/icons/AssignmentTurnedInTwoTone';
import LibraryBooksTwoToneIcon from '@material-ui/icons/LibraryBooksTwoTone';
import SchoolTwoToneIcon from '@material-ui/icons/SchoolTwoTone';
import Typography from '@material-ui/core/Typography';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import ContactSupportTwoToneIcon from '@material-ui/icons/ContactSupportTwoTone';
import AccountCircleTwoToneIcon from '@material-ui/icons/AccountCircleTwoTone';
import MenuBookTwoToneIcon from '@material-ui/icons/MenuBookTwoTone';
import OndemandVideoTwoToneIcon from '@material-ui/icons/OndemandVideoTwoTone';
import ExitToAppTwoToneIcon from '@material-ui/icons/ExitToAppTwoTone';
import TranslateIcon from '@material-ui/icons/Translate';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import { CastForEducation } from '@material-ui/icons';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import MenuItems from './MenuItem';
import userActions from '../../../redux/actions/userActions';
import uiActions from '../../../redux/actions/uiActions';
import SemesterSwitcher from './SemesterSwitcher';
import googleStorageServices from '../../../redux/services/googleStorageServices';

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 50,
    position: 'absolute',
    top: 0,
    marginRight: '0.5em',
    paddingTop: '1em',
  },
  drawerHeader: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    ...theme.mixins.toolbar,
  },
  menuTitle: {
    fontSize: '1.25rem',
    fontFamily: 'Roboto',
    marginLeft: '3em',
    fontWeight: 700,
    lineHeight: 1.6,
  },
  drawerContainer: {
    height: '100%',
  },
}));

const Menu = ({
  handleDrawerClose,
  selectedRoute,
  logout,
  darkMode,
  changeDarkMode,
}) => {
  const classes = useStyles();
  const [elearningOpen, setElearningOpen] = useState(false);
  const [anchorSemesterEl, setAnchorSemesterEl] = useState(null);
  const { innerWidth: width } = window;
  const isSmallScreen = width < 450;

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          handleDrawerClose();
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  const handleSemesterSwitchOpen = (event) => {
    setAnchorSemesterEl(event.currentTarget);
  };

  const PMenuClose = () => {
    setAnchorSemesterEl(null);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const SSID = 'semester-switcher';

  return (
    <div ref={wrapperRef} className={classes.drawerContainer}>
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
        <Grid container>
          <Grid item md={12}>
            <img src="/img/logo-whitey_cropped.png" className={classes.logo} alt="mw logo" />
            <Typography variant="h6" className={classes.menuTitle}>
              <span>Medical World</span>
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Divider />
      <List>
        <MenuItems
          name="Dashboard"
          route="/dashboard"
          selectedRoute={selectedRoute}
          icon={<DashboardTwoToneIcon />}
          handleDrawerClose={handleDrawerClose}
        />
        {isSmallScreen
          && (
          <MenuItems
            name="Profile"
            route="/profile"
            selectedRoute={selectedRoute}
            icon={<AccountCircleTwoToneIcon />}
            handleDrawerClose={handleDrawerClose}
          />
          )}
        <MenuItems
          name="Group"
          route="/group"
          selectedRoute={selectedRoute}
          icon={<EqualizerOutlined />}
          handleDrawerClose={handleDrawerClose}
        />
        <ListItem
          onClick={() => setElearningOpen(!elearningOpen)}
          selected={selectedRoute === '/elearning/course' || selectedRoute === '/elearning/resources'}
          button
        >
          <ListItemIcon>
            <MenuBookTwoToneIcon />
          </ListItemIcon>
          <ListItemText primary="E-learning" />
          {elearningOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={elearningOpen} timeout="auto" unmountOnExit>
          <Divider />
          <List component="div" style={{ paddingLeft: '5%' }}>
            <MenuItems
              name="Content"
              route="/elearning/course"
              selectedRoute={selectedRoute}
              icon={<SchoolTwoToneIcon />}
              handleDrawerClose={handleDrawerClose}
            />
            <MenuItems
              name="Resources"
              route="/elearning/resources"
              selectedRoute={selectedRoute}
              icon={<LibraryBooksTwoToneIcon />}
              handleDrawerClose={handleDrawerClose}
            />
          </List>
          <Divider />
        </Collapse>
        <MenuItems
          name="Results"
          route="/elearning/results"
          selectedRoute={selectedRoute}
          icon={<EqualizerOutlined />}
          handleDrawerClose={handleDrawerClose}
        />
        <MenuItems
          name="Exams"
          route="/elearning/exam"
          selectedRoute={selectedRoute}
          icon={<AssignmentTurnedInTwoToneIcon />}
          handleDrawerClose={handleDrawerClose}
        />
        <MenuItems
          name="Medflix"
          route="/videos"
          selectedRoute={selectedRoute}
          icon={<OndemandVideoTwoToneIcon />}
          handleDrawerClose={handleDrawerClose}
        />
        <MenuItems
          name="Webinaires"
          route="/webinaire"
          selectedRoute={selectedRoute}
          icon={<CastForEducation />}
          handleDrawerClose={handleDrawerClose}
        />
        <MenuItems
          name="Glossary"
          route="/glossary"
          selectedRoute={selectedRoute}
          icon={<TranslateIcon />}
          handleDrawerClose={handleDrawerClose}
        />
        <MenuItems
          name="Learning Links"
          route="/learning-links"
          selectedRoute={selectedRoute}
          icon={<InsertLinkIcon />}
          handleDrawerClose={handleDrawerClose}
        />
        <MenuItems
          name="Help"
          route="/help"
          selectedRoute={selectedRoute}
          icon={<ContactSupportTwoToneIcon />}
          handleDrawerClose={handleDrawerClose}
        />
      </List>
      {isSmallScreen
        && (
          <div style={{ width: '100%' }}>
            <FormControlLabel
              value="start"
              style={{ marginLeft: '2%', marginTop: '3%' }}
              control={<Switch onClick={() => changeDarkMode(!darkMode)} checked={darkMode} color="primary" />}
              label={<Typography style={{ marginLeft: '6px' }}>Mode sombre</Typography>}
              labelPlacement="end"
            />
          </div>
        )}
      {isSmallScreen
        && (
        <>
          <MenuItem onClick={handleSemesterSwitchOpen} style={{ marginTop: '3%' }}>
            <ExpandLess style={{ marginRight: '13%', color: 'grey' }} />
            Changer de semestre
          </MenuItem>
          <SemesterSwitcher handleMenuClose={PMenuClose} anchorEl={anchorSemesterEl} id={SSID} />
        </>
        )}
      {isSmallScreen
        && (
        <MenuItem onClick={() => logout()} style={{ marginTop: '3%' }}>
          <ExitToAppTwoToneIcon style={{ marginRight: '13%', color: 'grey' }} />
          Se déconnecter
        </MenuItem>
        )}
    </div>
  );
};

Menu.propTypes = {
  handleDrawerClose: PropTypes.func.isRequired,
  selectedRoute: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired,
  changeDarkMode: PropTypes.func.isRequired,
  darkMode: PropTypes.bool.isRequired,
};

function mapState(state) {
  const {
    currentPage, darkMode, dictionnary,
  } = state.ui;
  return {
    currentPage,
    darkMode,
    dictionnary,
  };
}

const actionCreator = {
  logout: userActions.logout,
  changeDarkMode: uiActions.changeDarkMode,
  changeDictionnary: uiActions.changeDictionnary,
};

export default connect(mapState, actionCreator)(Menu);
