/* eslint-disable no-console */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
  Card, CardActionArea, Typography, CardMedia,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import config from '../../../config';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
  },
  title: {
    padding: '1em',
  },
  center: {
    textAlign: 'center',
    margin: 'auto',
    width: '50%',
  },
  imgCard: {
    height: '80%',
  },
  cardAction: {
    paddingBottom: 20,
    width: '100%',
    height: '100%',
  },
  imgCardStart: {
    height: '20vh',
  },
});

const ResourcesWidget = () => {
  const classes = useStyles();

  return (
    <Card style={{ height: '100%' }}>
      <CardActionArea
        className={classes.cardAction}
        component={Link}
        to="/elearning/resources"
      >
        <CardMedia
          image={`${config.OBJECT_URL}/thumbnail/objectives.svg`}
          title="start learning thumbnail"
          component={Link}
          to="/elearning/resources"
          className={classes.imgCard}
        />
        <Typography
          variant="body1"
          style={{ fontWeight: '900', margin: '2em', textAlign: 'center' }}
        >
          Resources
        </Typography>
      </CardActionArea>
    </Card>
  );
};

export default connect(null, {})(ResourcesWidget);
