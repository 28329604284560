import {
  apiClient,
  handleResponse,
} from './axios';

const getGuides = () => apiClient.get('/guide')
  .then(handleResponse);

const guideServices = {
  getGuides,
};

export default guideServices;
