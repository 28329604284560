/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, Container, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import uiActions from '../../redux/actions/uiActions';
import TestBody from './components/TestBody';
import TestFinished from './components/TestFinished';
import Tutorial from './components/Tutorial';
import Landing from './components/Landing';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    overflowY: 'auto',
    marginTop: theme.spacing(1),
    position: 'relative',
    marginBottom: '80px',
  },
  progressBar: {
    width: '100%',
    height: '5px',
  },
}));

const PlacementTest = ({
  changePageTitle, test, isTest, currentQuestion, answers, authData,
}) => {
  const classes = useStyles();
  const [tutorial, setTutorial] = useState(false);
  const [scores, setScores] = useState(null);

  useEffect(() => {
    changePageTitle('Placement test');
  }, [isTest]);

  return (!isTest && isTest !== undefined) || authData.placementtest === false ? (
    <Redirect to="/dashboard" />
  ) : (
    <>
      <Container>
        {test && currentQuestion === test.questions.length && !tutorial
          ? (
            <TestFinished
              testId={test.testId}
              setTutorial={setTutorial}
              answers={answers}
              scores={scores}
              setScores={setScores}
            />
          )
          : (
            <Card variant="outlined" className={classes.root}>
              {test ? (
                (currentQuestion === test.questions.length) ? (
                  tutorial
                    ? (
                      <Tutorial
                        scores={scores}
                      />
                    )
                    : null
                ) : (
                  <>
                    <TestBody
                      descriptionRC={test.descriptionRC}
                      descriptionOC={test.descriptionOC}
                      descriptionKN={test.descriptionKN}
                      testData={test.questions}
                    />
                    <LinearProgress className={classes.progressBar} variant="determinate" value={test ? ((currentQuestion / test.questions.length) * 100) : 0} />
                  </>
                )
              ) : (
                <Landing />
              )}
            </Card>
          )}
      </Container>
    </>
  );
};

PlacementTest.propTypes = {
  changePageTitle: PropTypes.func.isRequired,
  test: PropTypes.object.isRequired,
  isTest: PropTypes.bool.isRequired,
  currentQuestion: PropTypes.number.isRequired,
  answers: PropTypes.array.isRequired,
};

const actionCreators = {
  changePageTitle: uiActions.changePageTitle,
};

function mapState(state) {
  const { authData, isTest } = state.auth;
  const {
    requestingTest, test, answers, currentQuestion,
  } = state.placementTest;
  const { _id, code, isVerified } = authData;
  return {
    code,
    _id,
    requestingTest,
    test,
    isTest,
    isVerified,
    answers,
    currentQuestion,
    authData,
  };
}
export default connect(mapState, actionCreators)(PlacementTest);
