/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Button, Grid } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import examType1Actions from '../../../../../../../redux/actions/examType1Actions';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(4),
  },
  buttonRoot: {
    padding: '1em 2em',
  },
}));

const TrueOrFalseAnswers = ({
  answers, currentAnswer, setCurrentAnswer, questionId, multiQuestionId,
}) => {
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles();

  return (
    <Grid container direction="row">
      {
        answers.map((answer, index) => (
          <Grid
            item
            xs={6}
            className={classes.buttonRoot}
          >
            <Button
              className={classes.button}
              key={answer.text}
              variant={currentAnswer.length > 0 ? (currentAnswer[multiQuestionId].answerIndex[0] === index ? 'contained' : 'outlined') : 'outlined'}
              color="primary"
              style={{ fontSize: 19, lineHeight: 1.5 }}
              onClick={() => setCurrentAnswer([{ question: questionId, answerIndex: [index], created_at: new Date() }])}
              startIcon={answer.text === 'True' ? <CheckIcon /> : <ClearIcon />}
              fullWidth
            >
              {answer.text}
            </Button>
          </Grid>
        ))
      }
    </Grid>
  );
};

TrueOrFalseAnswers.propTypes = {
  answers: PropTypes.array.isRequired,
  currentAnswer: PropTypes.array.isRequired,
  questionId: PropTypes.string.isRequired,
  setCurrentAnswer: PropTypes.func.isRequired,
  multiQuestionId: PropTypes.number.isRequired,
};

const actionCreators = {
  setCurrentAnswer: examType1Actions.setCurrentAnswer,
};

export default connect(null, actionCreators)(TrueOrFalseAnswers);
