/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable no-trailing-spaces */
/* eslint-disable max-len */
/* eslint-disable no-else-return */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  Grid, IconButton, Snackbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Delete } from '@material-ui/icons';
import config from '../../../../../config';
import companyServices from '../../../../../redux/services/companyServices';
import fileServices from '../../../../../redux/services/fileServices';
import { formatDateWithYear, formatTime, Loading } from '../../../../../helpers/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
  },
  modal: {
    padding: '20px',
    textAlign: 'center',
  },
  container: {
    marginTop: '50px',
    marginBottom: '100px',
    alignItems: 'center',
  },
  fileList: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '10px',
    // border: '1px solid #e5e5e5',
    // marginLeft: '20px',
    marginRight: '20px',
    gap: '10px',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flexStart',
    gap: '30px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    minWidth: '200px',
  },
  customFileUpload: {
    border: '1px solid #ccc',
    backgroundSize: 'cover',
    padding: '30px 30px',
    cursor: 'pointer',
  },
  fileName: {
    padding: '6px',
    paddingLeft: '10px',
  },
  button: {
    padding: '5px 10px',
  },
  alert: {
    alignSelf: 'center',
    marginTop: '200px',
    maxWidth: '400px',
  },
  icon: {
    paddingRight: '5px',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: '30px',
  },
  number: {
    fontSize: '12px',
    padding: '7px 13px',
    marginRight: '30px',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '100%',
    color: theme.palette.primary.main,
  },
  image: {
    width: '70%',
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

function ExamType3({
  group, userId, userName, examFile, getFormatedDate, email,
}) {
  const classes = useStyles();
  const [name, setName] = useState();
  const [file, setFile] = useState();
  const [groupName, setGroupName] = useState();
  const [companyName, setCompanyName] = useState();
  const [fileSent, setFileSent] = useState(false);
  const [errorSnackBar, setErrorSnackBar] = useState(null);
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState(false);
  const [modalMessage, setModalMessage] = useState();
  const [severity, setSeverity] = useState('error');
  const [fileAlreadySent, setFileAlreadySent] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [fileLoading, setFileLoading] = useState(true);

  useEffect(async () => {
    const company = await companyServices.getCompanyByStudent(userId);
    setCompanyName(company.name);
    setGroupName(group.name);
    const data = {
      companyName: company.name,
      groupName: group.name,
    };
    setFileAlreadySent({});
    fileServices.checkFile(data)
      .then((res) => {
        setFileLoading(false);
        if (res.data !== undefined && res.data.length > 0) {
          res.data.forEach((fileInDrive) => {
            if (fileInDrive.name === userName) {
              setFileAlreadySent(fileInDrive);
            }
          });
        }
      })
      .catch(() => {
        setErrorSnackBar({ code: 1901, message: 'Error checking file.', severity: 'warning' });
      });
  }, [group, refresh]);

  useEffect(() => {
    setName(`${userName}`);
  }, [groupName, companyName]);

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (file === undefined) {
      setSeverity('error');
      setModalMessage('Vous devez sélectionner un fichier.');
      setWarning(true);
      setLoading(false);
      return;
    } else if (file.type !== 'application/vnd.oasis.opendocument.text' && file.type !== 'application/pdf' && file.type !== 'application/msword' && file.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      setSeverity('error');
      setModalMessage('Mauvaise extension de fichier.');
      setWarning(true);
      setLoading(false);
      return;
    }
    const data = new FormData();
    data.append('email', email);
    data.append('name', name);
    data.append('groupName', groupName);
    data.append('companyName', companyName);
    data.append('file', file);
    data.append('filename', file.name);

    fileServices.uploadFile(data)
      .then((res) => {
        if (res === 'File uploaded successfully.') {
          setModalMessage('Fichier envoyé avec succès.');
          setSeverity('success');
        } else {
          setSeverity('error');
          setModalMessage('Echec de l\'envoi, votre fichier a déjà été envoyé.');
        }
        setWarning(true);
        setFileSent(true);
        setLoading(false);
        setRefresh(!refresh);
      })
      .catch(() => {
        setErrorSnackBar({ code: 1902, message: 'Error while uploading file.', severity: 'error' });
        setLoading(false);
      });
    // emptying form and state
    setFile();
    e.target.file.value = '';
  };

  const handleDelete = async () => {
    const res = await fileServices.deleteGoogleDriveFile(fileAlreadySent.id);
    if (res.status !== 200) {
      setSeverity('error');
      setModalMessage('La suppression a échoué');
      setWarning(true);
      setRefresh(!refresh);
    } else if (res.status === 200) {
      setModalMessage('Fichier supprimé avec succès.');
      setSeverity('success');
      setWarning(true);
      setRefresh(!refresh);
    }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2} className={classes.container}>
        <Snackbar open={errorSnackBar !== null} onClose={() => { setErrorSnackBar(null); }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <Alert severity={errorSnackBar !== null ? errorSnackBar.severity : 'info'} variant="filled" onClose={() => { setErrorSnackBar(null); }}>
            {`Error ${errorSnackBar?.code}: ${errorSnackBar?.message}`}
          </Alert>
        </Snackbar>
        <Grid item md={6} className={classes.card}>
          <img className={classes.image} alt="placement test illustration" src={`${config.OBJECT_URL}/thumbnail/pt-landing.svg`} />
        </Grid>
        <Grid item md={6}>
          <label htmlFor="subject" className={classes.label}>
            <div className={classes.number}>1</div>
            <Button
              id="subject"
              color="primary"
              variant="contained"
              target="_blank"
              className={classes.subject}
              href={`${config.OBJECT_URL}/exams/${examFile}`}
            >
              REDACTION DE L’ABSTRACT -
              TELECHARGEZ LES INSTRUCTIONS
            </Button>
          </label>
          <form onSubmit={onSubmit} encType="multipart/form-data" className={classes.form}>

            <label htmlFor="file" className={classes.label}>
              <div className={classes.number}>2</div>
              <input
                style={{ display: 'none' }}
                id="file"
                name="file"
                type="file"
                onChange={(e) => { setFile(e.target.files[0]); }}
              />
              <Button
                color="primary"
                component="span"
                aria-label="add"
                disabled={loading}
                variant="contained"
              >
                SELECTIONNEZ VOTRE FICHIER
              </Button>
              {file
                && (
                  <span className={classes.fileName}>
                    {file.name}
                  </span>
                )}
            </label>
            <div className={classes.label}>
              <div className={classes.number}>3</div>
              <Button type="submit" value="Envoyer" variant="outlined" disabled={loading} color="primary">{`${loading ? 'Chargement..' : 'ENVOYEZ VOTRE FICHIER'}`}</Button>
            </div>
          </form>
          {!fileLoading
            ? (
              <>
                {(fileAlreadySent && fileAlreadySent.name && fileAlreadySent.createdTime)
                  ? (
                    <Grid item className={classes.fileList}>
                      <Typography variant="body2" style={{ fontWeight: 'bold' }}>Fichier envoyé: </Typography>
                      <Typography variant="body2">{fileAlreadySent.name}</Typography>
                      <Typography variant="body2">{`${formatDateWithYear(fileAlreadySent.createdTime)} à ${formatTime(fileAlreadySent.createdTime)}`}</Typography>
                      <Tooltip title="Supprimer le fichier">
                        <IconButton color="secondary" onClick={handleDelete}>
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )
                  : (
                    <Grid item className={classes.fileList}>
                      <Typography variant="body2">Pas de fichier envoyé</Typography>
                    </Grid>
                  )}
              </>
            )
            : (
              <Grid item className={classes.fileList}>
                <CircularProgress style={{ width: '25px', height: '25px' }} />
              </Grid>
            )}
          <Snackbar open={warning} onClose={() => { setWarning(false); }} anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
            <Alert severity={severity} variant="filled" onClose={() => { setWarning(false); }}>
              {modalMessage}
            </Alert>
          </Snackbar>
        </Grid>
      </Grid>
     
    </div>
  );
}

ExamType3.propTypes = {
  group: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  examFile: PropTypes.string.isRequired,
};

export default ExamType3;
