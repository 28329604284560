import {
  apiClient,
  handleResponse,
} from './axios';

async function getVideos() {
  const response = await apiClient.get('/video/');
  return handleResponse(response);
}

const videoServices = {
  getVideos,
};

export default videoServices;
