/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import ContentLoader from 'react-content-loader';

const TopicLoader = (props) => (
  <ContentLoader
    viewBox="0 0 1360 900"
    height={900}
    width={1360}
    {...props}
  >
    <rect x="0" y="0" rx="8" ry="8" width="440" height="50" />
    <rect x="460" y="0" rx="8" ry="8" width="440" height="50" />
    <rect x="920" y="0" rx="8" ry="8" width="440" height="50" />
    <rect x="0" y="70" rx="8" ry="8" width="440" height="50" />
    <rect x="460" y="70" rx="8" ry="8" width="440" height="50" />
    <rect x="920" y="70" rx="8" ry="8" width="440" height="50" />
    <rect x="0" y="140" rx="8" ry="8" width="440" height="50" />
    <rect x="460" y="140" rx="8" ry="8" width="440" height="50" />
    <rect x="920" y="140" rx="8" ry="8" width="440" height="50" />
  </ContentLoader>
);

export default TopicLoader;
