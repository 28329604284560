/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Slide, Typography, ListSubheader } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import config from '../../../../config';

const useStyles = makeStyles(() => ({
  root: {
    background: ` 100% / 70% no-repeat url('${config.OBJECT_URL}/thumbnail/objectives.svg')`,
    height: '98%',
  },
  title: {
    margin: '1em 0.5em',
    fontWeight: '500',
    letterSpacing: '5px',
    fontSize: '3em',
  },
  textSummary: {
    margin: '1em 1em',
    fontSize: '1.6em',
    fontWeight: '400',
  },
  objectives: {
    listStyleType: 'none',
    margin: '0 1em',
  },
  objFirstWord: {
    marginTop: '2em',
    fontSize: '1.5em',
    fontWeight: '600',
  },
  objSentence: {
    marginLeft: '1em',
    fontSize: '1.3em',
  },
  objIndex: {
    fontSize: '1.5em',
  },
}));

const getFirstWord = (item) => {
  const splitStr = (item.split(' '));
  const firstWord = splitStr[0];
  const firstWordSpace = firstWord.replace('_', ' ');

  return firstWordSpace;
};

const getSentence = (item) => {
  const splitStr = (item.split(' '));
  const firstWord = splitStr[0].replace(/_/g, ' ');
  const StrWithoutFirstWord = item.substr(firstWord.length);

  return StrWithoutFirstWord;
};

const Objectives = ({ content, title }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h3" className={classes.title}>Objectives</Typography>
      <p className={classes.textSummary}>{title}</p>
      <ul className={classes.objectives}>
        {content.map((item, i) => (
          <Slide
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            direction="left"
            in
            {...{ timeout: 1000 }}
          >
            <li key={item.objectives}>
              <ListSubheader>
                <span className={classes.objIndex}>
                  {' '}
                  {`${content.indexOf(item) + 1}.`}
                  {' '}
                </span>
                <span className={classes.objFirstWord}>
                  {`${getFirstWord(item)}`}
                </span>
              </ListSubheader>
              <p className={classes.objSentence}>
                {getSentence(item)}
              </p>
            </li>
          </Slide>
        ))}
      </ul>
    </div>
  );
};

Objectives.defaultProps = {
  content: [],
  title: '',
};

Objectives.propTypes = {
  content: PropTypes.array,
  title: PropTypes.string,
};

export default Objectives;
