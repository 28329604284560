/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Card,
  Grid,
  CardActionArea,
} from '@material-ui/core';
import { Circle } from 'rc-progress';
import { connect } from 'react-redux';
import config from '../../../config';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
  },
  actionArea: {
    width: '100%',
    height: '100%',
  },
  title: {
    padding: '1em',
    paddingTop: '0px',
    textAlign: 'center',
    fontSize: '20px',
  },
  subtitle: {
    padding: '1em',
    paddingBottom: '0px',
    textAlign: 'center',
    fontSize: '17px',
    fontStyle: 'italic',
    color: '#777',
  },
  center: {
    margin: 'auto',
  },
  circleContainer: {
    padding: '20px',
  },
  fullWidth: {
    width: '100%',
    maxWidth: '100%',
  },
  percentageText: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '100%',
  },
});

const TotalProgressWidget = ({
  data, darkMode,
}) => {
  const classes = useStyles();
  const [goal, setGoal] = useState(null);
  // const [isCompleted, setIsCompleted] = useState(false);
  const [totalProgress, setTotalProgress] = useState(-1);
  const colorPrimary = darkMode ? config.darkColor : config.primaryColor;

  useEffect(() => {
    if (data !== undefined && data.length > 0) {
      let total = 0;
      let quizPercent = 0;
      data[0].forEach((module) => {
        module.units.forEach((unit) => {
          if (unit.unit.isFinalQuiz === true || unit.unit.isRise === true) {
            total += 1;
            if (unit.result.success || unit.result.completion) {
              if (unit.result && unit.result.score) {
                quizPercent += (
                  (unit.result.score.raw ? unit.result.score.raw : 0) / unit.result.score.max
                ) * 100;
              } else if (unit.result.completion && unit.result.success) {
                quizPercent += 100;
              }
            }
          }
        });
      });
      quizPercent /= total;
      setTotalProgress(quizPercent);
      setGoal(data[1].elearningTarget);
    }
  }, [data]);

  return (
    <Card direction="row" className={classes.root}>
      <CardActionArea
        component={Link}
        className={classes.actionArea}
        to={{
          pathname: '/elearning/results',
        }}
      >
        <Grid className={classes.actionArea} container direction="row" alignItems="center" alignContent="center">
          <Grid container item xs={6} md={6} direction="column" alignItems="center" alignContent="center">
            <Grid className={classes.fullWidth} item xs={4} md={4}>
              <Typography variant="body1" className={classes.title}>
                Success rate
              </Typography>
            </Grid>
            <Grid item xs={4} md={4} className={classes.percentageText}>
              { totalProgress > -1
                ? (
                  <Typography variant="h3" className={`${classes.center} ${classes.percentage}`}>
                    {`${Math.trunc(totalProgress)} %`}
                  </Typography>
                )
                : (
                  <Typography variant="h6" className={`${classes.center} ${classes.percentage}`}>
                    Loading..
                  </Typography>
                )}
            </Grid>
            <Grid className={classes.fullWidth} item xs={4} md={4}>
              <Typography variant="body1" className={classes.subtitle}>
                {goal !== null && goal >= 0 ? `Objective : ${goal}%` : 'Loading..'}
              </Typography>
            </Grid>
          </Grid>
          <Grid className={classes.circleContainer} item xs={6} md={6}>
            <Circle
              percent={totalProgress || 0}
              strokeWidth="9"
              trailWidth="9"
              strokeColor={totalProgress >= goal ? '#66DD66' : colorPrimary}
              style={{ width: '100%' }}
            />
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
};

TotalProgressWidget.propTypes = {
  data: PropTypes.array.isRequired,
  darkMode: PropTypes.bool.isRequired,
};

function mapState(state) {
  const { darkMode } = state.ui;
  return {
    darkMode,
  };
}
export default connect(mapState, null)(TotalProgressWidget);
