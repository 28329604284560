/* eslint-disable no-underscore-dangle */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import {
  Paper, Typography, Grid,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import placementTestActions from '../../../redux/actions/placementTestActions';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
    width: '100%',
  },
  cardBody: {
    width: '90%',
    margin: 'auto',
    textAlign: 'center',
    paddingTop: '1em',
  },
  center: {
    textAlign: 'center',
    margin: 'auto',
    width: '90%',
  },
  title: {
    padding: '1em',
    textAlign: 'center',
  },
  subTitle: {
    paddingBottom: '0.5em',
  },
  link: {
    color: theme.palette.primary.main,
  },
}));
const NoGroupAssociatedCard = () => {
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <Grid container direction="row" className={classes.center}>
        <Grid item lg={12}>
          <Typography variant="body1" className={classes.title} style={{ fontWeight: 900 }}>
            Votre contenu n&apos;est pas encore disponible pour le moment.
          </Typography>
        </Grid>
        <Grid item lg={12}>
          <Typography variant="body1" className={classes.subTitle}>
            Patience, vous recevrez votre contenu prochainement.
          </Typography>
          <Typography variant="body1" className={classes.subTitle}>
            Si toutefois vous pensez que vous devriez déjà avoir accès à votre contenu,
            essayez de vous déconnecter et vous reconnecter plus tard.
            Si le contenu n&apos;est toujours pas apparu merci de contacter l&apos;
            <Link push to="/help" className={classes.link}>
              assistance
            </Link>
            .
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

const actionCreators = {
  clearTest: placementTestActions.clearTest,
};

function mapState(state) {
  const { userData } = state.user;
  const { semester, company, _id } = userData;
  const { data } = state.group;
  return {
    semester, company, _id, data,
  };
}
export default connect(mapState, actionCreators)(NoGroupAssociatedCard);
