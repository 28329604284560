/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import {
  Snackbar,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import { checkExamBeginTime } from '../../../../../redux/actions/examType1Actions';

const Warning = ({
  additionalTime, currentExam, sendAnswer, alreadySent, setAlreadySent, examType, answerDate,
}) => {
  const [warning, setWarning] = React.useState({
    state: false,
    alreadySent: false,
  });

  useEffect(() => {
    if (currentExam && answerDate === null) {
      const interval = setInterval(() => {
        const res = checkExamBeginTime(additionalTime, currentExam.duration || 90);
        const minutes = Math.round(res / 1000 / 60);
        if (res && minutes === 4 && warning.alreadySent === false) {
          setWarning({
            state: true,
            alreadySent: true,
          });
        }
        if ((!res || minutes === 0) && alreadySent === false && examType === 2) {
          sendAnswer();
          setAlreadySent(true);
          clearInterval(interval);
        }
      }, 30000);
    }
  }, [currentExam]);

  return (
    <Snackbar open={warning.state} onClose={() => setWarning({ state: false, alreadySent: true })} anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
      <Alert severity="warning" variant="filled" onClose={() => setWarning({ state: false, alreadySent: true })}>
        Attention votre examen prendra fin dans 5 minutes.
      </Alert>
    </Snackbar>
  );
};

Warning.propTypes = {
  additionalTime: PropTypes.number.isRequired,
  currentExam: PropTypes.number.isRequired,
};

const actionCreators = {};

function mapState(state) {
  const { additionalTime } = state.user.userData;
  return {
    additionalTime,
  };
}

export default connect(mapState, actionCreators)(Warning);
