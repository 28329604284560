/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
// import logger from 'redux-logger';
import {
  userReducer,
  authenticationReducer,
  uiReducer,
  groupReducer,
  learningLinksReducer,
  moduleReducer,
  glossaryReducer,
  placementTestReducer,
  examType1Reducer,
} from './reducers/index';

let middlewares;

if (process.env.NODE_ENV === 'development') {
  // To activate redux logs
  middlewares = [thunk];
  // middlewares = [thunk, logger];
} else {
  middlewares = [thunk];
}

const appReducer = combineReducers({
  user: userReducer,
  auth: authenticationReducer,
  ui: uiReducer,
  group: groupReducer,
  learningLinks: learningLinksReducer,
  module: moduleReducer,
  glossary: glossaryReducer,
  placementTest: placementTestReducer,
  exam: examType1Reducer,
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  let newState = state;
  if (action.type === 'USER_LOGGED_OUT') {
    newState = {};
  }

  return appReducer(newState, action);
};

const store = createStore(rootReducer, applyMiddleware(...middlewares));

export default store;
