/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import {
  Typography, Grid, CardActionArea, Card, Snackbar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import funFactServices from '../../../redux/services/funFactServices';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
    width: '100%',
    maxHeight: 50,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardBody: {
    width: '85%',
    margin: 'auto',
    textAlign: 'center',
    height: '90%',
    paddingBottom: '30px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  cardBodyWeb: {
  },
  center: {
    textAlign: 'center',
    margin: 'auto',
    width: '100%',
    height: '100%',
  },
  midContainer: {
  },
  title: {
    padding: '1em',
    textAlign: 'left',
    fontWeight: 'bold',
    maxHeight: 50,
  },
  boldTitle: {
    paddingRight: '1em',
    maxHeight: 50,
    fontWeight: 'bold',
  },
  actionArea: {
    width: '100%',
    height: '100%',
  },
  subTitle: {
    paddingTop: '0.5em',
  },
}));

const FunFactWidget = ({ userId }) => {
  const classes = useStyles();
  const [funFact, setFunFact] = useState('');
  const [funFactNumber, setFunFactNumber] = useState(0);
  const [changer, setChanger] = useState(false);
  const [errorSnackBar, setErrorSnackBar] = useState(null);
  const [loading, setLoading] = useState(false);
  const { innerWidth: width } = window;
  const isSmallScreen = width < 450;

  const loadFunFact = () => {
    setLoading(true);
    const funFacts = JSON.parse(localStorage.getItem('funFacts'));
    if (funFacts && funFacts.response && funFacts.response.length > 0) {
      const index = Math.floor(Math.random() * funFacts.response.length);
      setFunFact(funFacts.response[index].body);
      setFunFactNumber(index);
      setLoading(false);
    } else {
      funFactServices.getFunFacts()
        .then((response) => {
          if (response && response.length > 0) {
            localStorage.setItem('funFacts', JSON.stringify({ response }));
            const index = Math.floor(Math.random() * response.length);
            setFunFact(response[index].body);
            setFunFactNumber(index);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setErrorSnackBar({ code: 1201, message: 'Missing medical facts data.', severity: 'error' });
        });
    }
  };

  useEffect(() => {
    loadFunFact();
  }, [changer]);

  return (
    <Card container className={classes.root}>
      <Snackbar open={errorSnackBar !== null} onClose={() => { setErrorSnackBar(null); }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert severity={errorSnackBar !== null ? errorSnackBar.severity : 'info'} variant="filled" onClose={() => { setErrorSnackBar(null); }}>
          {`Error ${errorSnackBar?.code}: ${errorSnackBar?.message}`}
        </Alert>
      </Snackbar>
      <CardActionArea
        onClick={loadFunFact}
        className={classes.actionArea}
      >
        <Grid container direction="row" className={classes.center}>
          <Grid item md={12} className={classes.cardHeader}>
            <Typography
              variant="body1"
              className={classes.title}
            >
              Medical fact
            </Typography>
            <Typography
              variant="h6"
              className={classes.boldTitle}
            >
              {loading ? '' : `${funFactNumber ? `# ${funFactNumber}` : ''}`}
            </Typography>
          </Grid>
          <Grid container direction="row" className={[classes.cardBody, !isSmallScreen ? classes.cardBodyWeb : null]}>
            { !loading && funFact
              ? (
                <>
                  <Grid className={classes.midContainer} item md={12} xs={12}>
                    <Typography
                      variant="h5"
                      style={{ fontWeight: '900' }}
                      className={classes.subTitle}
                    >
                      Did you know ?
                    </Typography>
                  </Grid>
                  <Grid className={classes.midContainer} item md={12} xs={12}>
                    <Typography variant="body1">{funFact}</Typography>
                  </Grid>
                </>
              )
              : (
                <Grid item md={12} xs={12}>
                  <Typography
                    variant="body1"
                    style={{ fontWeight: '900' }}
                    className={classes.subTitle}
                  >
                    Medical facts are coming...
                  </Typography>
                </Grid>
              )}
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
};

FunFactWidget.propTypes = {
  userId: PropTypes.string.isRequired,
};

function mapState(state) {
  const { userData } = state.user;
  const userId = userData._id;
  return { userId };
}
export default connect(mapState, null)(FunFactWidget);
