/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unreachable */
/* eslint-disable no-lone-blocks */
import React, { useState } from 'react';
import {
  Card,
  CardActionArea,
  Grid,
  Typography,
  // Card,
  // Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '1em 0 10em 0',
  },
  card: {
    minHeight: '12.5em',
    width: '100%',
  },
  imgCard: {
    position: 'relative',
    height: '100%',
    width: '100%',
    transformStyle: 'preserve-3d',
    transition: 'transform 0.8s',
  },
  flip: {
    transform: 'rotateY(180deg)',
  },
  frontCard: {
    top: 0,
    position: 'absolute',
    textAlign: 'center',
    width: '100%',
    height: '100%',
    backfaceVisibility: 'hidden',
  },
  backCard: {
    top: 0,
    textAlign: 'center',
    height: '100%',
    width: '100%',
    backfaceVisibility: 'hidden',
    transform: 'rotateY(180deg)',
  },
  backCardActive: {
    position: 'absolute',
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
    width: '100%',
    paddingTop: '0.5em',
    height: '2.5em',
    alignContent: 'center',
  },
  cardBody: {
    width: '90%',
    minHeight: '7.5em',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  cardBodyActive: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  title: {
    textAlign: 'center',
  },
  subTitle: {
    fontWeight: '900',
    textAlign: 'center',
  },
}));

const WordCard = ({ word, translation, definition }) => {
  const classes = useStyles();
  const [flip, setFlip] = useState(false);

  return (
    <Grid item lg={3} xs={12} sm={6} md={4} className={classes.card}>
      <CardActionArea className={[classes.imgCard, flip ? classes.flip : null]} onClick={() => setFlip(!flip)}>
        <Card className={classes.frontCard}>
          <Grid item xs={12} className={classes.cardHeader}>
            <Typography
              variant="body1"
              className={classes.title}
            >
              {word}
            </Typography>
          </Grid>
          <Grid direction="row" className={classes.cardBody}>
            <Grid xs={12}>
              <Typography
                variant="body1"
                className={classes.subTitle}
              >
                {translation}
              </Typography>
            </Grid>
          </Grid>
        </Card>
        <Card className={[classes.backCard, !flip ? classes.backCardActive : null]}>
          <Grid item xs={12} className={classes.cardHeader}>
            <Typography
              variant="body1"
              className={classes.title}
            >
              {word}
            </Typography>
          </Grid>
          <Grid direction="row" className={[classes.cardBody, flip ? classes.cardBodyActive : null]}>
            <Grid xs={12}>
              <Typography
                variant="body1"
                className={classes.subTitle}
              >
                {definition}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </CardActionArea>
    </Grid>
  );
};

WordCard.propTypes = {
  word: PropTypes.string.isRequired,
  translation: PropTypes.string.isRequired,
  definition: PropTypes.string.isRequired,
};

export default WordCard;
