/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container } from '@material-ui/core';
import uiActions from '../../redux/actions/uiActions';
import userActions from '../../redux/actions/userActions';
import Breadcrumbs from './components/Breadcrumbs';
import Dictionnary from '../Dictionnary';
// import useTimeCounter from '../Elearning/components/content/useTimeCounter';

const LearningLinks = ({
  changePageTitle,
  // logout,
  // userId,
  // groupId,
}) => {
  useEffect(() => {
    changePageTitle('Learning Links');
  }, []);

  // const isIdle = useTimeCounter(userId, groupId || null);

  // eslint-disable-next-line no-unused-expressions
  // isIdle && logout();

  return (
    <Container style={{ marginTop: '1em' }}>
      <Dictionnary />
      <Breadcrumbs />
    </Container>
  );
};

LearningLinks.propTypes = {
  changePageTitle: PropTypes.func.isRequired,
  // logout: PropTypes.func.isRequired,
  // userId: PropTypes.string.isRequired,
  // groupId: PropTypes.string.isRequired,
};

const mapState = (state) => {
  const { userData } = state.user;
  const { data } = state.group;
  const { currentGroup } = state.ui;
  const userId = userData._id;
  const { learningLinks } = state;
  const { units } = state;
  return {
    learningLinks, units, groupId: data[currentGroup]._id, userId,
  };
};

const actionCreators = {
  changePageTitle: uiActions.changePageTitle,
  logout: userActions.logout,
};

const connectedLearningLinks = connect(mapState, actionCreators)(LearningLinks);
export default connectedLearningLinks;
