/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  IconButton,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
  Snackbar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  AlternateEmail, VpnKeyRounded, Visibility, VisibilityOff,
} from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import userActions from '../../../redux/actions/userActions';
import config from '../../../config';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  newAccountButton: {
    marginTop: theme.spacing(4),
  },
}));

const LoginForm = ({
  login, setCreateAccount, loggingIn, loggingError,
}) => {
  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [prefmsError, setPrefmsError] = useState(false);
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorSnackBar, setErrorSnackBar] = useState(null);
  const [forbiddenCompany, setForbiddenCompany] = useState(false);
  const [maintenance, setMaintenance] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClick = (e) => {
    e.preventDefault();
    setPrefmsError(false);
    if (email.includes('@prefmschutoulouse.onmicrosoft.com')) {
      setPrefmsError(true);
    } else {
      login(email, password)
        .then((res) => {
          setPrefmsError(false);
          setForbiddenCompany(res === 'Company blocked');
          setMaintenance(res === 'Maintenance');
        })
        .catch(() => {
          setErrorSnackBar({ code: 2401, message: 'Error while login in.', severity: 'warning' });
          setPrefmsError(true);
        });
    }
  };

  return (
    <form className={classes.form} noValidate>
      <Snackbar open={errorSnackBar !== null} onClose={() => { setErrorSnackBar(null); }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert severity={errorSnackBar !== null ? errorSnackBar.severity : 'info'} variant="filled" onClose={() => { setErrorSnackBar(null); }}>
          {`Error ${errorSnackBar?.code}: ${errorSnackBar?.message}`}
        </Alert>
      </Snackbar>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        error={prefmsError}
        id="email"
        label="Adresse email"
        name="email"
        autoComplete="email"
        value={email || ''}
        onChange={(e) => setEmail(e.target.value.trim())}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AlternateEmail />
            </InputAdornment>
          ),
        }}
        autoFocus
      />
      {prefmsError && (
        <FormHelperText error id="password-error" style={{ marginBottom: '15px' }}>
          Adresse mail en @prefmschutoulouse.onmicrosoft.com bloquée par votre établissement
        </FormHelperText>
      )}
      <FormControl
        variant="outlined"
        fullWidth
        style={{ marginBottom: '1em' }}
      >
        <InputLabel htmlFor="password">Mot de passe *</InputLabel>
        <OutlinedInput
          id="password"
          error={loggingError}
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          startAdornment={(
            <InputAdornment position="start">
              <VpnKeyRounded />
            </InputAdornment>
          )}
          endAdornment={(
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )}
          labelWidth={105}
        />
        {loggingError && !forbiddenCompany && !maintenance && (
          <FormHelperText error id="password-error">
            Email ou mot de passe incorrect
          </FormHelperText>
        )}
        {forbiddenCompany && (
          <FormHelperText error id="password-error">
            Votre accès à la plateforme est temporairement suspendu.
            Si vous avez des questions, veuillez vous en référer à votre établissement et non a l'assistance.
          </FormHelperText>
        )}
        {maintenance && (
          <FormHelperText error id="password-error">
            {'La plateforme sera fermée du 3 au 7 Février compris afin d\'organiser la bascule des semestres.'}
          </FormHelperText>
        )}
      </FormControl>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        onClick={(e) => handleClick(e)}
      >
        {loggingIn ? 'Chargement...' : `Se connecter${config.PLATFORM === 'preprod' ? ' preprod' : ''}`}
      </Button>
      <Button
        className={classes.newAccountButton}
        fullWidth
        onClick={() => setCreateAccount()}
        variant="contained"
      >
        Créer un compte
      </Button>
    </form>
  );
};

LoginForm.defaultProps = {
  loggingIn: false,
  loggingError: false,
};

LoginForm.propTypes = {
  loggingIn: PropTypes.bool,
  login: PropTypes.func.isRequired,
  loggingError: PropTypes.bool,
  setCreateAccount: PropTypes.func.isRequired,
};

function mapState(state) {
  const { loggingIn, loggingError } = state.auth;
  return { loggingIn, loggingError };
}

const actionCreators = {
  login: userActions.login,
};

export default connect(mapState, actionCreators)(LoginForm);
