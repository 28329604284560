/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import examType1Constants from '../constants/examType1Constants';
import examType1Services from '../services/examType1Services';

function initTest(id, body) {
  function request() {
    return {
      type: examType1Constants.INIT_REQUEST,
    };
  }

  function success(exam) {
    return {
      type: examType1Constants.INIT_SUCCESS,
      exam,
    };
  }

  function failure(error) {
    return {
      type: examType1Constants.INIT_FAILURE,
      error,
    };
  }

  return (dispatch, getState) => {
    dispatch(request());
    const state = getState();
    examType1Services.getTest(state.user.userData._id, id)
      .then((res) => {
        if (!res || res === null) {
          examType1Services.initTest(id, body)
            .then(
              (exam) => {
                localStorage.setItem('exam', JSON.stringify(exam));
                localStorage.setItem('timeLeft', JSON.stringify(body.timer));
                dispatch(success(exam));
              },
              (error) => {
                dispatch(failure(error ? error.toString() : 'error'));
              },
            )
            .catch((e) => dispatch(failure(e ? e.toString() : 'error')));
        } else {
          examType1Services.getQuestionPool(res.pool)
            .then((questionPool) => {
              const questionsArr = questionPool.questions.map((question) => {
                const answersArr = question.answers.map((answer) => ({
                  text: answer.text,
                }));
                return ({
                  _id: question._id,
                  name: question.name || '',
                  type: question.type || '',
                  media: question.media || '',
                  level: question.level || '',
                  category: question.category || '',
                  subcategory: question.subcategory || '',
                  answers: answersArr,
                });
              });
              const examObj = {
                timer: res.timer,
                finish: false,
                testId: res._id,
                questions: questionsArr,
                descriptionKN: questionPool.descriptionKN ? questionPool.descriptionKN : '',
                descriptionRC: questionPool.descriptionRC ? questionPool.descriptionRC : '',
                descriptionOC: questionPool.descriptionOC ? questionPool.descriptionOC : '',
              };
              const tempanswers = res.answers.map((answer) => ({
                answerIndex: answer.answerIndex,
                question: answer.question._id,
                created_at: new Date(answer.created_at),
              }));
              localStorage.setItem('exam', JSON.stringify(examObj));
              localStorage.setItem('answers', JSON.stringify(tempanswers));
              localStorage.setItem('currentQuestion', JSON.stringify(tempanswers.length));
              localStorage.setItem('timeLeft', JSON.stringify(res.timer));
              dispatch(success(examObj));
            })
            .catch((error) => {
              dispatch(failure(error ? error.toString() : 'error'));
            });
        }
      });
  };
}

const setCurrentQuestion = (currentQuestion) => {
  localStorage.setItem('currentQuestion', JSON.stringify(currentQuestion));
  return ({
    type: examType1Constants.SET_CURRENT_QUESTION,
    currentQuestion,
  });
};

export const checkExamBeginTime = (additionalTime, duration) => {
  const time = new Date();
  const savedDate = localStorage.getItem('examBeginTime');
  const oldDate = new Date();
  oldDate.setFullYear(oldDate.getFullYear() - 1);
  let examBeginTime = new Date(JSON.parse(savedDate));
  if (examBeginTime.getTime() < oldDate.getTime()) {
    examBeginTime = new Date();
    examBeginTime.setTime(examBeginTime.getTime());
  }
  examBeginTime.setTime(examBeginTime.getTime() + (additionalTime ? additionalTime * 60 * 1000 : 0));
  examBeginTime.setTime(examBeginTime.getTime() + (60 * 1000 * duration));
  if (examBeginTime < time) {
    return false;
  }
  return (examBeginTime.getTime() - time.getTime());
};

const setExamBeginTime = () => {
  const time = new Date();
  // time.setHours(time.getHours());
  localStorage.setItem('examBeginTime', JSON.stringify(time));
  return ({
    type: examType1Constants.SET_EXAM_BEGIN_TIME,
    time,
  });
};

const setCurrentAnswer = (currentAnswer) => ({
  type: examType1Constants.SET_CURRENT_ANSWER,
  currentAnswer,
  created_at: new Date(),
});

const setMultiQuestionId = (id) => ({
  type: examType1Constants.SET_MULTI_QUESTION_ID,
  id,
});

const pushAnswer = () => async (dispatch, getState) => {
  const state = getState();
  const { currentAnswer } = state.exam;
  const tmpanswers = localStorage.getItem('answers') !== null ? JSON.parse(localStorage.getItem('answers')) : [];
  if (tmpanswers && tmpanswers !== null && tmpanswers.length && tmpanswers.length === 0) {
    const exam = await examType1Services.getTestById(state.exam.exam.testId);
    const userExam = await examType1Services.getTest(state.user.userData._id, exam.pool);
    if (userExam.pool === exam.pool) {
      userExam.answers.forEach((answer) => {
        tmpanswers.push({
          answerIndex: answer.answerIndex,
          question: answer.question._id,
          created_at: new Date(),
        });
      });
    }
    const answers = [...tmpanswers, ...currentAnswer];
    localStorage.setItem('answers', JSON.stringify(answers));
    dispatch({
      type: examType1Constants.PUSH_ANSWER,
      answers,
    });
  } else {
    const answers = [...tmpanswers, ...currentAnswer];
    localStorage.setItem('answers', JSON.stringify(answers));
    dispatch({
      type: examType1Constants.PUSH_ANSWER,
      answers,
    });
  }
};

const clearTest = () => {
  localStorage.removeItem('exam');
  localStorage.removeItem('currentQuestion');
  localStorage.removeItem('answers');
  localStorage.removeItem('examBeginTime');
  return (dispatch) => {
    dispatch({ type: examType1Constants.CLEAR_EXAM });
  };
};

const clearAnswers = () => {
  localStorage.removeItem('currentQuestion');
  localStorage.removeItem('answers');
  return (dispatch) => {
    dispatch({ type: examType1Constants.CLEAR_EXAM_ANSWERS });
  };
};

const examType1Actions = {
  initTest,
  setMultiQuestionId,
  setCurrentQuestion,
  setCurrentAnswer,
  pushAnswer,
  clearTest,
  clearAnswers,
  setExamBeginTime,
};

export default examType1Actions;
