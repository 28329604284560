/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, Paper, Typography } from '@material-ui/core';
import parse from 'html-react-parser';
import webinaireImg from '../img/webinaire_2.jpg';
import webinaireServices from '../../redux/services/webinaireServices';
import { Loading } from '../../helpers/utils';

const OldWebinaire = ({ setErrorSnackBar }) => {
  const [webinaires, setWebinaires] = useState([]);
  const [loading, setLoading] = useState(true);

  const getWebinairesData = async () => {
    const webinaireRes = await webinaireServices.getWebinaires();
    if (webinaireRes && webinaireRes.length > 0) {
      const newWebinaires = webinaireRes.filter((webinaire) => new Date(webinaire.start) < new Date());
      setWebinaires(newWebinaires);
      setLoading(false);
    } else {
      // toastError('Failed to fetch webinaires');
      setErrorSnackBar({ code: 400, message: 'Failed to fetch webinaires', severity: 'error' });
      setLoading(false);
    }
  };

  useEffect(() => {
    getWebinairesData();
  }, []);

  const handleDownload = async (filename) => {
    const downloadedFile = await webinaireServices.getRecording(filename);
    window.location.href = downloadedFile.url;
  };

  return (
    <div
      style={{
        width: '100%',
        backgroundSize: '700px',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.50), rgba(255, 255, 255, 0.50)), url(${webinaireImg})`,
        backgroundPosition: 'center',
        minHeight: '90vh',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      {!loading ? (
        <>
          {webinaires.length > 0 ? (
            webinaires.map((webinaire) => (
              <Paper
                style={{
                  padding: '20px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  width: '100%',
                }}
                elevation={1}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <Typography variant="h6">{webinaire.topic}</Typography>
                </div>
                <Typography variant="body2" align="justify">{parse(webinaire.blurb)}</Typography>
                <Button
                  onClick={() => handleDownload(webinaire.recording)}
                  variant="contained"
                  color="primary"
                  disableElevation
                  size="small"
                  style={{
                    display: 'flex',
                    alignSelf: 'end',
                  }}
                  disabled={!webinaire.recording}
                >
                  {webinaire.recording ? 'Télécharger' : 'En attente'}
                </Button>
              </Paper>
            ))
          ) : (
            <Typography variant="h6" style={{ textAlign: 'center', marginTop: 620, marginLeft: '-2%' }}>
              Aucun webinaire disponible en replay.
            </Typography>
          )}
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default OldWebinaire;
