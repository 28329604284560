import alertConstants from '../constants/alertConstants';
import store from '../store';

export const enqueueSnackbar = (notification) => {
  const key = notification.options && notification.options.key;

  return {
    type: alertConstants.ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random(),
    },
  };
};

function successWithToast(message) {
  return { type: alertConstants.SUCCESS, message };
}

export const toastSuccess = (message) => {
  store.dispatch(enqueueSnackbar({
    message,
    options: {
      key: new Date().getTime() + Math.random(),
      variant: 'success',
    },
  }));
};

export const toastError = (message) => {
  store.dispatch(enqueueSnackbar({
    message,
    options: {
      key: new Date().getTime() + Math.random(),
      variant: 'error',
    },
  }));
};

function success(message) {
  return { type: alertConstants.SUCCESS, message };
}

function error(message) {
  return { type: alertConstants.ERROR, message };
}

function clear() {
  return { type: alertConstants.CLEAR };
}

const alertActions = {
  success,
  successWithToast,
  error,
  clear,
};

export default alertActions;
