/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Container } from '@material-ui/core';
import ContentLoader from 'react-content-loader';

const UnitLoader = (props) => (
  <Container>
    <ContentLoader
      viewBox="0 0 1360 900"
      height={900}
      width={1360}
      {...props}
    >
      <rect x="0" y="0" ry="8" width="150" height="200" />
      <rect x="0" y="230" ry="8" width="150" height="400" />
    </ContentLoader>
  </Container>
);

export default UnitLoader;
