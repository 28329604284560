/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import learningLinksActions from '../../../redux/actions/learningLinksActions';
import LinkLoader from '../../Loader/FlatCardLoader';
import LinkItem from './LinkItem';
import GoBackButton from './GoBackButton';

const DisplayTopicLinks = ({ learningLinks, location, getLinks }) => {
  // Get topic name from url.
  const topic = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

  useEffect(() => {
    getLinks(topic);
  }, []);

  const displayContent = () => {
    if (learningLinks.requestingTopicLinks) {
      return (
        <LinkLoader />
      );
    }
    if (learningLinks.topicLinks) {
      return learningLinks.topicLinks.map((link) => (
        <LinkItem
          key={link.url}
          name={link.name}
          url={link.url}
        />
      ));
    }
    return null;
  };

  return (
    <div>
      <h3 className="subtitle">
        <GoBackButton />
        Links
      </h3>
      <Grid container>
        { displayContent() }
      </Grid>
    </div>
  );
};

DisplayTopicLinks.propTypes = {
  learningLinks: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  getLinks: PropTypes.func.isRequired,
};

function mapState(state) {
  const { learningLinks } = state;
  return { learningLinks };
}

const actionCreators = {
  getLinks: learningLinksActions.getLinksByTopic,
};

const connectedDisplayTopicLinks = connect(mapState, actionCreators)(DisplayTopicLinks);
export default connectedDisplayTopicLinks;
