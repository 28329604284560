/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable object-curly-newline */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  Grid, Typography, Button,
} from '@material-ui/core';
import guideServices from '../../../redux/services/guideServices';
import Player from '../../shared/Player';
import placementTestActions from '../../../redux/actions/placementTestActions';
import config from '../../../config';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
    position: 'relative',
    paddingBottom: '30px',
  },
  mainTitle: {
    marginTop: '1em',
    textAlign: 'center',
  },
  loadingText: {
    marginTop: '1em',
    textAlign: 'center',
    marginBottom: '30px',
  },
  resultCard: {
    textAlign: 'center',
    margin: '1em',
    padding: '2em 0',
    minHeight: '10em',
  },
  gradeTitle: {
    margin: '0 1em',
  },
  grade: {
    color: theme.palette.primary.main,
  },
  button: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  image: {
    width: '35%',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  playerContainer: {
    justifyContent: 'center !important',
    width: '100%',
    paddingTop: '50px',
    display: 'flex',
  },
}));

const Tutorial = ({ clearTest }) => {
  const classes = useStyles();
  const [guides, setGuides] = useState([]);
  const [step, setStep] = useState(0);

  useEffect(() => {
    guideServices.getGuides()
      .then((data) => {
        setGuides(data);
      });
  }, []);

  const nextStep = () => {
    if (step === 2) {
      clearTest();
    }
    if (step < 2) {
      setStep(step + 1);
    }
  };

  return (
    <Grid container direction="row" className={classes.root} justifyContent="center">
      <Grid item md={12}>
        <Typography className={classes.mainTitle} variant="h5">
          Tutoriel
        </Typography>
        <Typography className={classes.mainTitle} variant="h5">
          {`Vidéo ${step + 1}/3`}
        </Typography>
        <Typography className={classes.mainTitle} variant="h6">
          Vous pourrez retrouver les vidéos depuis la page Help de la plateforme.
        </Typography>
      </Grid>
      {
        guides.length > 0
          ? (
            <div className={classes.playerContainer}>
              <Player url={`${config.OBJECT_URL}/resources/video/${guides[(step + 1) % 3].filename}`} />
            </div>
          )
          : null
      }
      <Grid item md={12}>
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          onClick={() => nextStep()}
        >
          {step === 2 ? 'Accéder à la plateforme' : 'Prochaine vidéo'}
        </Button>
      </Grid>
    </Grid>
  );
};

Tutorial.propTypes = {
  clearTest: PropTypes.func.isRequired,
};

const actionCreators = {
  clearTest: placementTestActions.clearTest,
};

function mapState(state) {
  const { userData } = state.user;
  const { semester, company, _id } = userData;
  return {
    semester, company, _id,
  };
}
export default connect(mapState, actionCreators)(Tutorial);
