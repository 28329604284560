/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, TextField, Typography, Button, Container, Select, MenuItem, FormControl,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '2vh 0',
  },
  instructions: {
    margin: '1em 0 1em 0',
    padding: '0 0.5em 0 0.5em',
    textAlign: 'center',
  },
  hidden: {
    display: 'none',
  },
  button: {
    margin: theme.spacing(3, 0, 2),
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  input: {
    width: '100%',
  },
}));

const SignUpCode = ({
  graphData, handleNext, setCodeName, setSemester, error, code,
}) => {
  const [selectSemester, setSelectSemester] = useState(graphData && graphData.sem !== null && typeof (graphData.sem) === 'number' ? graphData.sem : 1);
  const classes = useStyles();

  function handleSemester(event) {
    setSelectSemester(event.target.value);
    setSemester(event.target.value);
  }

  function displaySemesters() {
    // eslint-disable-next-line prefer-const
    let semesters = [];
    for (let i = 1; i <= 10; i += 1) {
      semesters.push(<MenuItem key={i} value={i}>{`Semestre ${i.toString()}`}</MenuItem>);
    }
    return semesters;
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Grid
          container
          direction="row"
          className={classes.root}
        >
          <Grid item xs={12}>
            <Typography
              className={classes.instructions}
            >
              { graphData && graphData.code !== null && graphData.code.length > 0
                ? null
                : 'Veuillez entrer le code fourni par votre établissement:'}
            </Typography>
            <TextField
              error={error}
              value={code}
              disabled={graphData && graphData.code !== null && graphData.code.length > 0}
              helperText={error === true ? 'Ce code est invalide ou n\'existe pas, merci de réessayer.' : ''}
              className={classes.input}
              onChange={(e) => (setCodeName(e.target.value))}
              id="code"
              label="Code"
              variant="outlined"
              fullWidth
              autoFocus
            />
          </Grid>
          { graphData ? (
            <Grid item xs={12}>
              <Typography
                className={classes.instructions}
              >
                { graphData && graphData.sem !== null && typeof (graphData.sem) === 'number'
                  ? null
                  : 'Veuillez séléctionner votre semestre:'}
              </Typography>
              <FormControl variant="outlined" fullWidth>
                <Select
                  disabled={graphData && graphData.sem !== null && typeof (graphData.sem) === 'number'}
                  labelId="Semestre"
                  id="semesterSelect"
                  value={selectSemester}
                  onChange={handleSemester}
                >
                  { displaySemesters() }
                </Select>
              </FormControl>
            </Grid>
          ) : null }
          <Grid item md={12} xs={12}>
            <Button
              onClick={() => handleNext()}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.button}
            >
              {graphData ? 'Inscription' : 'Suivant'}
            </Button>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

SignUpCode.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  graphData: PropTypes.object.isRequired,
  handleNext: PropTypes.func.isRequired,
  setCodeName: PropTypes.func.isRequired,
  setSemester: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  code: PropTypes.string.isRequired,
};

export default SignUpCode;
