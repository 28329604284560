/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable object-curly-newline */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  Grid, Typography, Card, Button, CircularProgress, Snackbar,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import placementTestServices from '../../../redux/services/placementTestServices';
import { numGradeToEUgrade } from '../../../helpers/utils';
import placementTestActions from '../../../redux/actions/placementTestActions';
import config from '../../../config';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '60vh',
    marginTop: theme.spacing(1),
    position: 'relative',
  },
  mainTitle: {
    marginTop: '1em',
    textAlign: 'center',
  },
  loadingText: {
    marginTop: '1em',
    textAlign: 'center',
    marginBottom: '30px',
  },
  resultCard: {
    textAlign: 'center',
    margin: '1em',
    padding: '2em 0',
    minHeight: '10em',
  },
  gradeTitle: {
    margin: '0 1em',
  },
  grade: {
    color: theme.palette.primary.main,
  },
  button: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '50px',
  },
  image: {
    width: '35%',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

const GradeCard = ({ title, score }) => {
  const classes = useStyles();

  return (
    <Grid item md={4}>
      <Card className={classes.resultCard}>
        <Typography variant="h6" className={classes.gradeTitle}>{title}</Typography>
        <Typography variant="h4" className={classes.grade}>{numGradeToEUgrade(score)}</Typography>
      </Card>
    </Grid>
  );
};

GradeCard.propTypes = {
  title: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
};

const TestFinished = ({ setTutorial, answers, setScores, scores, _id }) => {
  const classes = useStyles();
  const [testSend, setTestSend] = useState(false);
  const [errorSnackBar, setErrorSnackBar] = useState(null);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (!testSend && answers && answers.length > 0) {
      setTestSend(true);
      placementTestServices.getTest(_id)
        .then(
          (res) => {
            if (res) {
              setScores(res.scores);
              setTestSend(true);
            }
          },
          (error) => {
            console.error(error);
            setTestSend(false);
          },
        )
        .catch(() => {
          setErrorSnackBar({ code: 2601, message: 'Missing test data.', severity: 'warning' });
        });
    }
  }, [answers, reload]);

  setInterval(() => setReload(!reload), 8000);

  const studentToGroup = () => {
    setTutorial(true);
  };

  return scores ? (
    <Grid container direction="row" className={classes.root} justifyContent="center">
      <Snackbar open={errorSnackBar !== null} onClose={() => { setErrorSnackBar(null); }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert severity={errorSnackBar !== null ? errorSnackBar.severity : 'info'} variant="filled" onClose={() => { setErrorSnackBar(null); }}>
          {`Error ${errorSnackBar?.code}: ${errorSnackBar?.message}`}
        </Alert>
      </Snackbar>
      <Grid item md={12}>
        <Typography className={classes.mainTitle} variant="h5">
          Vos résultats:
        </Typography>
        <Grid item md={12}>
          <img className={classes.image} alt="placement test illustration" src={`${config.OBJECT_URL}/thumbnail/end-test.svg`} />
        </Grid>
      </Grid>
      <GradeCard title="Connaissances" score={scores.scoreKN} />
      <GradeCard title="Compréhension écrite" score={scores.scoreRC} />
      <GradeCard title="Compréhension orale" score={scores.scoreOC} />
      <GradeCard title="Note globale" score={scores.total} />
      <Grid item md={12}>
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          onClick={() => studentToGroup()}
        >
          Tutoriel
        </Button>
      </Grid>
    </Grid>
  ) : (
    <Grid container direction="column" className={classes.root} justifyContent="center" alignItems="center">
      <Typography className={classes.loadingText} variant="h5">
        Chargement en cours
      </Typography>
      <CircularProgress />
    </Grid>
  );
};

TestFinished.propTypes = {
  _id: PropTypes.string.isRequired,
  answers: PropTypes.array.isRequired,
  setTutorial: PropTypes.func.isRequired,
  setScores: PropTypes.func.isRequired,
  scores: PropTypes.object.isRequired,
};

const actionCreators = {
  clearTest: placementTestActions.clearTest,
};

function mapState(state) {
  const { userData } = state.user;
  const { semester, company, _id } = userData;
  return {
    semester, company, _id,
  };
}
export default connect(mapState, actionCreators)(TestFinished);
