import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import FillBlankQuestion from './FillBlankQuestion';

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'center',
    fontSize: '1.5em',
  },
}));

const Question = ({ question, type }) => {
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles();

  switch (type) {
    case 'mcq':
      return (
        <Typography className={classes.root} style={{ fontSize: 19, lineHeight: 1.5 }} variant="body1">{question}</Typography>
      );
    case 'fillblank':
      return (
        <>
          <Typography className={classes.root} style={{ fontSize: 19, lineHeight: 1.5 }} variant="body1">Select the most appropriate words</Typography>
          <FillBlankQuestion question={question} />
        </>
      );
    case 'd&d':
      return (
        <Typography className={classes.root} style={{ fontSize: 19, lineHeight: 1.5 }} variant="body1">Put the sentence in the right order</Typography>
      );
    case 'tof':
      return (
        <Typography className={classes.root} style={{ fontSize: 19, lineHeight: 1.5 }} variant="body1">{question}</Typography>
      );
    default:
      return 'Unknown type';
  }
};

Question.propTypes = {
  question: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default Question;
