/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import {
  Grid, Snackbar, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Moment from 'moment';
import Alert from '@material-ui/lab/Alert';
import NoExamDisplay from './NoExamDisplay';
import ExamType3 from './ExamType3';
import ExamType2 from './ExamType2';
import ExamType1 from './ExamType1/indexExam';
import userActions from '../../../../../redux/actions/userActions';
import userServices from '../../../../../redux/services/userServices';
import examResultServices from '../../../../../redux/services/examResultServices';
import Warning from './Warning';
import uiActions from '../../../../../redux/actions/uiActions';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    display: 'flex',
    justifyContent: 'center',
    height: '80vh',
  },
  mainCard: {
    height: '100%',
    minWidth: '45vw',
    position: 'relative',
    overflow: 'visible',
  },
  rise: {
    height: '100rem',
  },
  storyline: {
    minHeight: '75vh',
    maxHeight: '80vh',
  },
  alertContainer: {
    width: '60%',
    marginLeft: '20%',
    textAlign: 'center',
    height: '60vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
}));

const Exam = ({
  userName, currentGroup, userId, groupId, company, changePageTitle, data, userData, email,
}) => {
  const classes = useStyles();
  const [hiddenResults, setHiddenResults] = React.useState(false);
  const [alertAnswer, setAlertAnswer] = React.useState(false);
  const [finished, setFinished] = React.useState(false);
  const [alreadySent, setAlreadySent] = React.useState(false);
  const [started, setStarted] = React.useState(false);
  const [errorSnackBar, setErrorSnackBar] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [answer, setAnswer] = React.useState('');
  const [answerDate, setAnswerDate] = React.useState(null);
  const [currentDate, setCurrentDate] = React.useState(null);
  const [modalMessage, setModalMessage] = React.useState('');
  const [modalApproval, setModalApproval] = React.useState(false);
  const [approval, setApproval] = React.useState(false);
  const [elearningOptions, setElearningOptions] = React.useState([]);
  const [currentExam, setCurrentExam] = React.useState({});
  const [beginDate, setBeginDate] = React.useState(new Date());
  const [examEnd, setExamEnd] = React.useState(new Date());
  const [examFile, setExamFile] = React.useState('');
  const [examType, setExamType] = React.useState(0);
  const [activityId, setActivityId] = React.useState('');

  function getItemsInAscendingDateOrderAndClosestToNowFirst(arr) {
    const time = Date.now();

    const [closest, ...rest] = Array
      // create a shallow copy in order to
      // not mutate the original reference.
      .from(arr)
      // sort items by date closest to now.
      .sort((a, b) => {
        const aTime = new Date(a.examBegin).getTime();
        const bTime = new Date(b.examEnd).getTime();

        const aDelta = Math.abs(time - aTime);
        const bDelta = Math.abs(time - bTime);

        return (aDelta - bDelta);
      });

    return [
      closest,
      ...rest
        // sort all other items in ascending date order.
        .sort((a, b) => new Date(a.effectiveDate).getTime()
          - new Date(b.effectiveDate).getTime()),
    ];
  }

  const dates = [
    'JANVIER',
    'FEVRIER',
    'MARS',
    'AVRIL',
    'MAI',
    'JUIN',
    'JUILLET',
    'AOUT',
    'SEPTEMBRE',
    'OCTOBRE',
    'NOVEMBRE',
    'DECEMBRE',
  ];

  const getFormatedDate = (_date) => {
    const date = Moment(_date);
    const day = date.date();
    const month = dates[date.month()];
    const year = date.year();
    const hours = date.hours();
    const minutes = date.minutes();

    return `${hours}h${minutes < 10 && minutes > 0 ? '0' : ''}${minutes > 0 ? minutes : ''} le ${day} ${month} ${year}`;
  };

  const loadingExams = async () => {
    let examTypeTemp = 0;
    let examFileTemp = '';
    if (data[currentGroup].elearning.elearningOptions
      && data[currentGroup].elearning.elearningOptions.length > 0) {
      setElearningOptions(data[currentGroup].elearning.elearningOptions);
      const tempElearningOptions = [...data[currentGroup].elearning.elearningOptions];
      let elearningOption;
      tempElearningOptions.forEach((exam) => {
        if (new Date(exam.examBegin) <= new Date() && new Date(exam.examEnd) > new Date()) {
          elearningOption = { ...exam };
        }
      });
      if (!elearningOption) {
        getItemsInAscendingDateOrderAndClosestToNowFirst(tempElearningOptions);
        elearningOption = tempElearningOptions[0];
        setCurrentExam(tempElearningOptions[0]);
      } else {
        setCurrentExam(elearningOption);
      }

      setBeginDate(new Date(elearningOption.examBegin));
      const examEndAdditionalTime = new Date();
      if (userData.additionalTime && userData.additionalTime > 0) {
        examEndAdditionalTime.setMinutes(examEndAdditionalTime.getMinutes() + userData.additionalTime);
        setExamEnd(examEndAdditionalTime);
      } else {
        setExamEnd(new Date(elearningOption.examEnd));
      }
      if (elearningOption.examSubject !== null) {
        setExamFile(elearningOption.examSubject.examFile);
        setActivityId(elearningOption.examSubject.activityId);
        examTypeTemp = elearningOption.examSubject.type;
        examFileTemp = elearningOption.examSubject.examFile;
        setExamType(elearningOption.examSubject.type);
      } else if (elearningOption.examSubject === null) {
        setExamType(0);
        examTypeTemp = 0;
      }
    }
    if (examTypeTemp === 2) {
      const examResult = async () => {
        try {
          const res = await examResultServices.getExamsByExamFile(examFileTemp, groupId, userId);
          return res;
        } catch (e) {
          console.error(e);
        }
      };

      const exams = await examResult();
      if (exams !== undefined && exams.length > 0) {
        exams.forEach((exam) => {
          if (exam.answer === undefined || exam.answer === '') {
            setHiddenResults(false);
          } else {
            setHiddenResults(true);
            setAnswerDate(getFormatedDate(new Date(exam.created_at)));
            setAnswer(exam.answer);
            setAlreadySent(true);
          }
        });
      } else {
        if (localStorage.getItem('answer') !== undefined) {
          setAnswer(JSON.parse(localStorage.getItem('answer')));
        }
        setHiddenResults(false);
      }
    }
    try {
      const resTime = await userServices.getFixTime();
      if (resTime) {
        setCurrentDate(new Date(resTime.today));
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    changePageTitle('E-Learning | Exam');
    loadingExams();
  }, [data, data[currentGroup], groupId]);

  // sending answer to db
  const sendAnswer = () => {
    examResultServices.postExam({
      companyId: company._id,
      groupId,
      examFile,
      userId,
      answer: JSON.parse(localStorage.getItem('answer')),
    })
      .then(() => {
        const today = new Date();
        setAnswerDate(getFormatedDate(today));
        setHiddenResults(true);
        localStorage.removeItem('answer');
      })
      .catch(() => {
        setErrorSnackBar({ code: 2001, message: 'Error posting exam.', severity: 'error' });
      });
  };

  // tiggering the modals to check if there is an answer AND if the user wants to confirm his answer
  const handleApprovalAnswer = () => {
    if (answer !== undefined && answer !== '') {
      setModalMessage('Etes-vous sûr(e) de vouloir envoyer votre réponse ?');
      setModalApproval(true);
    } else {
      setModalMessage('Vous ne pouvez pas envoyer de réponse vide.');
      setAlertAnswer(true);
    }
  };

  // sending answer depending on approval state
  useEffect(() => {
    if (approval === true) {
      sendAnswer();
    }
    setApproval(false);
  }, [approval]);

  const handleAnswer = (e) => {
    localStorage.setItem('answer', JSON.stringify(e.target.value));
    setAnswer(e.target.value);
  };

  return (
    <div className={classes.root}>
      <Snackbar open={errorSnackBar !== null} onClose={() => { setErrorSnackBar(null); }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert severity={errorSnackBar !== null ? errorSnackBar.severity : 'info'} variant="filled" onClose={() => { setErrorSnackBar(null); }}>
          {`Error ${errorSnackBar?.code}: ${errorSnackBar?.message}`}
        </Alert>
      </Snackbar>
      {
        loading
          ? (
            <Typography variant="h6">
              Loading...
            </Typography>
          )
          : (
            <Grid container direction="column" className={classes.mainCard}>
              {
          // eslint-disable-next-line react/destructuring-assignment
            (elearningOptions
            && elearningOptions.length > 0
            && examType !== undefined
            && (data[currentGroup].semester === userData.semester || (userData.catchUp && userData.catchUp.includes(data[currentGroup].semester))))
              ? (currentDate && beginDate > currentDate
                ? (
                  <NoExamDisplay
                    examText="Examen à venir"
                    getFormatedDate={getFormatedDate}
                    examBegin={beginDate}
                    examEnd={examEnd}
                  />
                )
                : (currentDate
                  && (
                    (examType !== 1 && examEnd > currentDate)
                    || (examType === 1
                      && ((!finished && started) || (examEnd > currentDate && !started)))
                    || (examType === 2 && ((!localStorage.getItem('examBeginTime') && examEnd > currentDate) || (localStorage.getItem('examBeginTime') && (currentDate.getTime() - new Date(JSON.parse(localStorage.getItem('examBeginTime'))).getTime()) / 60000 < currentExam.duration))))
                  ? (() => {
                    switch (examType) {
                      case 0:
                        return (
                          <div className={classes.alertContainer}>
                            <Alert severity="info">
                              Vous n’avez pas d’épreuve écrite prévue sur la plateforme durant ce semestre. Pour en savoir plus, vous pouvez consulter votre programme dans l’onglet « Resources » ou vous renseigner auprès de votre école.
                            </Alert>
                          </div>
                        );
                      case 1:
                        return (
                          <ExamType1
                            activityId={activityId}
                            currentExam={currentExam}
                            setFinished={setFinished}
                            setStarted={setStarted}
                          />
                        );
                      case 2:
                        return (
                          <>
                            <ExamType2 data={{
                              examFile,
                              hiddenResults,
                              answer,
                              handleAnswer,
                              answerDate,
                              handleApprovalAnswer,
                              modalApproval,
                              setModalApproval,
                              modalMessage,
                              setApproval,
                              alertAnswer,
                              setAlertAnswer,
                            }}
                            />
                            {examEnd
                            && (
                            <Warning
                              examEnd={examEnd}
                              setCurrentDate={setCurrentDate}
                              getFormatedDate={getFormatedDate}
                              currentExam={currentExam}
                              sendAnswer={sendAnswer}
                              examType={examType}
                              alreadySent={alreadySent}
                              setAlreadySent={setAlreadySent}
                              answerDate={answerDate}
                            />
                            )}
                          </>
                        );
                      case 3:
                        return (
                          <ExamType3
                            group={data[currentGroup]}
                            userId={userId}
                            email={email}
                            userName={userName}
                            getFormatedDate={getFormatedDate}
                            examFile={examFile}
                          />
                        );
                      default:
                        break;
                    }
                  })()
                  : (
                    <NoExamDisplay
                      examText="Votre épreuve est terminée."
                      getFormatedDate={getFormatedDate}
                      examBegin={beginDate}
                      examEnd={examEnd}
                      examType={examType}
                      userId={userId}
                      activityId={activityId}
                    />
                  )
                ))
              : (
                <div className={classes.alertContainer}>
                  <Alert severity="info">
                    Vous n’avez pas d’épreuve écrite prévue sur la plateforme durant ce semestre. Pour en savoir plus, vous pouvez consulter votre programme dans l’onglet « Resources » ou vous renseigner auprès de votre école.
                  </Alert>
                </div>
              )
        }
            </Grid>
          )
      }
    </div>
  );
};

Exam.propTypes = {
  data: PropTypes.array.isRequired,
  userName: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  currentGroup: PropTypes.number.isRequired,
  // logout: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  company: PropTypes.object.isRequired,
  changePageTitle: PropTypes.func.isRequired,
};

function mapState(state) {
  const { userData } = state.user;
  const { data } = state.group;
  const { currentGroup } = state.ui;
  const userName = `${userData.firstname} ${userData.name}`;
  const { email } = userData;
  const userId = userData._id;
  const { company } = userData;
  return {
    // eslint-disable-next-line max-len
    data, userName, email, currentGroup, userId, groupId: data[currentGroup]._id, company, userData,
  };
}

const actionCreators = {
  logout: userActions.logout,
  changePageTitle: uiActions.changePageTitle,
};

export default connect(mapState, actionCreators)(Exam);
