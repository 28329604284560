/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// eslint-disable-next-line no-unused-vars
import PlayArrowIcon from '@material-ui/icons/PlayArrowRounded';
import {
  Card, Typography, Grid, TextField, IconButton, Snackbar,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import Alert from '@material-ui/lab/Alert';
import userActions from '../../../redux/actions/userActions';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardBody: {
    width: '100%',
    margin: 'auto',
    padding: '1em',
    flexDirection: 'row',
    height: '100%',
  },
  center: {
    textAlign: 'center',
    margin: 'auto',
    width: '100%',
    height: '100%',
  },
  midContainer: {
    height: 'min-content',
  },
  title: {
    padding: '1em',
    textAlign: 'left',
    maxHeight: 50,
  },
  boldTitle: {
    paddingRight: '1em',
    maxHeight: 50,
    fontWeight: 'bold',
  },
  actionArea: {
    width: '100%',
    height: '100%',
  },
  subTitle: {
    paddingBottom: '0.5em',
  },
  input: {
    width: '80%',
  },
}));

const ProfileEditWidget = ({
  firstname, lastname, email, _id, updateUserNames,
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState({
    open: false,
    severity: 'success',
  });
  const [snackMessage, setsnackMessage] = useState('');
  const [values, setValues] = React.useState({
    firstname,
    lastname,
    email,
    editFirstname: true,
    editLastname: true,
  });

  const updateStudent = (prop) => {
    const forbiddenCharacter = new RegExp(/^[\p{L}'][ \p{L}'-]*[\p{L}]$/u);
    let body;
    if (prop === 'editFirstname') body = { firstname: values.firstname.charAt(0).toUpperCase() + values.firstname.slice(1).toLowerCase() };
    else if (prop === 'editLastname') body = { name: values.lastname.toString().toUpperCase() };
    else return;

    if ((body.firstname && forbiddenCharacter.test(body.firstname))
    || (body.name && forbiddenCharacter.test(body.name))) {
      updateUserNames(_id, body);
      setsnackMessage('Name successfully changed');
      setOpen({ ...open, open: true, severity: 'success' });
    } else {
      setsnackMessage('Certain symbols and numbers are forbidden');
      setOpen({ ...open, open: true, severity: 'error' });
    }
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleEdit = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: !values[prop],
      lastname: values.lastname.toUpperCase(),
      firstname: values.firstname.charAt(0).toUpperCase() + values.firstname.slice(1).toLowerCase(),
    });
    if (values[prop] && (values.firstname !== firstname || values.lastname !== lastname)) {
      updateStudent(prop);
    }
  };

  const noMailEdit = () => {
    setsnackMessage('You cannot update your email adress');
    setOpen({ ...open, open: true, severity: 'warning' });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen({ ...open, open: false });
  };

  useEffect(() => {
  }, []);

  return (
    <Card>
      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={open.open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} variant="outlined" severity={open.severity}>
          {snackMessage}
        </Alert>
      </Snackbar>
      <Card className={classes.root}>
        <Grid container direction="row" className={classes.center}>
          <Grid item md={12} className={classes.cardHeader}>
            <Typography
              variant="body1"
              className={classes.title}
            >
              Your personal information
            </Typography>
          </Grid>
          <Grid container direction="row" spacing={1} className={classes.cardBody}>
            <Grid item xs={12}>
              <TextField className={classes.input} label="Firstname" onChange={handleChange('firstname')} value={values.firstname} disabled={!values.editFirstname} />
              <IconButton aria-label="upload picture" component="span" onClick={handleEdit('editFirstname')}>
                {
                  values.editFirstname
                    ? <EditIcon />
                    : <DoneIcon />
                }
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <TextField className={classes.input} label="Lastname" onChange={handleChange('lastname')} value={values.lastname} disabled={!values.editLastname} />
              <IconButton aria-label="upload picture" component="span" onClick={handleEdit('editLastname')}>
                {
                  values.editLastname
                    ? <EditIcon />
                    : <DoneIcon />
                }
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Card>
  );
};

const actionCreators = {
  updateUserNames: userActions.updateUserNames,
};

ProfileEditWidget.propTypes = {
  firstname: PropTypes.string.isRequired,
  lastname: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  _id: PropTypes.string.isRequired,
  updateUserNames: PropTypes.func.isRequired,
};

function mapState(state) {
  const { firstname, name, email } = state.user.userData;
  const lastname = name;
  const { _id } = state.auth.authData;
  return {
    _id, firstname, lastname, email,
  };
}

export default connect(mapState, actionCreators)(ProfileEditWidget);
