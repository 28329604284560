/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import {
  Grid, Container, useMediaQuery, Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner';
import WordCard from './WordCard';
import AlphabetMenu from './AlphabetMenu';
import uiActions from '../../../redux/actions/uiActions';
import glossaryActions from '../../../redux/actions/glossaryActions';
import SearchField from './SearchField';
import Dictionnary from '../../Dictionnary';

const WordTable = ({
  glossary, getGlossary, changePageTitle,
}) => {
  useEffect(() => {
    getGlossary();
    changePageTitle('Glossary');
  }, []);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [letter, setLetter] = useState('');
  const glossaryData = glossary;
  const wordCards = [];

  const words = glossaryData.glossary;

  const setSelectedLetter = (character) => {
    setLetter(character);
  };

  if (words) {
    words.sort((a, b) => ((a.word.toUpperCase() > b.word.toUpperCase()) ? 1 : -1));
    words.forEach((element) => {
      const wordMatch = element.word.toUpperCase().includes(letter.toUpperCase());
      const translationMatch = element.translation.toUpperCase().includes(letter.toUpperCase());

      if (wordMatch || translationMatch) {
        wordCards.push(
          <WordCard
            key={element.word}
            word={element.word}
            definition={element.definition}
            translation={element.translation}
          />,
        );
      }
    });
  }

  return (
    <Container style={{ paddingTop: '2em' }}>
      <Dictionnary />
      {
        !isMobile && (
          <AlphabetMenu letter={letter} setLetter={setSelectedLetter} />
        )
      }
      <SearchField search={letter} setSearch={setSelectedLetter} />
      <div style={{
        display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingBottom: '1.5em', paddingLeft: '2.5em',
      }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" height="2em" viewBox="0 0 24 24" width="2em">
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M17 5h-2V3h2v2zm-2 16h2v-2.59L19.59 21 21 19.59 18.41 17H21v-2h-6v6zm4-12h2V7h-2v2zm0 4h2v-2h-2v2zm-8 8h2v-2h-2v2zM7 5h2V3H7v2zM3 17h2v-2H3v2zm2 4v-2H3c0 1.1.9 2 2 2zM19 3v2h2c0-1.1-.9-2-2-2zm-8 2h2V3h-2v2zM3 9h2V7H3v2zm4 12h2v-2H7v2zm-4-8h2v-2H3v2zm0-8h2V3c-1.1 0-2 .9-2 2z" />
        </svg>
        <Typography
          style={{ marginLeft: '10px' }}
          component="h6"
          variant="h6"
        >
          Click on the card to see definition
        </Typography>
      </div>
      <Grid spacing={5} container direction="row">
        { words ? (
          <>
            {wordCards.length > 0
              ? wordCards
              : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%', /* Makes the div fill the viewport height */
                    width: '100%', /* Makes the div fill the viewport width */
                    alignSelf: 'center', /* Centers the div horizontally */
                    marginTop: '18%',
                    fontSize: '1.5em', /* Added font-size to make the text bigger */
                    fontBold: 'bold', /* Added font-weight to make the text bold */
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{
                      marginTop: 0, /* Removed marginTop since flex is centering it */
                    }}
                  >
                    No words found.
                  </Typography>
                </div>
              )}
          </>
        ) : (
          <div style={{
            width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '18%',
          }}
          >
            <Loader
              type="Oval"
              color="#00BFFF"
              height={150}
              width={150}
            />
          </div>
        )}
      </Grid>
    </Container>
  );
};

WordTable.propTypes = {
  glossary: PropTypes.array.isRequired,
  getGlossary: PropTypes.func.isRequired,
  changePageTitle: PropTypes.func.isRequired,
};

const mapState = (state) => {
  const { glossary } = state;
  return { glossary };
};

const actionCreators = {
  getGlossary: glossaryActions.getGlossary,
  changePageTitle: uiActions.changePageTitle,
};

const connectedGetGlossary = connect(mapState, actionCreators)(WordTable);

export default connectedGetGlossary;
