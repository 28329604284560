/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import uiActions from '../../redux/actions/uiActions';
import ZoomLinks from '../Dashboard/components/ZoomLinks';

const Group = ({ company, group, changePageTitle }) => {
  changePageTitle('Group');

  return (
    <div
      style={{
        margin: '30px',
      }}
    >
      <ZoomLinks group={group} company={company} />
    </div>
  );
};

Group.propTypes = {
  company: PropTypes.object.isRequired,
  group: PropTypes.object.isRequired,
  changePageTitle: PropTypes.func.isRequired,
};

function mapState(state) {
  const { data } = state.group;
  const { currentGroup } = state.ui;
  const { userData } = state.user;
  const { company } = userData;
  return {
    company, group: data[currentGroup],
  };
}

const actionCreators = {
  changePageTitle: uiActions.changePageTitle,
};

export default connect(mapState, actionCreators)(Group);
